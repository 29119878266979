<app-top-menu></app-top-menu>

<div class="app-container" [ngClass]="{ 'zen-mode': (isZenModeOn$ | async) === true }">
  @if ((isZenModeOn$ | async) === false) {
    <app-aside-menu />
  }
  <div class="main-content">
    <router-outlet></router-outlet>
  </div>
</div>
@if (isZenModeOn$ | async) {
  <app-zenmode-paginate></app-zenmode-paginate>
}
