<brx-modal-header [dialogRef]="dialogRef">
  <div class="header">
    <brx-icon name="@brx.energy" />
    <div class="text">Как правильно ставить цель / идти к мечте?</div>
  </div>
</brx-modal-header>

<video class="video" controls>
  <source src="https://brx-static.object.pscloud.io/crm/video/dreams.mp4" type="video/mp4" />
</video>

<div class="rule-list">
  <div class="rule">
    <brx-icon name="@brx.square-star" color="primary" />
    НЕ используй сленговые слова, чтобы не запутать нейросеть "рекламник", "турик", "тридцатка", "ТП"
  </div>
  <div class="rule">
    <brx-icon name="@brx.square-star" color="primary" />
    Загадывайте желание, когда вы максимально заряжены и находитесь в хорошем настроении
  </div>
  <div class="rule">
    <brx-icon name="@brx.square-star" color="primary" />
    Представляйте себе момент исполнения желания с видом от первого лица
  </div>
  <div class="rule">
    <brx-icon name="@brx.square-star" color="primary" />
    Точно представляйте себе то состояние и ощущения, которые вы будете испытывать, когда мечта исполнится
  </div>
  <div class="rule">
    <brx-icon name="@brx.square-star" color="primary" />
    Максимально конкретно и подробно опишите свою мечту, как будто бы она уже достигнута и запишите
  </div>
  <div class="rule">
    <brx-icon name="@brx.square-star" color="primary" />
    Постоянно благодарите Вселенную за то, что она дает. Ведь она щедра и ей ничего не жалко для вас
  </div>
</div>

<div class="examples-title">Как надо:</div>
<div class="examples">
  <div class="example">
    <brx-icon class="top-quote" name="@brx.quote" [size]="25" />
    <div class="text">
      <div class="text-part">
        <strong>Мечта:</strong><br />
        Я владею Toyota Camry 70 2022г. Кожаный салон, пробег не больше 10000 км, цвета черный асфальт
      </div>
      <div class="text-part">
        <strong>Чувства:</strong><br />
        Я чувствую себя свободной и независимой, эйфория заполняет меня, я мчусь по ночным дорогам и ветер
        развевает мне волосы
      </div>
      <div class="text-part">
        <strong>Совет себе:</strong><br />
        Посмотри объявления на сайтах по продаже авто, лайкай если что-то понравилось
      </div>
    </div>
    <brx-icon class="bottom-quote" name="@brx.quote" [size]="25" />
  </div>
  <div class="example">
    <brx-icon class="top-quote" name="@brx.quote" [size]="25" />
    <div class="text">
      <div class="text-part">
        <strong>Мечта:</strong><br />
        Я - счастливая, здоровая, энергичная, с подтянутой фигурой и весом 50 кг
      </div>
      <div class="text-part">
        <strong>Чувства:</strong><br />
        Я чувствую себя легкой и свободной, меня переполняет энергия и позитивные эмоции. Я чувствую, что могу
        свернуть горы, моей энергии хватает на всё, что я запланировала
      </div>
      <div class="text-part">
        <strong>Совет себе:</strong><br />
        Обязательно составь план питания и восполни дефицит витаминов и минералов
      </div>
      <brx-icon class="bottom-quote" name="@brx.quote" [size]="25" />
    </div>
  </div>
</div>

<div class="examples-title">Как не надо:</div>
<div class="examples bad">
  <div class="example">
    <brx-icon class="top-quote" name="@brx.quote" [size]="25" />
    <div class="text">
      <div class="text-part">
        <strong>Мечта:</strong><br />
        Хочу сделать тридцадку
      </div>
      <div class="text-part">
        <strong>Чувства:</strong><br />
        Кайф
      </div>
      <div class="text-part">
        <strong>Совет себе:</strong><br />
        Работай
      </div>
    </div>
    <brx-icon class="bottom-quote" name="@brx.quote" [size]="25" />
  </div>
  <div class="example">
    <brx-icon class="top-quote" name="@brx.quote" [size]="25" />
    <div class="text">
      <div class="text-part">
        <strong>Мечта:</strong><br />
        Хочу рекламник на Мальдивы
      </div>
      <div class="text-part">
        <strong>Чувства:</strong><br />
        Релакс
      </div>
      <div class="text-part">
        <strong>Совет себе:</strong><br />
        Учи Мальдивы!
      </div>
      <brx-icon class="bottom-quote" name="@brx.quote" [size]="25" />
    </div>
  </div>
</div>
