import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ChatContactExpand,
  ChatContactListRequest,
  ChatContactsListItem,
  ChatContactsListItemDetailsDealInfo,
  ChatContactsListResponse,
  ChatContactsListType,
  ChatContactsSearchRequest,
  ChatContactsSearchResponse,
  ContactChatReadRequest,
  CrmTaskItem,
  DefaultResponse,
} from '@api-clients/crm-api-client';
import { map, Observable } from 'rxjs';
import { AppConfig } from '../../../../environments/environment';

// Минимальный набор данных для начального отображения карточки
const defaultExpandList: ChatContactExpand[] = [
  ChatContactExpand.LastMessage, // сообщение будет обновлено при загрузке чата
  ChatContactExpand.TourPackagesStats,
  ChatContactExpand.DealInfo,
  ChatContactExpand.HasMobileApplication,
  ChatContactExpand.DealTouchActionsStats,
];

// Данные, которые регулярно требуют обновления
export const defaultExpandListForRefresh: ChatContactExpand[] = [
  ChatContactExpand.LastMessage, // сообщение будет обновлено при загрузке чата, если пропустили на сокете
  ChatContactExpand.DealInfo, // Движение сделки, перенос и прочее
  ChatContactExpand.DealTouchActionsStats, // Статистика касаний (обновляется раз в N минут)
];

@Injectable({
  providedIn: 'root',
})
export class ChatContactsService {
  constructor(private http: HttpClient) {}

  /**
   * @note listType: ChatsAndDeals - может возвращать записей > limit
   */
  getContactsListAll(): Observable<ChatContactsListResponse> {
    const body: ChatContactListRequest = {
      expand: [...defaultExpandList, ChatContactExpand.Recommendation],
      contactIds: [],
      query: '',
      listType: ChatContactsListType.ChatsAndDeals, // может возвращать записей > limit
    };
    return this.http.post<ChatContactsListResponse>(`${AppConfig.crmApiUrl}/chat/contacts/list`, body);
  }

  getContactsListWithDeals(): Observable<ChatContactsListResponse> {
    const body: ChatContactListRequest = {
      expand: [...defaultExpandList, ChatContactExpand.Recommendation],
      contactIds: [],
      query: '',
      listType: ChatContactsListType.DealsChats,
    };
    return this.http.post<ChatContactsListResponse>(`${AppConfig.crmApiUrl}/chat/contacts/list`, body);
  }

  getContactsListUnread(): Observable<ChatContactsListResponse> {
    const body: ChatContactListRequest = {
      expand: [...defaultExpandList, ChatContactExpand.Recommendation],
      contactIds: [],
      query: '',
      listType: ChatContactsListType.UnreadChats,
    };
    return this.http.post<ChatContactsListResponse>(`${AppConfig.crmApiUrl}/chat/contacts/list`, body);
  }

  getContactsListGroups(): Observable<ChatContactsListResponse> {
    const body: ChatContactListRequest = {
      expand: [ChatContactExpand.LastMessage],
      contactIds: [],
      query: '',
      listType: ChatContactsListType.GroupChats,
      // limit: 20,
    };
    return this.http.post<ChatContactsListResponse>(`${AppConfig.crmApiUrl}/chat/contacts/list`, body);
  }

  getContactById(contactId: string, expands?: ChatContactExpand[]): Observable<ChatContactsListItem> {
    const body: ChatContactListRequest = {
      expand: expands || [ChatContactExpand.LastMessage],
      contactIds: [contactId],
      query: '',
    };
    return this.http.post<ChatContactsListResponse>(`${AppConfig.crmApiUrl}/chat/contacts/list`, body).pipe(
      map((response: ChatContactsListResponse) => {
        return response.list[0];
      }),
    );
  }

  getContactDealInfoById(contactId: string): Observable<ChatContactsListItemDetailsDealInfo> {
    const body: ChatContactListRequest = {
      expand: [ChatContactExpand.DealInfo],
      contactIds: [contactId],
      query: '',
    };
    return this.http.post<ChatContactsListResponse>(`${AppConfig.crmApiUrl}/chat/contacts/list`, body).pipe(
      map((response: ChatContactsListResponse) => {
        return response.list[0].crmDetails?.dealInfo;
      }),
    );
  }

  getContactNextTaskById(contactId: string): Observable<CrmTaskItem> {
    const body: ChatContactListRequest = {
      expand: [ChatContactExpand.NextTask],
      contactIds: [contactId],
      query: '',
    };
    return this.http.post<ChatContactsListResponse>(`${AppConfig.crmApiUrl}/chat/contacts/list`, body).pipe(
      map((response: ChatContactsListResponse) => {
        return response.list[0].crmDetails?.nextTask;
      }),
    );
  }

  readChat(contactId: string, fromPlace: string): Observable<DefaultResponse> {
    const body: ContactChatReadRequest = {
      contactId,
      fromPlace,
    };
    return this.http.post<DefaultResponse>(`${AppConfig.crmApiUrl}/chat/contacts/read-chat`, body);
  }

  readAllChats(): Observable<DefaultResponse> {
    return this.http.post<DefaultResponse>(`${AppConfig.crmApiUrl}/chat/contacts/read-all-chats`, {});
  }

  /**
   * Поисковый запрос (номер телефона | имя контакта в чате | имя в карточке)
   */
  searchContacts(searchQuery: string): Observable<ChatContactsSearchResponse> {
    const body: ChatContactsSearchRequest = {
      searchQuery,
    };
    return this.http.post<ChatContactsSearchResponse>(`${AppConfig.crmApiUrl}/chat/contacts/search`, body);
  }
}
