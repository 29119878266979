<div class="header">
  <h2>{{ 'PAGES.SETTINGS.WHATSAPP.TEMPLATES_TITLE' | translate }}</h2>
  <app-close-modal-button [dialogRef]="dialogRef" />
</div>
<div class="settings-content">
  <div class="templates column">
    <h3>{{ 'PAGES.SETTINGS.WHATSAPP.ADD_TEMPLATE' | translate }}</h3>
    @if (templates?.length) {
      <div class="templates">
        @for (template of templates; track template) {
          <app-whatsapp-template-manage-item
            [id]="template.id"
            [key]="template.key"
            [value]="template.value"
            [type]="template.type"
            [alias]="template.alias"
            (removed)="refreshTemplateList()"
            (edited)="editTemplate($event)"
          />
        }
      </div>
    } @else {
      {{ 'PAGES.SETTINGS.WHATSAPP.TEMPLATES_LIST_EMPTY' | translate }}
    }
  </div>
  <div class="controllers column">
    <h3>{{ 'PAGES.SETTINGS.WHATSAPP.TEMPLATES_LIST' | translate }}</h3>
    <app-whatsapp-template-form [template]="currentTemplate" (saved)="refreshTemplateList()">
    </app-whatsapp-template-form>
  </div>
</div>
