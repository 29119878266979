import { Component, DestroyRef, EventEmitter, inject, Input, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  DefaultResponse,
  DeleteWhatsappMessageTemplateRequest,
  WhatsappMessageTemplate,
  WhatsappMessageTemplateTypeEnum,
} from '@api-clients/crm-api-client';
import { MessageTemplatesApiService } from '../../services/message-templates-api.service';
import { ScreenTypes } from '../../../../../../core/services/amplitude/amplitudeEventData';
import { AmplitudeTrackService } from '../../../../../../core/services/amplitude/amplitude-track.service';
import { QUICK_MESSAGE_DELETE } from '../../../../../../core/services/amplitude/amplitudeEvents';

@Component({
  selector: 'app-whatsapp-template-manage-item',
  templateUrl: './whatsapp-template-manage-item.component.html',
  styleUrls: ['./whatsapp-template-manage-item.component.scss'],
})
export class WhatsappTemplateManageItemComponent {
  private readonly messageTemplatesApiService = inject(MessageTemplatesApiService);
  private readonly amplitudeTrackService = inject(AmplitudeTrackService);
  private readonly destroyRef = inject(DestroyRef);

  public readonly screenType: ScreenTypes = ScreenTypes.QUICK_ANSWERS;
  @Input() id: number;
  @Input() key = '';
  @Input() value = '';
  @Input() type: WhatsappMessageTemplateTypeEnum;
  @Input() alias?: string | null = null;
  @Output() removed: EventEmitter<void> = new EventEmitter<void>();
  @Output() edited: EventEmitter<WhatsappMessageTemplate> = new EventEmitter<WhatsappMessageTemplate>();

  get isUserDefined() {
    return this.type === WhatsappMessageTemplateTypeEnum.UserDefined;
  }

  edit() {
    const template: WhatsappMessageTemplate = {
      id: this.id,
      key: this.key,
      value: this.value,
      type: this.type,
      alias: this.alias,
    };
    this.edited.emit(template);
  }

  delete() {
    const body: DeleteWhatsappMessageTemplateRequest = {
      id: this.id,
    };
    this.messageTemplatesApiService
      .delete(body)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((result: DefaultResponse) => {
        if (result.success) {
          this.removed.emit();
          this.amplitudeTrackService.trackEvent(QUICK_MESSAGE_DELETE, { screenType: this.screenType });
        }
      });
  }
}
