<div class="notify-wrapper">
  @for (notify of notifyList(); track notify) {
    <div [ngClass]="notify.type" [@inOutAnimation] class="notify">
      <div class="header">
        <div class="title">
          @if (notify.type !== 'simple') {
            <img src="./assets/icons/notify/{{ notify.type }}.svg" alt="" />
          }
          <h5>{{ notify.title }}</h5>
        </div>
        <app-close-modal-button (click)="close(notify)" class="close" [isAbsolute]="false" [iconSize]="10" />
      </div>
      <div class="body">
        @if (notify?.textList; as texts) {
          <ul>
            @for (text of texts; track text) {
              <li class="text">{{ text }}</li>
            }
          </ul>
        } @else {
          <p class="text">{{ notify.text }}</p>
        }
      </div>
    </div>
  }
</div>
