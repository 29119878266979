import { inject, Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { WhatsappTemplatesSettingsComponent } from '../whatsapp-templates-settings.component';

@Injectable({
  providedIn: 'root',
})
export class MessageTemplatesModalService {
  private readonly matDialog = inject(MatDialog);

  showModal(): MatDialogRef<WhatsappTemplatesSettingsComponent> {
    return this.matDialog.open(WhatsappTemplatesSettingsComponent, {
      panelClass: 'modal-panel-rounded',
    });
  }
}
