<brx-modal-header text="Мечты" [dialogRef]="dialogRef" />
<brx-loader-fullscreen [isLoading]="isLoading()" [error]="error()" />

@if (!isLoading() && dreams()) {
  <div class="how-to-title">Прежде чем ты начнешь заполнять свою мечту (цель), прочитай подсказку</div>
  <div class="how-to" (click)="showHowToModal()">
    <brx-icon name="@brx.milky-way" [size]="24" />
    <div class="text">Как правильно ставить цель / идти к мечте?</div>
    <brx-icon name="@brx.arrow-right" [size]="20" />
  </div>

  @if (notDoneDreamsCount()) {
    <div class="group">
      @for (dream of dreams(); track dream) {
        @if (!isDreamDone(dream)) {
          <app-dream-form [dream]="dream" (removeDream)="removeDream(dream)" />
        }
      }
      <span class="add-dream" (click)="addDream()"
        ><brx-icon name="@brx.plus" />
        <div class="label">Добавить</div></span
      >
    </div>
  } @else {
    <div class="no-dreams">
      <img class="icon" src="../../../assets/icons/love-smile.svg" />
      <div class="title">Добавим мечту?</div>
      <button brx-button color="blue" (click)="addDream(true)">Добавить</button>
    </div>
  }

  @if (doneDreamsCount()) {
    <div
      class="done-dreams-title"
      (click)="isDoneDreamsVisible.set(!isDoneDreamsVisible())"
      [class.is-open]="isDoneDreamsVisible()"
    >
      <brx-icon class="main-icon" name="@brx.crown" />
      <div class="text">
        Мечты, которые уже исполнились
        <brx-badge color="primary" size="small">{{ doneDreamsCount() }}</brx-badge>
      </div>
      <brx-icon class="icon-arrow" name="@brx.arrow-right" [size]="20" />
    </div>
    @if (isDoneDreamsVisible()) {
      <div class="group">
        @for (dream of dreams(); track dream) {
          @if (isDreamDone(dream)) {
            <app-dream-form [dream]="dream" (removeDream)="removeDream(dream)" />
          }
        }
      </div>
    }
  }

  @if (notDoneDreamsCount() || (doneDreamsCount() && isDoneDreamsVisible()) || isSaving()) {
    <div class="control-buttons">
      @if (saveError()) {
        <brx-error [error]="saveError()" />
      }
      @if (isSuccessSaved()) {
        <app-alert-label [type]="successLabelType">Мечты успешно сохранены!</app-alert-label>
      }
      <button brx-button color="blue" [isLoading]="isSaving()" (click)="saveDreams()">Сохранить</button>
    </div>
  }
}
