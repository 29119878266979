import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  inject,
  OnInit,
  signal,
} from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { MatTooltip } from '@angular/material/tooltip';
import { NavigationEnd, Router, RouterLink, RouterLinkActive } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { filter } from 'rxjs';
import { ChatsFacade } from '../../../../+state/chats/chats.facade';
import { WhatsappContactsFacade } from '../../../../+state/whatsapp-contacts/whatsapp-contacts.facade';
import { AsideChatsComponent } from '../../../aside-chats/components/aside-chats/aside-chats.component';
import { ChatListStageFilterKeys, ChatsMenuCounter } from '../../../chats/chats';
import { ChatsContainerTabService } from '../../../chats/container/components/chats-container-tab/chats-container-tabs-service';
import { DealItemsCounterService } from '../../../deals/services/deal-items-counter.service';
import { ZenmodeAbTestService } from '../../services/zenmode-ab-test.service';
import { AddDealPopupService } from '../../../deals/modules/add-deal/services/add-deal-popup.service';

@Component({
  selector: 'app-aside-menu',
  templateUrl: './aside-menu.component.html',
  styleUrls: ['./aside-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatTooltip, TranslateModule, AsyncPipe, RouterLink, AsideChatsComponent, RouterLinkActive],
})
export class AsideMenuComponent implements OnInit {
  public dealsTotalCount = toSignal(this.dealItemsCounterService.dealsNumber$);
  public unreadChatsCount = toSignal(this.whatsappContactsFacade.unreadChats$);
  public isOnlyZenmode$ = this.zenmodeAbTestService.isOnlyZenmodeForWorker$;

  public currentStageChatListFilter = toSignal(this.chatsFacade.currentStageFilter$);

  public getStageCounterBy$ = this.chatsFacade.chatContactsStageFilteredCountBy$;
  public currentUrl = signal(this.router.url);
  public isChatsRoute = computed(() => {
    return this.currentUrl().startsWith('/chats');
  });

  public currentStageChatListFilterIsAll = computed(() => {
    return this.currentStageChatListFilter() === ChatListStageFilterKeys.ALL;
  });

  public isDaysFiltersOpenedByClick = signal(true);

  public isDaysFiltersOpened = computed<boolean>(() => {
    return (
      this.isDaysFiltersOpenedByClick() &&
      this.chatsFacade.workInProgressStages.includes(this.currentStageChatListFilter())
    );
  });

  public readonly chatListStageFilterKeys = ChatListStageFilterKeys;

  private destroyRef = inject(DestroyRef);
  private chatsTabService = inject(ChatsContainerTabService);

  // public chatsUrgentCounter = signal<ChatsMenuCounter | null>(null);
  // public chatsNewCounter = signal<ChatsMenuCounter | null>(null);

  constructor(
    private zenmodeAbTestService: ZenmodeAbTestService,
    private dealItemsCounterService: DealItemsCounterService,
    private whatsappContactsFacade: WhatsappContactsFacade,
    private chatsFacade: ChatsFacade,
    private router: Router,
    private addDealPopupService: AddDealPopupService,
  ) {}

  ngOnInit(): void {
    this.router.events
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter(event => event instanceof NavigationEnd),
      )
      .subscribe(() => {
        this.currentUrl.set(this.router.url);
      });
  }

  public showAddDealPopup() {
    this.addDealPopupService.showAddDealPopup();
  }

  public setStageFilter(stage: ChatListStageFilterKeys): void {
    const isStageChange = this.currentStageChatListFilter() !== stage;

    this.chatsTabService.closeAll();
    this.chatsFacade.setStageChatListFilter(stage);

    const stageToSetInWorkInProgress = this.chatsFacade.workInProgressStages.includes(stage);
    // Если выбираемый фильтр по стейджу не один из определяемых как "В работе", то очищаем Touch Filter
    if (!stageToSetInWorkInProgress) {
      this.chatsFacade.resetTouchTodayFilter();
    }

    // Эксперименты с разделом "в работе/на сегодня"
    const isStageWithDays = stage === ChatListStageFilterKeys.IN_PROGRESS;
    if (isStageWithDays) {
      // todo: уточнить, будем запоминать выбор пользователя?
      if (isStageChange) {
        // При переключении открываем (запрос от Макса)
        this.isDaysFiltersOpenedByClick.set(true);
      } else {
        // Находясь в одном разделе - toggle
        this.isDaysFiltersOpenedByClick.set(!this.isDaysFiltersOpenedByClick());
      }
    }
  }

  public resetStageFilter(): void {
    this.chatsFacade.resetStageChatListFilter();
    this.chatsFacade.resetTouchTodayFilter();
  }

  public openDealsRoute(): void {
    void this.router.navigate(['/deals']);
  }

  protected readonly toSignal = toSignal;
}
