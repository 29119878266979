<div class="dream">
  <brx-button-remove (click)="removeDream.emit()" />
  <label>Моя мечта (цель)</label>
  <textarea
    class="dream-textarea"
    rows="2"
    spellcheck="true"
    placeholder="Понятным языком, без сленга, как будто мечта уже осуществилась, от первого лица..."
    appResizableTextarea
    [maxHeightInPx]="250"
    matInput
    [(ngModel)]="dream().dream"
    (input)="setDreamChanged()"
  ></textarea>
  <div class="two-column">
    <div class="column">
      <label>Ощущения, когда мечта осуществится</label>
      <textarea
        rows="1"
        spellcheck="true"
        placeholder="Четко, развернуто, какие эмоции вы испытываете..."
        appResizableTextarea
        [maxHeightInPx]="250"
        matInput
        [(ngModel)]="dream().feelingsWhenDone"
        (input)="setDreamChanged()"
      >
      </textarea>
    </div>
    <div class="column">
      <label>Совет от будущего "Я"</label>
      <textarea
        rows="1"
        spellcheck="true"
        placeholder="Только честно..."
        appResizableTextarea
        [maxHeightInPx]="250"
        matInput
        [(ngModel)]="dream().adviceFromFutureSelf"
        (input)="setDreamChanged()"
      >
      </textarea>
    </div>
  </div>
  <div class="two-column">
    <div class="column">
      @if (dream().shownMotivationsCount) {
        <div class="motivations">
          <a (click)="showMotivationsForDream()"
            >Посмотреть показанные советы
            <brx-badge color="primary" size="small">{{ dream().shownMotivationsCount }}</brx-badge>
          </a>
        </div>
      }
    </div>
    <div class="column is-done-wrapper">
      <brx-checkbox [(ngModel)]="dream().isDone">Мечта исполнена</brx-checkbox>
    </div>
  </div>
  @if (this.isDreamChanged()) {
    <app-alert-label class="change-info-label" [type]="dangerLabelType" [noMargin]="true"
      >При изменении существующей мечты все показанные советы по ней будут удалены!</app-alert-label
    >
  }
</div>
