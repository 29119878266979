<div class="header">
  @if (text()) {
    {{ text() }}
  } @else {
    <ng-content />
  }
  @if (dialogRef()) {
    <app-close-modal-button [dialogRef]="dialogRef()" />
  }
</div>
