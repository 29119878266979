import { AsyncPipe } from '@angular/common';
import { Component, DestroyRef, inject } from '@angular/core';
import { BehaviorSubject, Observable, Subscription, timer } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { CountResponse } from '@api-clients/crm-api-client/models';
import {
  NotificationsCenterApiService,
} from '../../../notifications-center/services/notifications-center-api.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

const TIMER_DUE_TIME = 60 * 1000;

@Component({
  selector: 'app-notifications-center-count',
  templateUrl: './notifications-center-count.component.html',
  styleUrls: ['./notifications-center-count.component.scss'],
  imports: [AsyncPipe],
})
export class NotificationsCenterCountComponent {
  private readonly destroyRef = inject(DestroyRef);

  public count$: Observable<CountResponse>;
  public timer: Subscription;
  event$ = new BehaviorSubject(true);

  constructor(private notificationsCenterApi: NotificationsCenterApiService) {
    this.count$ = this.event$.pipe(switchMap(() => this.notificationsCenterApi.getCountNew()));
    this.timer = timer(TIMER_DUE_TIME)
      // 2 запроса на старте приложения, пока решаем debounce
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.manualRefresh());

    this.notificationsCenterApi.getCountNewEvent$
      .subscribe(() => this.manualRefresh());
  }

  public manualRefresh() {
    this.event$.next(true);
  }
}
