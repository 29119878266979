<div
  class="badge"
  [ngClass]="classList()"
  [style.--background-color]="cssBackgroundColor()"
  [style.--icon-color]="cssIconColor()"
>
  @if (isFuzzy()) {
    <brx-icon name="@brx.fuzzy-circle" class="fuzzy-circle" size="24" />
  }
  @if (icon()) {
    <brx-icon class="icon" [name]="icon()" />
  }
  <div class="badge-content">
    <ng-content />
  </div>
</div>
