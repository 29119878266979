@if (videos.length) {
  @if (disableSelection === false) {
    <div class="selected-info">Выбрано {{ selectedVideos.size }} из {{ videos.length }}</div>
  }

  <div class="categories-list">
    <div class="video-list">
      @for (category of categories; track category) {
        @for (video of category.videos; track video) {
          <div class="video">
            @if (isFirstInCategory(video)) {
              <div class="category-name">{{ category.name }}</div>
            }
            @if (isPromo(video)) {
              <span class="status-icon is-promo">Промо</span>
            }
            <div
              class="rounded-corners"
              (mouseenter)="showVideoPreview(video)"
              (mouseleave)="hideVideoPreview()"
            >
              @if (this.videoWithVisiblePreview() === video) {
                <video class="video-preview" autoplay (click)="showGallery(video)">
                  <source [src]="video.contentLink" type="video/mp4" />
                </video>
              } @else {
                <img class="image-preview" [src]="video.previewUrl" (click)="showGallery(video)" />
              }
              <img class="show-gallery" src="assets/icons/magnifier-plus-shadow.svg" />

              @if (disableSelection === false) {
                <app-checkbox-rounded
                  [isChecked]="isVideoSelected(video)"
                  (changed)="selectVideo($event, video)"
                />
              }
            </div>
          </div>
        }
      }
    </div>
  </div>
} @else {
  <app-alert-label [type]="dangerAlertLabelType"> Видео для этого тура не найдены</app-alert-label>
}
