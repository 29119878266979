import {
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
} from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { NgModel } from '@angular/forms';

export interface TextareaResizeEvent {
  width: number;
  height: number;
}

@Directive({
  selector: '[appResizableTextarea]',
})
export class ResizableTextareaDirective implements OnInit, OnDestroy {
  @Input() debounceTimeInMs = 50;
  @Input() maxHeightInPx?: number;
  @Input() triggerResize?: EventEmitter<void>;
  @Output() resizeEvent = new EventEmitter<TextareaResizeEvent>();

  private resizeSubject = new Subject<void>();
  private resizeSubscription: Subscription;
  private resizeTriggerSubscription: Subscription;
  private modelChangeSubscription: Subscription | undefined;

  constructor(
    private elementRef: ElementRef,
    @Optional() private ngModel: NgModel, // Используем @Optional() для необязательного внедрения
  ) {}

  ngOnInit() {
    // Подписываемся на изменения модели только если NgModel существует
    if (this.ngModel) {
      this.modelChangeSubscription = this.ngModel.valueChanges
        ?.pipe(debounceTime(this.debounceTimeInMs))
        .subscribe(() => {
          this.adjustTextareaHeight();
        });
    } else {
      // Иначе подписываемся на событие input
      this.elementRef.nativeElement.addEventListener('input', () => this.resizeSubject.next());
    }

    this.resizeSubscription = this.resizeSubject
      .pipe(debounceTime(this.debounceTimeInMs))
      .subscribe(() => this.adjustTextareaHeight());

    if (this.triggerResize) {
      this.resizeTriggerSubscription = this.triggerResize.subscribe(() => this.resizeSubject.next());
    }

    this.resizeSubject.next();
  }

  ngOnDestroy() {
    this.resizeSubscription?.unsubscribe();
    this.resizeTriggerSubscription?.unsubscribe();
    this.modelChangeSubscription?.unsubscribe();
  }

  private adjustTextareaHeight(): void {
    const textarea = this.elementRef.nativeElement;

    // Если у textarea есть граница, то нужно учесть ее в расчете высоты
    const computedStyle = window.getComputedStyle(textarea as Element);
    const borderTop = parseInt(computedStyle.getPropertyValue('border-top-width'), 10);
    const borderBottom = parseInt(computedStyle.getPropertyValue('border-bottom-width'), 10);

    const oldHeight = (textarea.clientHeight as number) + borderTop + borderBottom;
    textarea.style.height = `auto`;
    let newHeight = (textarea.scrollHeight as number) + borderTop + borderBottom;

    if (this.maxHeightInPx !== undefined && newHeight > this.maxHeightInPx) {
      textarea.style.overflow = 'auto';
      newHeight = this.maxHeightInPx;
    } else {
      textarea.style.overflow = 'hidden';
    }

    if (newHeight !== oldHeight) {
      textarea.style.height = `${newHeight}px`;
      const event: TextareaResizeEvent = {
        width: textarea.offsetWidth,
        height: newHeight,
      };
      this.resizeEvent.emit(event);
    } else {
      textarea.style.height = `${oldHeight}px`;
    }
  }
}
