import { Injectable } from '@angular/core';
import { PhoneItem, ChatSendMessageRequest } from '@api-clients/crm-api-client';
import { Observable, Subject, of } from 'rxjs';
import { concatMap, catchError, first } from 'rxjs/operators';
import { ChatService } from './chat.service';

/**
 * Сервис для отправки сообщений в чат в порядке очереди
 */
@Injectable({
  providedIn: 'root',
})
export class ChatMessageQueueService {
  private messageQueue: Subject<{ phone: PhoneItem; message: ChatSendMessageRequest }> = new Subject();

  constructor(private chatService: ChatService) {
    this.messageQueue
      .pipe(
        concatMap(({ phone, message }) => this.chatService.sendMessage(phone, message)),
        catchError(error => {
          console.error('Error in MessageQueueService:', error);
          return of(null);
        }),
      )
      .subscribe();
  }

  /**
   * Добавление сообщения в очередь на отправку
   */
  queueMessage(phone: PhoneItem, message: ChatSendMessageRequest): void {
    this.messageQueue.next({ phone, message });
  }

  /**
   * Получение сообщений из Observable и добавление их в очередь на отправку
   */
  queueFromObservable(phone: PhoneItem, messages$: Observable<ChatSendMessageRequest[]>): void {
    messages$.pipe(first()).subscribe(messages => {
      messages.forEach(message => this.queueMessage(phone, message));
    });
  }
}
