import { Component, signal, WritableSignal } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { BrxLoaderComponent } from '../brx/loader/brx-loader.component';
import { BrxIconComponent } from '../brx/icon/brx-icon.component';

@Component({
  selector: 'app-recorder-button',
  templateUrl: './recorder-button.component.html',
  styleUrls: ['./recorder-button.component.scss'],
  standalone: true,
  imports: [BrxIconComponent, BrxLoaderComponent, MatTooltip],
})
export class RecorderButtonComponent {
  /**
   * Директива запишет сюда ссылку на signals:
   * isRecording, isProcessing,
   * а также колбэк onButtonClick
   */
  public isRecording: WritableSignal<boolean> = signal(false);
  public isProcessing: WritableSignal<boolean> = signal(false);

  /**
   * Колбэк, который директива установит, чтобы отловить клик.
   */
  public onButtonClick: () => void = () => {};

  public handleClick(): void {
    // При клике вызываем колбэк, который передаст управление директиве
    this.onButtonClick();
  }

  public getTooltip(): string {
    return this.isRecording() ? 'Остановить запись' : 'Нажмите, чтобы диктовать текст';
  }
}
