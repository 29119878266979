@if (disableSelection === false) {
  <div class="selected-info">Выбрано {{ selectedPhotos.size }} из {{ photos.length }}</div>
}

<div class="photo-list">
  @for (photo of visiblePhotos; track photo) {
    <div class="photo">
      <img class="show-gallery" src="assets/icons/magnifier-plus-shadow.svg" />
      <img [src]="photo.previewUrl" (click)="showGallery(photo)" />

      @if (disableSelection === false) {
        <app-checkbox-rounded [isChecked]="isPhotoSelected(photo)" (changed)="selectPhoto($event, photo)" />
      }
    </div>
  }
  @if (hiddenPhotosCount) {
    <div class="photo show-more" (click)="showMorePhotos()">+ {{ hiddenPhotosCount }} фото</div>
  }
</div>
