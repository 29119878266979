import {
  Directive,
  ElementRef,
  Renderer2,
  HostListener,
  ViewContainerRef,
  TemplateRef,
  OnDestroy,
  input,
  output,
  inject,
} from '@angular/core';

/**
 * Директива для отображения контекстного меню при выделении текста
 */
@Directive({
  selector: '[appTextSelection]',
})
export class TextSelectionDirective implements OnDestroy {
  private readonly el = inject(ElementRef);
  private readonly renderer = inject(Renderer2);
  private readonly viewContainerRef = inject(ViewContainerRef);

  appTextSelectionMenu = input<TemplateRef<any> | null>();
  appTextSelectionEnabled = input<boolean>(true);
  lastSelectedText = output<string>();

  private documentMousedownListener: () => void;
  private documentMouseupListener: () => void;

  private getSelection(): Selection | null {
    const selection = window.getSelection();
    if (selection && selection.toString().length > 0) {
      return selection;
    }
    return null;
  }

  @HostListener('mouseup') onMouseUp() {
    if (!this.appTextSelectionEnabled()) {
      return;
    }

    const selection = this.getSelection();
    if (selection) {
      this.lastSelectedText.emit(selection.toString());
      const range = selection.getRangeAt(0).getBoundingClientRect();
      this.showMenu(range);

      // Добавляем глобальный обработчик кликов, чтобы скрывать меню не только по кликам внутри элемента
      this.addDocumentClickListener();
    }
  }

  addDocumentClickListener() {
    // Убираем старый обработчик, если он был
    if (this.documentMousedownListener) {
      this.documentMousedownListener();
    }
    if (this.documentMouseupListener) {
      this.documentMouseupListener();
    }

    const hideMenu = () => {
      if (!this.getSelection()) {
        this.viewContainerRef.clear();
        this.removeDocumentClickListener();
      }
    };

    // Добавляем новый обработчик кликов по всему документу
    this.documentMousedownListener = this.renderer.listen('document', 'mousedown', () => {
      // Нужен таймаут потому что при нажатии текст очищается не сразу
      setTimeout(hideMenu, 50);
    });

    this.documentMouseupListener = this.renderer.listen('document', 'mouseup', () => {
      setTimeout(hideMenu, 50);
    });
  }

  private getScrollableParent(element: HTMLElement): HTMLElement | null {
    let parent: HTMLElement | null = element;

    while (parent) {
      const overflowY = window.getComputedStyle(parent).overflowY;
      const overflowX = window.getComputedStyle(parent).overflowX;

      const isScrollable =
        overflowY === 'scroll' || overflowY === 'auto' || overflowX === 'scroll' || overflowX === 'auto';

      if (isScrollable) {
        return parent; // Если родитель прокручивается, возвращаем его
      }

      parent = parent.parentElement;
    }

    return null; // Если не нашли прокручиваемого родителя
  }

  showMenu(range: DOMRect) {
    // Очистка предыдущего содержимого
    this.viewContainerRef.clear();

    // Вставка меню в DOM (временно, чтобы его измерить)
    let embeddedView = this.viewContainerRef.createEmbeddedView(this.appTextSelectionMenu());
    let menuElement = embeddedView.rootNodes[0];

    this.renderer.setStyle(menuElement, 'position', 'absolute');
    this.renderer.setStyle(menuElement, 'visibility', 'hidden');
    this.renderer.appendChild(this.el.nativeElement, menuElement);

    // После вставки измеряем размеры меню
    const menuWidth = menuElement.offsetWidth;
    const menuHeight = menuElement.offsetHeight;

    // Удаляем элемент из DOM, пока его не нужно отображать.
    // Чтобы ангуляр сам вставил его и правильно проставил контексты.
    // Без этого не работают вызовы функций в шаблоне
    this.renderer.removeChild(this.el.nativeElement, menuElement);

    // Теперь можно позиционировать меню
    const scrollableParent =
      this.getScrollableParent(this.el.nativeElement as HTMLElement) || document.documentElement;
    const parentRect = scrollableParent.getBoundingClientRect();

    const scrollTop = scrollableParent.scrollTop;
    const scrollLeft = scrollableParent.scrollLeft;

    const topPosition = range.top - parentRect.top + scrollTop - menuHeight;
    const leftPosition = range.left - parentRect.left + scrollLeft + range.width / 2 - menuWidth / 2;

    // А теперь настоящая вставка
    embeddedView = this.viewContainerRef.createEmbeddedView(this.appTextSelectionMenu());
    menuElement = embeddedView.rootNodes[0];

    // Устанавливаем корректные стили
    this.renderer.setStyle(menuElement, 'position', 'absolute');
    this.renderer.setStyle(menuElement, 'top', `${topPosition}px`);
    this.renderer.setStyle(menuElement, 'left', `${leftPosition}px`);
  }

  removeDocumentClickListener() {
    if (this.documentMousedownListener) {
      this.documentMousedownListener();
      this.documentMousedownListener = null;
    }

    if (this.documentMouseupListener) {
      this.documentMouseupListener();
      this.documentMouseupListener = null;
    }
  }

  ngOnDestroy() {
    this.removeDocumentClickListener(); // Удаляем слушатель при уничтожении директивы
  }
}
