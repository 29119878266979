import {
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
  CreateWhatsappMessageTemplateRequest,
  DefaultResponse,
  UpdateWhatsappMessageTemplateRequest,
  WhatsappMessageTemplate,
} from '@api-clients/crm-api-client';
import { TranslateModule } from '@ngx-translate/core';
import { FormErrorComponent } from '../../../../../../ui-components/form-error/form-error.component';
import { MessageTemplatesApiService } from '../../services/message-templates-api.service';
import { ScreenTypes } from '../../../../../../core/services/amplitude/amplitudeEventData';
import { AmplitudeTrackService } from '../../../../../../core/services/amplitude/amplitude-track.service';
import {
  NEW_QUICK_MESSAGE,
  QUICK_MESSAGE_EDIT,
} from '../../../../../../core/services/amplitude/amplitudeEvents';

@Component({
  selector: 'app-whatsapp-template-form',
  templateUrl: './whatsapp-template-form.component.html',
  styleUrls: ['./whatsapp-template-form.component.scss'],

  imports: [ReactiveFormsModule, FormErrorComponent, TranslateModule],
})
export class WhatsappTemplateFormComponent implements OnChanges {
  private readonly fb = inject(UntypedFormBuilder);
  private readonly messageTemplatesApiService = inject(MessageTemplatesApiService);
  private readonly amplitudeTrackService = inject(AmplitudeTrackService);
  private readonly destroyRef = inject(DestroyRef);

  @Output() saved: EventEmitter<void> = new EventEmitter<void>();
  @Input() template: WhatsappMessageTemplate = null;
  public form: UntypedFormGroup;
  public readonly screenType: ScreenTypes = ScreenTypes.QUICK_ANSWERS;

  constructor() {
    this.form = this.fb.group({
      key: ['', Validators.required],
      value: ['', Validators.required],
      id: [null],
    });
  }

  get id() {
    return this.form.get('id');
  }
  get key() {
    return this.form.get('key');
  }

  get value() {
    return this.form.get('value');
  }

  onSubmit() {
    if (this.id.value) {
      this.updateTemplate(this.id.value, this.value.value, this.key.value);
    } else {
      this.createTemplate(this.key.value, this.value.value);
    }
  }

  updateTemplate(id: number, value: string, key: string) {
    const body: UpdateWhatsappMessageTemplateRequest = {
      id,
      value,
      key,
    };
    this.messageTemplatesApiService
      .update(body)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((result: DefaultResponse) => {
        if (result.success) {
          this.form.reset();
          this.saved.emit();
          this.amplitudeTrackService.trackEvent(QUICK_MESSAGE_EDIT, { screenType: this.screenType });
        }
      });
  }

  createTemplate(key: string, value: string) {
    const body: CreateWhatsappMessageTemplateRequest = {
      key,
      value,
    };
    this.messageTemplatesApiService
      .create(body)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((result: DefaultResponse) => {
        if (result.success) {
          this.form.reset();
          this.saved.emit();
          this.amplitudeTrackService.trackEvent(NEW_QUICK_MESSAGE, { screenType: this.screenType });
        }
      });
  }

  populateForm(template: WhatsappMessageTemplate) {
    this.key.setValue(template.key);
    this.value.setValue(template.value);
    this.id.setValue(template.id);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      !changes?.template?.isFirstChange() &&
      changes?.template?.currentValue !== changes?.template?.previousValue
    ) {
      this.populateForm(changes.template.currentValue);
    }
  }

  resetForm() {
    this.form.reset();
  }
}
