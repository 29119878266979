import { ChangeDetectionStrategy, Component, inject, resource, Signal, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EmployeeDreamMotivation } from '@api-clients/api-client';
import { EmployeeDream } from '@api-clients/api-client/dist/models/employee-dream';
import { BrxModalHeaderComponent } from '../../../../ui-components/brx/modal-header/brx-modal-header.component';
import { BrxLoaderFullscreenComponent } from '../../../../ui-components/brx/loader-fullscreen/brx-loader-fullscreen.component';
import { DreamMotivationsApiService } from '../../services/dream-motivations-api.service';
import { DreamMotivationsModalService } from '../../services/dream-motivations-modal.service';

@Component({
  selector: 'app-frontend-dream-motivation-list',
  standalone: true,
  imports: [CommonModule, BrxModalHeaderComponent, BrxLoaderFullscreenComponent],
  templateUrl: './dream-motivation-list.component.html',
  styleUrl: './dream-motivation-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DreamMotivationListComponent {
  private data = inject<{ dream: EmployeeDream }>(MAT_DIALOG_DATA);
  public readonly dialogRef = inject(MatDialogRef);
  private readonly dreamMotivationsApiService = inject(DreamMotivationsApiService);
  private readonly dreamMotivationsModalService = inject(DreamMotivationsModalService);

  public dream = signal<EmployeeDream>(this.data.dream);
  private motivationsResource = resource({
    loader: () => this.dreamMotivationsApiService.list(this.dream().id),
  });
  public motivations: Signal<EmployeeDreamMotivation[]> = this.motivationsResource.value;
  public isLoading = this.motivationsResource.isLoading;
  public error = this.motivationsResource.error;

  showMotivation(motivation: EmployeeDreamMotivation): void {
    this.dreamMotivationsModalService.showMotivation(this.dream(), motivation);
  }
}
