import { ChangeDetectionStrategy, Component, computed, DestroyRef, inject, signal } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ChatListStageFilterKeys } from '../../../chats/chats';
import { ChatsContainerTabService } from '../../../chats/container/components/chats-container-tab/chats-container-tabs-service';
import { AddDealPopupService } from '../../../deals/modules/add-deal/services/add-deal-popup.service';
import { ChatsDataService } from '../../../../+state/chats/store/chats.data.service';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatTooltip } from '@angular/material/tooltip';
import { CounterForChatsMenu, CounterForChatsMenuItem } from '../../../../+state/chats/store/chats.models';
import { NgTemplateOutlet } from '@angular/common';
import { createCounterForMenuInitialValue } from '../../../../+state/chats/store/chats.data.store.utils.filter';

@Component({
  selector: 'app-aside-menu-in-chats',
  templateUrl: './aside-menu-in-chats.component.html',
  styleUrls: ['./aside-menu-in-chats.component.scss'],
  imports: [TranslateModule, MatTooltip, MatTooltip, NgTemplateOutlet],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AsideMenuInChatsComponent {
  private readonly chatsTabService = inject(ChatsContainerTabService);
  private readonly chatsFacadeService = inject(ChatsDataService);

  public unreadChatsCount = this.chatsFacadeService.unreadChatsCount;
  public currentStageChatListFilter = this.chatsFacadeService.appliedFiltersStage;
  public currentStageChatListFilterIsAll = this.chatsFacadeService.isAllChatsStageSelected;

  public isDaysFiltersOpenedByClick = signal(true);
  public isDaysFiltersOpened = computed<boolean>(() => {
    return this.isDaysFiltersOpenedByClick() && this.chatsFacadeService.isWorkInProgressStageGroupSelected();
  });

  public readonly chatListStageFilterKeys = ChatListStageFilterKeys;
  counters = toSignal(this.chatsFacadeService.counterForAsideNavbar$, {
    initialValue: createCounterForMenuInitialValue(),
  });

  hasUnread = computed((): boolean => {
    return this.unreadChatsCount() > 0;
  });

  constructor(private addDealPopupService: AddDealPopupService) {}

  public showAddDealPopup() {
    this.addDealPopupService.showAddDealPopup();
  }

  getConditionalCounter(stage: keyof CounterForChatsMenu): CounterForChatsMenuItem {
    return this.counters()[stage];
  }

  public isActiveStage(stage: ChatListStageFilterKeys): boolean {
    return this.currentStageChatListFilter() === stage;
  }

  public hasUnreadMarker(counter: CounterForChatsMenuItem): boolean {
    return counter.hasUnread || counter.hasUnreadDealChanges;
  }

  public setStageFilter(stage: ChatListStageFilterKeys): void {
    const isStageChange = this.currentStageChatListFilter() !== stage;

    this.chatsTabService.closeAll();
    this.chatsFacadeService.setStageFilter(stage);

    // Эксперименты с разделом "в работе/на сегодня"
    const isStageWithDays = stage === ChatListStageFilterKeys.IN_PROGRESS;
    if (isStageWithDays) {
      if (isStageChange) {
        // При переключении открываем (запрос от Макса)
        this.isDaysFiltersOpenedByClick.set(true);
      } else {
        // Находясь в одном разделе - toggle
        this.isDaysFiltersOpenedByClick.set(!this.isDaysFiltersOpenedByClick());
      }
    }
  }

  public resetStageFilter(): void {
    this.chatsFacadeService.resetStageFilter();
  }
}
