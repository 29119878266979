import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { GetTourContentRequest } from '@api-clients/api-client';
import { GetTourContentManagerOfferTourRequest } from '@api-clients/api-client/models/get-tour-content-manager-offer-tour-request';
import { TourContent } from '@api-clients/api-client/models/tour-content';
import { Observable } from 'rxjs';
import { AppConfig } from '../../../../environments/environment';
import {
  ApiContentType,
  ContentCreatorManagerOffer,
  ContentCreatorManagerOfferCardProps,
} from '../interfaces/content-creator.interface';

@Injectable({
  providedIn: 'root',
})
export class ContentCreatorApiService {
  private readonly http = inject(HttpClient);

  getTourContent(
    tour: ContentCreatorManagerOffer,
    expands?: ApiContentType[],
    crmCardProps?: ContentCreatorManagerOfferCardProps,
  ): Observable<TourContent> {
    const request: GetTourContentRequest = {
      tourId: tour.tourId,
      expands,
      managerOfferExpandParams: undefined,
    };

    if (crmCardProps) {
      request.managerOfferExpandParams = {
        tours: [
          {
            tourId: tour.tourId,
            claim: tour.claim,
            priceInCurrency: tour.price?.value,
            currency: tour.price?.currency,
          },
        ],
        ...crmCardProps,
      };
    }

    return this.http.post<TourContent>(`${AppConfig.commonApiUrl}/content-creator/get-tour-content`, {
      ...request,
    });
  }

  getTourContentManagerOffer(
    tourIds: string[],
    crmCardProps?: ContentCreatorManagerOfferCardProps,
  ): Observable<TourContent> {
    const expands = [ApiContentType.ManagerOffer];
    const request: GetTourContentRequest = {
      tourId: tourIds[0],
      expands,
      managerOfferExpandParams: {
        tours: tourIds.map(tourId => {
          const tour: GetTourContentManagerOfferTourRequest = {
            tourId,
          };

          return tour;
        }),
      },
    };

    if (crmCardProps) {
      request.managerOfferExpandParams = {
        ...request.managerOfferExpandParams,
        ...crmCardProps,
      };
    }

    return this.http.post<TourContent>(`${AppConfig.commonApiUrl}/content-creator/get-tour-content`, {
      ...request,
    });
  }
}
