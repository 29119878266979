import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService, WorkerStateService } from '../core/services';

@Injectable({ providedIn: 'root' })
export class AuthGuard {
  public hasAccess: boolean;

  constructor(
    private router: Router,
    private authenticationService: AuthService,
    private workerStateService: WorkerStateService,
  ) {}

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    this.workerStateService.hasAccess$.subscribe(state => {
      this.hasAccess = !!state;
    });

    if (this.hasAccess) {
      return true;
    }
    this.workerStateService.clearWorkerState();
    this.authenticationService.logout();
    this.router.navigate(['/login']).then(() => {});
    return false;
  }
}
