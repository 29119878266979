import {
  Directive,
  ElementRef,
  inject,
  OnDestroy,
  OnInit,
  Renderer2,
  signal,
  WritableSignal,
  ViewContainerRef,
  ComponentRef,
  input,
  output,
} from '@angular/core';
import { AmplitudeTrackService } from '../../core/services/amplitude/amplitude-track.service';
import { ScreenTypes } from '../../core/services/amplitude/amplitudeEventData';
import { AUDIO_RECORDING_TO_TEXT_CONVERT_CLICK } from '../../core/services/amplitude/amplitudeEvents';
import { AudioTranscriptService } from '../../modules/deals/modules/deals-list/modules/deals-list-content/deals-item/audio/services/audio-transcript.service';
import { RecorderButtonComponent } from '../../ui-components/recorder-button/recorder-button.component';
import { NotifyService } from '../notify/services/notify.service';

/**
 * Директива, которая добавляет «кнопку записи» (RecorderButtonComponent)
 * к textarea, записывает аудио через MediaRecorder, отправляет на сервер,
 * получает расшифрованный текст и вставляет его в textarea
 */
@Directive({
  standalone: true,
  selector: '[appSpeechRecognition]',
})
export class SpeechRecognitionDirective implements OnInit, OnDestroy {
  private readonly elRef = inject<ElementRef<HTMLTextAreaElement>>(ElementRef);
  private readonly renderer = inject(Renderer2);
  private readonly viewContainerRef = inject(ViewContainerRef);
  private readonly audioTranscriptService = inject(AudioTranscriptService);
  private readonly amplitudeTrackService = inject(AmplitudeTrackService);
  private readonly notifyService = inject(NotifyService);

  public screenType = input.required<ScreenTypes>();
  /**
   * Нужно для сценариев, когда у textarea может поменяться плейсхолдер
   */
  public initialPlaceholder = input<string>();
  public recorderButtonMarginBottom = input<number>();
  public recordStart = output();
  public recordEnd = output();

  /**
   * Флаг «идёт ли запись»
   */
  private isRecording: WritableSignal<boolean> = signal(false);

  /**
   * Флаг «идёт ли транскрибация» (обработка)
   */
  private isProcessing: WritableSignal<boolean> = signal(false);

  /**
   * MediaRecorder для записи звука
   */
  private mediaRecorder: MediaRecorder | null = null;

  /**
   * Чанки (части) аудио
   */
  private recordedChunks: BlobPart[] = [];

  /**
   * Ссылка на textarea
   */
  private textareaEl: HTMLTextAreaElement;

  /**
   * Исходный placeholder textarea
   */
  private basePlaceholder: string;

  /**
   * Ссылка на динамически созданный RecorderButtonComponent
   */
  private recorderButtonRef: ComponentRef<RecorderButtonComponent> | null = null;

  public ngOnInit(): void {
    this.textareaEl = this.elRef.nativeElement;
    this.basePlaceholder = this.textareaEl.placeholder;

    // Добавим padding-left, чтобы оставить место под кнопку/иконку
    this.renderer.setStyle(this.textareaEl, 'paddingLeft', '44px');

    this.makeParentRelative();
    this.createRecorderButton();
  }

  public ngOnDestroy(): void {
    this.recorderButtonRef?.destroy();
    this.viewContainerRef.clear();
  }

  /**
   * Если у textarea родитель "static", делаем его "relative",
   * чтобы компонент-кнопка мог позиционироваться "absolute" внутри.
   */
  private makeParentRelative(): void {
    const parentEl = this.textareaEl.parentNode as HTMLElement;
    if (!parentEl) {
      return;
    }
    const parentStyle = window.getComputedStyle(parentEl).position;
    if (parentStyle === 'static' || !parentStyle) {
      this.renderer.setStyle(parentEl, 'position', 'relative');
    }
  }

  private createRecorderButton(): void {
    this.viewContainerRef.clear();
    const compRef = this.viewContainerRef.createComponent(RecorderButtonComponent);
    this.recorderButtonRef = compRef;
    // Если у родителя есть padding, то у кнопки нужно сделать left: auto
    if (this.textareaEl.parentNode) {
      const parentPadding = window.getComputedStyle(this.textareaEl.parentNode as HTMLElement).paddingLeft;
      if (parentPadding && parentPadding !== '0px' && parentPadding !== '0') {
        this.renderer.setStyle(compRef.location.nativeElement, 'left', 'auto');
      }
    }
    // А если у textarea есть margin снизу, то его надо добавить к bottom кнопки
    setTimeout(() => {
      const computedStyle = window.getComputedStyle(this.textareaEl);
      const textareaMarginBottom = parseFloat(computedStyle.marginBottom);
      if (textareaMarginBottom || this.recorderButtonMarginBottom()) {
        this.renderer.setStyle(
          compRef.location.nativeElement,
          'bottom',
          textareaMarginBottom + this.recorderButtonMarginBottom() + 'px',
        );
      }
    });

    compRef.instance.isRecording = this.isRecording;
    compRef.instance.isProcessing = this.isProcessing;

    // Колбэк на клик по кнопке
    compRef.instance.onButtonClick = () => this.onRecorderButtonClick();
  }

  /**
   * Нажали на кнопку записи (в RecorderButtonComponent):
   * если ещё не записываем — начать,
   * если уже записываем — остановить + транскрибация
   */
  private onRecorderButtonClick(): void {
    if (!this.isRecording()) {
      void this.startRecording();
    } else {
      this.stopRecordingAndTranscribe();
    }
  }

  private async startRecording(): Promise<void> {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      this.mediaRecorder = new MediaRecorder(stream);
      this.recordedChunks = [];

      this.mediaRecorder.ondataavailable = (e: BlobEvent) => {
        if (e.data && e.data.size > 0) {
          this.recordedChunks.push(e.data);
        }
      };

      this.mediaRecorder.onstop = () => {
        stream.getTracks().forEach(track => track.stop());
      };

      this.textareaEl.placeholder = 'Говорите...';

      this.mediaRecorder.start();
      this.isRecording.set(true);
      this.isProcessing.set(false);
      this.recordStart.emit();
    } catch (err) {
      this.notifyService.error('Ошибка при доступе к микрофону');
    }
  }

  private stopRecordingAndTranscribe(): void {
    if (!this.mediaRecorder) {
      return;
    }

    this.mediaRecorder.stop();
    this.isRecording.set(false);
    this.isProcessing.set(true);
    this.recordEnd.emit();
    this.textareaEl.placeholder = this.initialPlaceholder() || this.basePlaceholder;

    // Небольшая задержка, чтобы onstop сработал
    setTimeout(() => {
      const blob = new Blob(this.recordedChunks, { type: 'audio/wav' });
      this.recordedChunks = [];
      this.transcribeAudio(blob);
    });
  }

  private transcribeAudio(blob: Blob): void {
    const file = new File([blob], 'recorded-audio.wav', { type: blob.type });
    const request = {
      file,
      fromPlace: this.screenType(), // используем переданный screenType
    };

    this.audioTranscriptService.transcribeFile(request).subscribe({
      next: response => {
        const recognized = response.message;
        this.insertRecognizedText(recognized);
        this.isProcessing.set(false);
        this.amplitudeTrackService.trackEvent(AUDIO_RECORDING_TO_TEXT_CONVERT_CLICK, {
          screenType: this.screenType(),
        });
      },
      error: () => {
        this.isProcessing.set(false);
        this.notifyService.error('Ошибка при расшифровке аудио');
      },
    });
  }

  private insertRecognizedText(text: string): void {
    const currentValue = this.textareaEl.value;
    this.textareaEl.value = currentValue ? currentValue + ' ' + text : text;

    // Генерируем событие input для Angular Forms
    const event = new Event('input', { bubbles: true });
    this.textareaEl.dispatchEvent(event);
  }
}
