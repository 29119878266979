import { ChatContactsListItem, CrmCardViewItem } from '@api-clients/crm-api-client';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  ChatListExclusiveFilterKey,
  ChatListInclusiveFilterKeys,
  ChatListStageFilterKeys,
  ChatListTouchTodayFilterKeys,
  ChatWithDealListItem,
  ChatsWithDealsResult,
  InclusiveFiltersCounters,
  ChatsMenuCounter,
} from '../../modules/chats/chats';
import { dealsQuery } from '../deals/deals.selectors';
import { ChatsState, FEATURE_KEY } from './chats.reducer';
import { DealConversationStatusEnum } from '../../modules/deals/modules/deals-list/deal-list';

// Feature Selector
export const selectChatState = createFeatureSelector<ChatsState>(FEATURE_KEY);

// Selectors
export const selectAllChatContacts = createSelector(
  selectChatState,
  (state: ChatsState) => state.chatContacts,
);

export const selectAllChatContactsWithDeals = createSelector(
  selectChatState,
  dealsQuery.getAllDeals,
  (state: ChatsState, deals: CrmCardViewItem[]): ChatsWithDealsResult => {
    const dealMap = new Map(deals.map(deal => [deal.card.id, deal]));

    const chatsWithDeals = state.chatContacts.map(chat => {
      // Находим CrmCardViewItem по `card.id` или `deal.id`
      const deal =
        deals.find(
          d => d.card.id === chat.contact.crmCardId || d.deal?.id === chat?.crmDetails?.dealInfo?.id,
        ) || null;

      if (deal) {
        dealMap.delete(deal.card.id); // Удаляем записи, сопоставленные c каким-либо чатом
      }

      return {
        chat,
        deal,
      } as ChatWithDealListItem;
    });

    const unmatchedDeals = Array.from(dealMap.values()); // Оставшиеся не сопоставленные карточки

    return {
      chats: chatsWithDeals,
      deals: unmatchedDeals, // Только CrmCardViewItem без чатов
      // переписка может быть и с чужими клиентами,
      // чтобы иметь возможность разделить чаты "мои клиенты/остальные"
      currentEmployeeId: state.currentEmployeeId,
    };
  },
);

export const selectCurrentSort = createSelector(selectChatState, (state: ChatsState) => state.sort);

export const selectChatFilters = createSelector(selectChatState, (state: ChatsState) => state.filters);

export const selectCurrentStageFilter = createSelector(selectChatFilters, filters => filters.stage);

export const selectCurrentExclusiveFilter = createSelector(selectChatFilters, filters => filters.exclusive);

export const selectLastReadChatContactId = createSelector(
  selectChatState,
  state => state.lastReadChatContactId,
);

export const selectCurrentInclusiveFilters = createSelector(selectChatFilters, filters => filters.inclusive);

export const selectHasAnyInclusiveFiltersEnabled = createSelector(
  selectChatFilters,
  filters => !!filters.inclusive.length,
);

export const selectCurrentTempInclusiveFiltersForCounters = createSelector(
  selectChatFilters,
  filters => filters.tempInclusiveForCounters,
);

export const selectCurrentTouchTodayFilter = createSelector(
  selectChatState,
  (state: ChatsState) => state.filters.touchToday,
);

export const selectIsTouchTodayFilterEnabled = createSelector(
  selectChatState,
  (state: ChatsState) => !!state.filters.touchToday,
);

export const selectIsLeftToTouchTodayFilterEnabled = createSelector(
  selectChatState,
  (state: ChatsState) => state.filters.touchToday === ChatListTouchTodayFilterKeys.LEFT_TO_TOUCH,
);

export const selectIsTouchedTodayFilterEnabled = createSelector(
  selectChatState,
  (state: ChatsState) => state.filters.touchToday === ChatListTouchTodayFilterKeys.TOUCHED_TODAY,
);

export const selectStageFilteredChatsWithDeals = createSelector(
  selectAllChatContactsWithDeals,
  selectCurrentStageFilter,
  (
    { chats, deals, currentEmployeeId }: ChatsWithDealsResult,
    currentStageFilter: ChatListStageFilterKeys,
  ): ChatsWithDealsResult => {
    // console.log(
    //   'selectAllChatContactsWithDeals->selectStageFilteredChatsWithDeals',
    //   { c: chats.length, d: deals.length },
    // );

    const filteredChats = chats.filter(({ chat }) => {
      if (chat.recommendation && currentStageFilter !== ChatListStageFilterKeys.IN_PROGRESS) {
        return false;
      }
      return filterByStage(
        chat.crmDetails?.dealInfo,
        currentStageFilter,
        chat.contact.unreadMessageCount > 0,
        currentEmployeeId === chat.crmDetails?.dealInfo?.employeeId,
      );
    });

    const filteredDeals = deals.filter(deal =>
      filterByStage(
        deal,
        currentStageFilter,
        deal.deal?.conversationStatus === (DealConversationStatusEnum.NewMessage as number),
        currentEmployeeId === deal.deal?.worker?.employeeId,
      ),
    );

    return {
      chats: filteredChats,
      deals: filteredDeals,
      currentEmployeeId,
    };
  },
);

export const selectStageFilteredChatsWithDealsCountBy = (filter: ChatListStageFilterKeys, from?: string) =>
  createSelector(
    selectAllChatContactsWithDeals,
    ({ chats, deals, currentEmployeeId }: ChatsWithDealsResult): ChatsMenuCounter => {
      const counter: ChatsMenuCounter = {
        total: 0,
        unread: 0,
        hasUnreadDealChanges: false,
      };
      chats.reduce((acc, { chat }) => {
        if (
          filterByStage(
            chat.crmDetails?.dealInfo,
            filter,
            chat.contact.unreadMessageCount > 0,
            currentEmployeeId === chat.crmDetails?.dealInfo?.employeeId,
          )
        ) {
          acc.total++;
          if (chat.contact.unreadMessageCount > 0) {
            acc.unread++;
          }
          // Если потребуется - принесите и в другие места stages, пока нужен только для сделок без чатов

          if (filter === ChatListStageFilterKeys.NEW && chat.crmDetails?.dealInfo?.hasUnreadChanges) {
            acc.hasUnreadDealChanges = true;
          }
        }
        return acc;
      }, counter);

      deals.reduce((acc, dealItem) => {
        if (
          filterByStage(
            dealItem,
            filter,
            dealItem.deal?.conversationStatus === (DealConversationStatusEnum.NewMessage as number),
            currentEmployeeId === dealItem.deal?.worker?.employeeId,
          )
        ) {
          acc.total++;
          if (dealItem.deal?.conversationStatus === (DealConversationStatusEnum.NewMessage as number)) {
            acc.unread++;
          }
          // Если потребуется - принесите и в другие места stages, пока нужен только для сделок без чатов
          if (filter === ChatListStageFilterKeys.NEW && dealItem.deal?.isNew) {
            acc.hasUnreadDealChanges = true;
          }
        }
        return acc;
      }, counter);

      // if (filter === ChatListStageFilterKeys.NEW || filter === ChatListStageFilterKeys.MISSED_AND_URGENT) {
      //   if (from === 'list-stages') {
      //     console.warn('selectStageFilteredChatsWithDealsCountBy', { stage: filter, counter, from });
      //   } else {
      //     console.log('selectStageFilteredChatsWithDealsCountBy', { stage: filter, counter, from });
      //   }
      // }

      return counter;
    },
  );

export const selectTouchTodayFilteredChatsWithDeals = createSelector(
  selectStageFilteredChatsWithDeals,
  selectIsTouchTodayFilterEnabled,
  selectCurrentTouchTodayFilter,
  (
    chatsWithDeals: ChatsWithDealsResult,
    isTouchTodayFilterEnabled: boolean,
    currentTouchTodayFilter: ChatListTouchTodayFilterKeys,
  ): ChatsWithDealsResult => {
    // console.log(
    //   'selectStageFilteredChatsWithDeals->selectTouchTodayFilteredChatsWithDeals',
    //   { c: chatsWithDeals.chats.length, d: chatsWithDeals.deals.length },
    // );
    return isTouchTodayFilterEnabled
      ? filterChatsWithDealsByTouchToday(chatsWithDeals, currentTouchTodayFilter)
      : chatsWithDeals;
  },
);

export const selectTouchTodayFilteredChatsWithDealsByKey = (key: ChatListTouchTodayFilterKeys) =>
  createSelector(
    selectStageFilteredChatsWithDeals,
    (chatsWithDeals: ChatsWithDealsResult): ChatsWithDealsResult =>
      filterChatsWithDealsByTouchToday(chatsWithDeals, key),
  );

export const selectTouchTodayFilteredChatContactsCountBy = (key: ChatListTouchTodayFilterKeys) =>
  createSelector(
    selectTouchTodayFilteredChatsWithDealsByKey(key),
    (touchTodayFilteredChatsWithDeals: ChatsWithDealsResult) =>
      touchTodayFilteredChatsWithDeals.chats.length + touchTodayFilteredChatsWithDeals.deals.length,
  );

export const selectTouchTodayAllCount = createSelector(
  selectTouchTodayFilteredChatContactsCountBy(ChatListTouchTodayFilterKeys.LEFT_TO_TOUCH),
  selectTouchTodayFilteredChatContactsCountBy(ChatListTouchTodayFilterKeys.TOUCHED_TODAY),
  (leftToTouch: number, touched: number) => leftToTouch + touched,
);

export const selectInclusiveFilteredChatsWithDeals = createSelector(
  selectTouchTodayFilteredChatsWithDeals,
  selectCurrentInclusiveFilters,
  (
    chatsWithDeals: ChatsWithDealsResult,
    inclusiveFilters: ChatListInclusiveFilterKeys[],
  ): ChatsWithDealsResult => {
    // console.log(
    //   'selectTouchTodayFilteredChatsWithDeals->selectInclusiveFilteredChatsWithDeals',
    //   { c: chatsWithDeals.chats.length, d: chatsWithDeals.deals.length },
    // );
    return {
      chats: chatsWithDeals.chats.filter(chat => {
        return inclusiveFilters.every(filterKey => {
          return chat.deal ? filterByInclusiveFilterKey(chat.deal, filterKey) : false;
        });
      }),
      deals: chatsWithDeals.deals.filter(deal => {
        return inclusiveFilters.every(filterKey => {
          return deal ? filterByInclusiveFilterKey(deal, filterKey) : false;
        });
      }),
    };
  },
);

/**
 * @see ChatListInclusiveFilterKeys - доп. фильры (в модалке)
 * @see ChatListExclusiveFilterKey - фильры-табы
 */
export const selectExclusiveFilteredChatsWithDeals = createSelector(
  selectInclusiveFilteredChatsWithDeals,
  selectCurrentExclusiveFilter,
  selectLastReadChatContactId,
  (
    chatsWithDeals: ChatsWithDealsResult,
    currentExclusiveFilter: ChatListExclusiveFilterKey,
    lastReadChatContactId: string,
  ): ChatsWithDealsResult => {
    // console.log(
    //   'selectInclusiveFilteredChatsWithDeals->selectExclusiveFilteredChatsWithDeals',
    //   { c: chatsWithDeals.chats.length, d: chatsWithDeals.deals.length },
    // );
    return {
      chats: chatsWithDeals.chats.filter(chat => {
        return filterChatsByExclusiveFilterKey(chat, currentExclusiveFilter, { lastReadChatContactId });
      }),
      deals: chatsWithDeals.deals.filter(deal => {
        return filterDealsByExclusiveFilterKey(deal, currentExclusiveFilter);
      }),
    };
  },
);

export const selectExclusiveFilteredChatsWithDealsCountBy = (filter: ChatListExclusiveFilterKey) =>
  createSelector(selectInclusiveFilteredChatsWithDeals, (chatsWithDeals: ChatsWithDealsResult) => {
    const filteredChats = chatsWithDeals.chats.filter(chat => {
      return filterChatsByExclusiveFilterKey(chat, filter);
    });

    const filteredDeals = chatsWithDeals.deals.filter(deal => {
      return filterDealsByExclusiveFilterKey(deal, filter);
    });

    return filteredChats.length + filteredDeals.length;
  });

export const selectInclusiveFilterCounters = createSelector(
  selectTouchTodayFilteredChatsWithDeals,
  selectCurrentTempInclusiveFiltersForCounters,
  (chatsWithDeals: ChatsWithDealsResult, tempInclusiveFilters: ChatListInclusiveFilterKeys[]) => {
    // Функция для подсчета счетчиков на основе фильтров
    const countFilters = (items: (ChatWithDealListItem | CrmCardViewItem)[]): InclusiveFiltersCounters => {
      const counters: InclusiveFiltersCounters = {
        [ChatListInclusiveFilterKeys.IS_FORGOTTEN]: 0,
        [ChatListInclusiveFilterKeys.IS_NEARBY_DATES]: 0,
        [ChatListInclusiveFilterKeys.IS_RETURN_TOURIST]: 0,
        [ChatListInclusiveFilterKeys.IS_MOBILE_APP]: 0,
        [ChatListInclusiveFilterKeys.IS_HIGH_CHECK]: 0,
      };

      items.forEach(item => {
        // Определяем, является ли элемент ChatWithDealListItem или CrmCardViewItem сразу
        const deal = 'chat' in item ? item.deal : item;

        if (deal) {
          if (deal.isForgotten) {
            counters[ChatListInclusiveFilterKeys.IS_FORGOTTEN]++;
          }
          if (deal.lastSearchRequest?.isNearbyDates) {
            counters[ChatListInclusiveFilterKeys.IS_NEARBY_DATES]++;
          }
          if (deal.deal?.isReturnTourist) {
            counters[ChatListInclusiveFilterKeys.IS_RETURN_TOURIST]++;
          }
          if (deal.card?.hasMobileApplication) {
            counters[ChatListInclusiveFilterKeys.IS_MOBILE_APP]++;
          }
          if (deal.experimentalData?.isHighCheck) {
            counters[ChatListInclusiveFilterKeys.IS_HIGH_CHECK]++;
          }
        }
      });

      return counters;
    };
    // Шаг 1: фильтруем чаты и сделки по выбранным, но не примененным фильтрам
    const filteredChats =
      tempInclusiveFilters.length > 0
        ? chatsWithDeals.chats.filter(chat => {
            return tempInclusiveFilters.every(
              filterKey => chat.deal && filterByInclusiveFilterKey(chat.deal, filterKey),
            );
          })
        : chatsWithDeals.chats;

    const filteredDeals =
      tempInclusiveFilters.length > 0
        ? chatsWithDeals.deals.filter(deal => {
            return tempInclusiveFilters.every(
              filterKey => deal && filterByInclusiveFilterKey(deal, filterKey),
            );
          })
        : chatsWithDeals.deals;

    // Шаг 2: теперь подсчитываем количество чатов и сделок для каждого фильтра
    const chatCounters = countFilters(filteredChats);
    const dealCounters = countFilters(filteredDeals);

    // Шаг 3: объединяем счетчики
    return Object.keys(chatCounters).reduce((acc, key) => {
      acc[key] = chatCounters[key] + dealCounters[key];
      return acc;
    }, {} as InclusiveFiltersCounters);
  },
);

export const selectHasForgotten = createSelector(
  selectInclusiveFilteredChatsWithDeals,
  (chatsWithDeals: ChatsWithDealsResult) => {
    const { chats, deals } = chatsWithDeals;

    const hasForgottenChat = chats.some(chat => !chat.chat.contact.isGroup && chat.deal?.isForgotten);

    const hasForgottenDeal = deals.some(deal => deal.isForgotten);

    return hasForgottenChat || hasForgottenDeal;
  },
);

export const selectHasGroups = createSelector(
  selectStageFilteredChatsWithDeals,
  (chatsWithDeals: ChatsWithDealsResult) =>
    chatsWithDeals.chats.some((chat: ChatWithDealListItem) => {
      return chat.chat.contact.isGroup;
    }),
);

export const selectHasUnreadGroups = createSelector(
  selectStageFilteredChatsWithDeals,
  (chatsWithDeals: ChatsWithDealsResult) =>
    chatsWithDeals.chats.some((chat: ChatWithDealListItem) => {
      return chat.chat.contact.isGroup && chat.chat.contact.unreadMessageCount > 0;
    }),
);

export const selectHasUnreadChats = createSelector(
  selectInclusiveFilteredChatsWithDeals,
  (chatsWithDeals: ChatsWithDealsResult) =>
    chatsWithDeals.chats.some(
      (chat: ChatWithDealListItem) => !chat.chat.contact.isGroup && chat.chat.contact.unreadMessageCount > 0,
    ),
);

export const selectCurrentChatContact = createSelector(
  selectChatState,
  (state: ChatsState) => state.currentChatContact,
);

export const selectCurrentChatContactInvisibleRead = createSelector(
  selectChatState,
  (state: ChatsState) => state.currentChatContactInvisibleRead,
);

export const selectChatRecommendation = createSelector(
  selectChatState,
  (state: ChatsState) => state.chatRecommendation,
);

export const selectCurrentChatContactCrmCard = createSelector(
  selectChatState,
  (state: ChatsState) => state.currentChatContactCrmCard,
);

export const selectCurrentChatContactCrmCardLoading = createSelector(
  selectChatState,
  (state: ChatsState) => state.currentChatContactCrmCardLoading,
);

export const selectCurrentChatContactNextTaskLoading = createSelector(
  selectChatState,
  (state: ChatsState) => state.currentChatContactNextTaskLoading,
);

export const selectReadAllChatsLoading = createSelector(
  selectChatState,
  (state: ChatsState) => state.readAllChatsLoading,
);

export const selectChatContactsLoading = createSelector(
  selectChatState,
  (state: ChatsState) => state.loading,
);
export const selectChatContactsLoadingMore = createSelector(
  selectChatState,
  (state: ChatsState) => state.loadingMore,
);

export const selectChatContactsError = createSelector(selectChatState, (state: ChatsState) => state.error);

export const selectSearchResults = createSelector(
  selectChatState,
  (state: ChatsState) => state.searchResults,
);

export const selectIsSearching = createSelector(selectChatState, (state: ChatsState) => state.isSearching);

function filterByStage<T extends { categories?: string[]; status?: number }>(
  item: T,
  currentStageFilter: ChatListStageFilterKeys,
  isUnread: boolean,
  isCurrentEmployeeResponsible: boolean,
): boolean {
  const categories = item?.categories || [];

  if (currentStageFilter === ChatListStageFilterKeys.ALL) {
    return true;
  }

  // Остальные разделы - по сделкам.
  // Оставляем только своих
  if (!isCurrentEmployeeResponsible) {
    return false;
  }

  switch (currentStageFilter) {
    case ChatListStageFilterKeys.IN_PROGRESS:
    case ChatListStageFilterKeys.FIRST_DAY:
    case ChatListStageFilterKeys.SECOND_DAY:
    case ChatListStageFilterKeys.THIRD_DAY:
    case ChatListStageFilterKeys.FOURTH_TO_SEVENTH_DAYS:
    case ChatListStageFilterKeys.MORE_THAN_SEVEN_DAYS:
      return (
        isCurrentEmployeeResponsible && categories.includes(currentStageFilter as string)
        // && filterInProgressWithExclusions(categories)
      );
    case ChatListStageFilterKeys.POST_SALE:
      return isCurrentEmployeeResponsible && item?.status === 2;
    default:
      return categories.includes(currentStageFilter);
  }
}

// function filterInProgressWithExclusions(categories: string[]): boolean {
//   const excludedFilters = [
//     ChatListStageFilterKeys.MEETING,
//     ChatListStageFilterKeys.READY_FOR_BOOKING,
//     ChatListStageFilterKeys.POSTPONED,
//     ChatListStageFilterKeys.COLD_TOUCH,
//     ChatListStageFilterKeys.AUTO_TOUCH,
//   ];
//   return (
//     categories.includes(ChatListStageFilterKeys.IN_PROGRESS as string) &&
//     excludedFilters.every(excluded => !categories.includes(excluded as string))
//   );
// }

function filterByInclusiveFilterKey(deal: CrmCardViewItem, filterKey: ChatListInclusiveFilterKeys) {
  switch (filterKey) {
    case ChatListInclusiveFilterKeys.IS_FORGOTTEN:
      return deal?.isForgotten;
    case ChatListInclusiveFilterKeys.IS_NEARBY_DATES:
      return deal?.lastSearchRequest?.isNearbyDates;
    case ChatListInclusiveFilterKeys.IS_RETURN_TOURIST:
      return deal?.deal?.isReturnTourist;
    case ChatListInclusiveFilterKeys.IS_MOBILE_APP:
      return deal?.card?.hasMobileApplication;
    case ChatListInclusiveFilterKeys.IS_HIGH_CHECK:
      return deal?.experimentalData?.isHighCheck;
    default:
      return true;
  }
}

function filterChatsByExclusiveFilterKey(
  chat: ChatWithDealListItem,
  key: ChatListExclusiveFilterKey,
  options?: { lastReadChatContactId: string },
): boolean {
  switch (key) {
    case 'all':
      return !chat.chat.contact.isGroup;
    case 'forgotten':
      return !chat.chat.contact.isGroup && chat.deal?.isForgotten;
    case 'unread':
      return (
        !chat.chat.contact.isGroup &&
        (chat.chat.contact.unreadMessageCount > 0 || options?.lastReadChatContactId === chat.chat.contact.id)
      );
    case 'groups':
      return chat.chat.contact.isGroup;
    default:
      return false;
  }
}

function filterDealsByExclusiveFilterKey(deal: CrmCardViewItem, key: ChatListExclusiveFilterKey): boolean {
  switch (key) {
    case 'all':
      return true;
    case 'forgotten':
      return deal?.isForgotten;
    default:
      return false;
  }
}

/**
 * TODO: поменять к ебаной матери!!!
 * @param chatsWithDeals
 * @param key
 */
function filterChatsWithDealsByTouchToday(
  chatsWithDeals: ChatsWithDealsResult,
  key: ChatListTouchTodayFilterKeys,
): ChatsWithDealsResult {
  const filterByTouchToday = (deal: CrmCardViewItem, chat?: ChatContactsListItem) => {
    let isDealRequired = true;
    const hasUnreadMessages = chat?.contact.unreadMessageCount > 0;
    if (hasUnreadMessages) {
      // Допущение: только мои чаты! Фильтрация должна быть выше
      // Данный фильтр применяется в разделах, где присутсуют только мои чаты
      //
      // В разделе, где есть данные фильтр:
      // - только мои чаты
      // - срочные/пропущенные/в работе
      // - + непрочитанные со всех разделов
      // Причина одна: у чатов из post sale нет сделки в ChatWithDealListItem
      isDealRequired = false;
    }

    if (!deal && isDealRequired) {
      console.warn('filterByTouchToday: deal is not defined');
      return false;
    }

    if (key === ChatListTouchTodayFilterKeys.TOUCHED_TODAY) {
      return deal?.experimentalData?.isContactedToday;
    }

    return !deal?.experimentalData?.isContactedToday || hasUnreadMessages;
  };

  return {
    chats: chatsWithDeals.chats.filter(chat => filterByTouchToday(chat.deal, chat.chat)),
    deals: chatsWithDeals.deals.filter(deal => filterByTouchToday(deal, null)),
  };
}
