<div class="wrap">
  <a
    class="avatar-bar"
    (click)="dealViewOpened()"
    [routerLink]="['/deals/view', crmId]"
    [queryParams]="{ phone: phone.phone, code: phone.code }"
    [ngClass]="{ active: isSelectedChat }"
  >
    <div class="avatar-wrap" (mouseenter)="showOverlay()">
      <img [src]="avatarSrc" class="avatar" alt="avatar" />

      @if (hasUnreadMessages) {
        <div class="counter">1</div>
      }
    </div>
  </a>
</div>
