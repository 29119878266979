import { NgClass } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  DestroyRef,
  Input,
  OnDestroy,
  Output,
  signal,
  Type,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TourContent } from '@api-clients/api-client/models/tour-content';
import { WhatsappMessage } from '@api-clients/crm-api-client';
import { TranslateModule } from '@ngx-translate/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { first, map, switchMap, takeUntil } from 'rxjs/operators';
import { SlideToggleComponent } from '../../../../ui-components/slide-toggle/components/slide-toggle.component';
import { ChatTimelineService } from '../../../chat-timeline/services/chat-timeline.service';
import {
  ContentCreatorContentType,
  ContentCreatorItemComponent,
} from '../../interfaces/content-creator.interface';
import { ContentCreatorModalControlService } from '../../services/content-creator-modal-control.service';
import { AudioPresentationComponent } from '../items/audio-presentation/audio-presentation.component';
import { ContentCreatorCustomMessageComponent } from '../items/custom-message/content-creator-custom-message.component';
import { GptComponent } from '../items/gpt/gpt.component';
import { ManagerCommentsComponent } from '../items/manager-comments/manager-comments.component';
import { ContentCreatorManagerOfferComponent } from '../items/manager-offer/content-creator-manager-offer.component';
import { PhotosComponent } from '../items/photos/photos.component';
import { PricesCalendarComponent } from '../items/prices-calendar/prices-calendar.component';
import { ReviewsComponent } from '../items/reviews/reviews.component';
import { VideosComponent } from '../items/videos/videos.component';

@Component({
  selector: 'app-content-creator-item-wrapper',
  imports: [TranslateModule, SlideToggleComponent, NgClass],
  templateUrl: './content-creator-item-wrapper.component.html',
  styleUrls: ['./content-creator-item-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentCreatorItemWrapperComponent implements AfterViewInit, OnDestroy {
  @Input() public itemType: ContentCreatorContentType;
  @Input() public tourId: string;
  @Input() public isMultipleMode = false;
  @Input() public isPlaceholder = false;
  @Input() public isEnabled = true;
  @Input() public isContentCreatorInitialized$: Observable<boolean>;
  @Output() public itemComponent: ContentCreatorItemComponent;
  @ViewChild('mainWrapper', { read: ViewContainerRef })
  mainWrapper: ViewContainerRef;
  @ViewChild('itemComponentWrapper', { read: ViewContainerRef })
  itemComponentWrapper: ViewContainerRef;

  public isItemComponentWrapperVisible = signal(true);
  public titleLabel = '';
  public recordsCount = 0;

  private viewInitialized$ = new ReplaySubject<void>(1);
  private destroy$ = new Subject<void>();

  constructor(
    private chatTimelineService: ChatTimelineService,
    private cdr: ChangeDetectorRef,
    private destroyRef: DestroyRef,
    private contentCreatorModalControlService: ContentCreatorModalControlService,
  ) {}

  ngAfterViewInit(): void {
    this.viewInitialized$.next();
    this.viewInitialized$.complete();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public createItemComponent(
    tourContent: TourContent,
    initialWhatsappMessage: WhatsappMessage | null = null,
  ): Observable<ComponentRef<ContentCreatorItemComponent> | null> {
    return this.viewInitialized$.asObservable().pipe(
      takeUntil(this.destroy$),
      map(() => {
        let component: Type<ContentCreatorItemComponent>;
        if (this.isPlaceholder) {
          // Плейсхолдер - это просто текстовое поле в которое
          // будет вставлен результат работы полноценного компонента
          component = ContentCreatorCustomMessageComponent;
          // А текст по умолчанию должен быть пустым
          initialWhatsappMessage = this.chatTimelineService.createChatTimelineItemByText('').data;
          switch (this.itemType) {
            case ContentCreatorContentType.Reviews:
              this.recordsCount = tourContent.tourMessageData.hotel.reviewsWithTextCount;
              break;
            case ContentCreatorContentType.ManagerComments:
              this.recordsCount = tourContent.tourMessageData.hotel.managerCommentsCount;
              break;
          }
          // Покажем текст, когда отработает полноценный компонент
          this.isItemComponentWrapperVisible.set(false);
        } else {
          switch (this.itemType) {
            case ContentCreatorContentType.PricesCalendar:
              component = PricesCalendarComponent;
              break;
            case ContentCreatorContentType.Photos:
              component = PhotosComponent;
              break;
            case ContentCreatorContentType.Videos:
              component = VideosComponent;
              break;
            case ContentCreatorContentType.AudioPresentation:
              component = AudioPresentationComponent;
              break;
            case ContentCreatorContentType.Gpt:
              component = GptComponent;
              break;
            case ContentCreatorContentType.ManagerOffer:
              component = ContentCreatorManagerOfferComponent;
              break;
            case ContentCreatorContentType.CustomMessage:
              component = ContentCreatorCustomMessageComponent;
              break;
            case ContentCreatorContentType.ManagerComments:
              component = ManagerCommentsComponent;
              break;
            case ContentCreatorContentType.Reviews:
              component = ReviewsComponent;
              break;
          }
        }
        if (!component) {
          return null;
        }
        this.titleLabel = `PAGES.CONTENT_CREATOR.CONTENT_TYPE.${this.itemType
          .replace(/([A-Z])/g, $1 => `_${$1.toLowerCase()}`)
          .toUpperCase()}`;

        const itemComponentRef = this.itemComponentWrapper.createComponent(component);
        itemComponentRef.instance.tourId = this.tourId;
        itemComponentRef.instance.tourContent = tourContent;
        itemComponentRef.instance.isContentCreatorInitialized$ = this.isContentCreatorInitialized$;
        if (
          (itemComponentRef.instance instanceof GptComponent ||
            itemComponentRef.instance instanceof ContentCreatorCustomMessageComponent) &&
          initialWhatsappMessage
        ) {
          itemComponentRef.instance.initialMessage = initialWhatsappMessage;
        }
        this.itemComponent = itemComponentRef.instance;
        this.cdr.detectChanges();
        return itemComponentRef;
      }),
    );
  }

  public mainWrapperClick(): void {
    // Пока в плейсхолдере нет контента, клик на вес плейсхолдер срабатывает для открытия модалки
    if (this.isPlaceholder && this.isEnabled && !this.isItemComponentWrapperVisible()) {
      this.showPlaceholderModal();
    }
  }

  /**
   * Открывает новый создатель контента с одним типом, за который отвечает плейсхолдер.
   * Чтобы потом вернуть в плейсходлер результат своей работы
   */
  public showPlaceholderModal(): void {
    this.contentCreatorModalControlService.showModal(
      this.chatTimelineService.createChatTimelineItemByText(`номер тура: ${this.tourId}`),
      [this.itemType],
      null,
      true,
    );

    this.contentCreatorModalControlService.lastOpenedModal$
      .pipe(
        switchMap(modal => modal.afterClosed()),
        first(),
        switchMap(messages$ => messages$),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(messages => {
        // Объединяем текст всех сообщений и вставляем в текстовое поле плейсхолдера
        if (this.itemComponent instanceof ContentCreatorCustomMessageComponent) {
          let messageText = messages.map(message => message.text).join('\n');
          if (messageText) {
            if (this.itemType === ContentCreatorContentType.ManagerComments) {
              messageText = '*Наши комментарии:*\n' + messageText;
            }
            if (this.itemType === ContentCreatorContentType.Reviews) {
              messageText = '*Отзывы наших туристов:*\n' + messageText;
            }
          }

          this.itemComponent.setMessage(messageText);
          this.isItemComponentWrapperVisible.set(!!messageText);
          // Надо прокрутить родительский элемент со скроллом до плейсхолдера
          setTimeout(() => {
            this.mainWrapper.element.nativeElement.scrollIntoView({ behavior: 'smooth' });
          }, 500);
        }
      });
  }

  public changeIsEnabled(isEnabled: boolean): void {
    this.isEnabled = isEnabled;
  }
}
