import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { InputSwitchModule } from 'primeng/inputswitch';

@Component({
  selector: 'app-slide-toggle',
  templateUrl: './slide-toggle.component.html',
  styleUrls: ['./slide-toggle.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,

  imports: [InputSwitchModule, NgClass, FormsModule],
})
export class SlideToggleComponent implements OnInit, OnChanges {
  public text: string;
  public checked: boolean;
  @Input() defaultValue: boolean;
  @Input() readonly = false;
  @Input() labelText: string;
  // Это вообще надо выпилить. Для UX это только путает,
  // когда лейбл меняется в зависимости от состояния чекбокса
  @Input() enabledStateText: string;
  @Input() disabledStateText: string;
  @Input() size: 'small' | 'big' = 'small';
  @Output() change: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private cdRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.checked = this.defaultValue;
    this.text = this.labelText;
    this.changeText(this.checked);
    this.cdRef.detectChanges();
  }

  changeText(state: boolean): void {
    if (!this.labelText) {
      this.text = state ? this.enabledStateText : this.disabledStateText;
    }
    this.cdRef.detectChanges();
  }

  toggle(): void {
    if (this.readonly) {
      return;
    }
    this.checked = !this.checked;
    this.change.emit(this.checked);
    this.changeText(this.checked);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.defaultValue) {
      this.checked = changes.defaultValue.currentValue;
      this.changeText(this.checked);
      this.cdRef.detectChanges();
    }
  }
}
