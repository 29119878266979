import { inject, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { WorkerStateService } from './core/services';
import { AuthGuard } from './helpers/auth.guard';
import { FeatureToggleService } from './modules/deals/modules/deals-list/modules/deals-list-content/helpers/feature-toggle.service';
import { MainComponent } from './modules/layout/components/main/main.component';
import { LoginComponent } from './modules/login/components/login/login.component';
import { LogoutComponent } from './modules/login/components/logout/logout.component';

const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: () => {
          const featureToggleService = inject(FeatureToggleService);
          const workerState = inject(WorkerStateService);
          const worker = workerState.currentWorkerValue;

          if (worker && featureToggleService.shouldDisplayChats(worker)) {
            return 'chats';
          }
          return 'deals';
        },
      },
      {
        path: 'deals',
        loadComponent: () =>
          import('./modules/deals/modules/deals-list/deals-list.component').then(m => m.DealsListComponent),
      },
      {
        path: 'deals/add',
        loadComponent: () =>
          import('./modules/deals/modules/add-deal/add-deal.component').then(m => m.AddDealComponent),
      },
      {
        path: 'call-list',
        loadComponent: () =>
          import('./modules/call-list/components/call-list/call-list.component').then(
            m => m.CallListComponent,
          ),
      },
      {
        path: 'deals/view/:id',
        loadComponent: () =>
          import('./modules/deals/modules/deal-view/deal-view.component').then(m => m.DealViewComponent),
      },
      {
        path: 'deals/view/:id/:messageTourId',
        loadComponent: () =>
          import('./modules/deals/modules/deal-view/deal-view.component').then(m => m.DealViewComponent),
      },
      {
        path: 'deals/view',
        loadComponent: () =>
          import('./modules/deals/modules/deal-view/deal-view.component').then(m => m.DealViewComponent),
      },
      {
        path: 'deals/preload',
        loadComponent: () =>
          import(
            './modules/deals/modules/deal-view/components/deal-view-skeleton/deal-view-skeleton.component'
          ).then(m => m.DealViewSkeletonComponent),
      },
      {
        path: 'complaint',
        loadComponent: () =>
          import('./modules/complaint/components/complaint-list/complaint-list.component').then(
            m => m.ComplaintListComponent,
          ),
      },
      {
        path: 'task-list',
        loadComponent: () => import('./modules/task-list/task-list.component').then(m => m.TaskListComponent),
      },
      {
        path: 'search-results',
        loadComponent: () =>
          import('./modules/layout/components/search-form/search-results/search-results.component').then(
            m => m.SearchResultsComponent,
          ),
      },
      {
        path: 'update',
        loadComponent: () =>
          import('./modules/layout/components/update-window/update-window.component').then(
            m => m.UpdateWindowComponent,
          ),
      },
      {
        path: 'recommendations',
        loadComponent: () =>
          import('./modules/recommendations/components/recommendations/recommendations.component').then(
            m => m.RecommendationsComponent,
          ),
      },
      {
        path: 'zenmode-list',
        loadComponent: () =>
          import('./modules/zenmode/components/zenmode-list/zenmode.component').then(m => m.ZenmodeComponent),
      },
      {
        path: 'chats',
        loadComponent: () =>
          import('./modules/chats/container/chats-container.component').then(m => m.ChatsContainerComponent),
        children: [
          {
            path: '',
            loadComponent: () =>
              import('./modules/chats/components/chat-placeholder/chat-placeholder.component').then(
                m => m.ChatPlaceholderComponent,
              ),
          },
          {
            path: ':id',
            loadComponent: () =>
              import('./modules/chats/components/chat-window/chat-window.component').then(
                m => m.ChatWindowComponent,
              ),
          },
        ],
      },
    ],
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'logout',
    component: LogoutComponent,
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
