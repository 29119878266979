import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ChatsCounterService {
  private unreadCountSub: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  setUnreadChatsCount(total: number) {
    this.unreadCountSub.next(total);
  }

  get unreadChatsCount$() {
    return this.unreadCountSub.asObservable();
  }
}
