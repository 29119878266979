import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ChatsContainerTabService {
  private showSearchFormSub = new BehaviorSubject<boolean>(false);
  private showHotOffersSub = new BehaviorSubject<boolean>(false);

  showSearchForm$ = this.showSearchFormSub.asObservable();
  showHotOffers$ = this.showHotOffersSub.asObservable();

  openSearchForm(): void {
    this.showSearchFormSub.next(true);
  }

  closeSearchForm(): void {
    this.showSearchFormSub.next(false);
  }

  openHotOffers(): void {
    this.showHotOffersSub.next(true);
  }

  closeHotOffers(): void {
    this.showHotOffersSub.next(false);
  }

  closeAll(): void {
    this.showHotOffersSub.next(false);

    this.showSearchFormSub.next(false);
  }
}
