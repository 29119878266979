<div class="template-manage">
  <div class="template">
    <h3>/{{ key }}</h3>
    <p>{{ value }}</p>
  </div>
  @if (isUserDefined) {
    <div class="controllers">
      <button class="manage-template edit" (click)="edit()"></button>
      <button class="manage-template remove" (click)="delete()"></button>
    </div>
  }
</div>
