import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { BrxIconComponent } from '../../../../ui-components/brx/icon/brx-icon.component';
import { BrxModalHeaderComponent } from '../../../../ui-components/brx/modal-header/brx-modal-header.component';

@Component({
  selector: 'app-dream-how-to',
  standalone: true,
  templateUrl: './dream-how-to.component.html',
  styleUrl: './dream-how-to.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [BrxModalHeaderComponent, BrxIconComponent],
})
export class DreamHowToComponent {
  public readonly dialogRef = inject(MatDialogRef);
}
