import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { BrxIconComponent } from '../icon/brx-icon.component';
import { BrxIcon } from '../icon/brx-icon.interface';

@Component({
  selector: 'brx-badge',
  standalone: true,
  templateUrl: './brx-badge.component.html',
  styleUrl: './brx-badge.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [BrxIconComponent, NgClass],
})
export class BrxBadgeComponent {
  /**
   * Цвет бейджа (default, primary, success, warning, danger, info)
   */
  public color = input<string>(null);
  public icon = input<BrxIcon>();
  public iconColor = input<string>(null);
  public size = input<'small'>(null);
  public isDisabled = input<boolean>(false);
  public isInteractive = input<boolean>(false);
  /**
   * Для подсветки элемента, даже когда мышка не над ним (нужно для всяких состояний типа "открыт тултип")
   */
  public isHovered = input<boolean>(false);
  public isRounded = input<boolean>(false);
  /**
   * Будет круглый, но лучами по кругу
   */
  public isFuzzy = input<boolean>(false);

  public classList = computed(() => {
    return {
      'is-disabled': this.isDisabled(),
      'is-interactive': this.isInteractive() && !this.isDisabled(),
      'is-hovered': this.isHovered(),
      'is-rounded': this.isRounded(),
      'is-fuzzy': this.isFuzzy(),
      [this.color()]: true,
      [this.size() ? 'size-' + this.size() : null]: true,
    };
  });
  public cssBackgroundColor = computed(() => {
    if (!this.color()) {
      return `var(--h-color-inverted-800)`;
    } else {
      if (this.color() === 'danger') {
        // Сильно красный для danger не нужен, чтобы не было слишком ярким
        return `var(--h-color-${this.color()}-250)`;
      } else {
        return `var(--h-color-${this.color()}-500)`;
      }
    }
  });
  public cssIconColor = computed(() => {
    const color = this.iconColor();
    if (!color) {
      return `var(--h-color-inverted-500)`;
    } else {
      if (color === 'danger') {
        // Сильно красный для danger не нужен, чтобы не было слишком ярким
        return `var(--h-color-${color}-250)`;
        // Если цвет начинается с --, то это переменная цвета
      } else if (color.startsWith('--')) {
        return `var(${color})`;
      } else {
        return `var(--h-color-${color}-500)`;
      }
    }
  });
}
