<button
  class="recorder-button"
  (click)="handleClick()"
  [class.is-recording]="isRecording()"
  [matTooltip]="getTooltip()"
>
  @if (!isProcessing()) {
    <brx-icon class="icon-mic" name="@brx.mic-text-outlined" [size]="20" />
  }
  @if (isProcessing()) {
    <brx-loader [size]="16" [isDelayed]="false" />
  }
</button>
