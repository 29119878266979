import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { AppConfig } from '../../../../environments/environment';
import { EmployeeDream, EmployeeDreamBatchUpdateRequest } from '@api-clients/api-client/dist';

@Injectable({
  providedIn: 'root',
})
export class DreamsApiService {
  private readonly http = inject(HttpClient);
  private readonly baseUrl = AppConfig.commonApiUrl;

  list(): Promise<EmployeeDream[]> {
    return firstValueFrom(this.http.get<EmployeeDream[]>(`${this.baseUrl}/employee-dream/list`));
  }

  batchUpdate(payload: EmployeeDreamBatchUpdateRequest): Promise<EmployeeDream[]> {
    return firstValueFrom(
      this.http.post<EmployeeDream[]>(`${this.baseUrl}/employee-dream/batch-update`, payload),
    );
  }
}
