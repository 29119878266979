@if (isLoading$ | async; as isLoading) {
  <brx-loader-fullscreen />
}
<iframe
  #frame
  [class.visible]="isCalendarFrameVisible$ | async"
  class="calendar-frame"
  [src]="htmlCalendarUrl"
></iframe>
@if (errorMessage$ | async; as errorMessage) {
  <app-alert-label [type]="dangerAlertType">
    {{ errorMessage }}
  </app-alert-label>
}
