import { ChangeDetectionStrategy, Component, computed, inject, input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTooltip } from '@angular/material/tooltip';
import { EmployeeDreamMotivation } from '@api-clients/api-client';
import { EmployeeDream } from '@api-clients/api-client/dist/models/employee-dream';
import { GalleryService } from '../../../../shared/services/gallery.service';
import { CloseModalButtonComponent } from '../../../../ui-components/close-modal-button/close-modal-button.component';

@Component({
  selector: 'app-dream-motivation',
  standalone: true,
  templateUrl: './dream-motivation.component.html',
  styleUrl: './dream-motivation.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CloseModalButtonComponent, MatTooltip],
})
export class DreamMotivationComponent {
  public readonly dialogRef = inject(MatDialogRef);
  private readonly dialogData: { dream: EmployeeDream; motivation: EmployeeDreamMotivation } =
    inject(MAT_DIALOG_DATA);
  private readonly galleryService = inject(GalleryService);

  dream = input<EmployeeDream>(this.dialogData.dream);
  motivation = input<EmployeeDreamMotivation>(this.dialogData.motivation);

  dreamMonth = computed(() => {
    const month = this.dream().deadlineDate.split('-')[1];
    return this.monthNames[+month - 1];
  });
  dreamYear = computed(() => this.dream().deadlineDate.split('-')[0]);

  private readonly monthNames = [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь',
  ];

  showImage(): void {
    this.galleryService.showGallery([
      {
        previewUrl: this.motivation().previewImageUrl,
        contentLink: this.motivation().imageUrl,
        text: this.motivation().text,
      },
    ]);
  }
}
