import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { CloseModalButtonComponent } from '../../close-modal-button/close-modal-button.component';

@Component({
  selector: 'brx-modal-header',
  standalone: true,
  imports: [CloseModalButtonComponent],
  templateUrl: './brx-modal-header.component.html',
  styleUrl: './brx-modal-header.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BrxModalHeaderComponent {
  public text = input<string>();
  public dialogRef = input<MatDialogRef<any>>();
}
