import { WhatsappContactsAction, WhatsappContactsActionTypes } from './whatsapp-contacts.actions';
import { whatsappContactsInitialState, WhatsappContactsState } from './whatsapp-contacts.state';

export const USER_FEATURE_KEY = 'whatsapp-contacts';

export function whatsappContactsReducer(
  state: WhatsappContactsState = whatsappContactsInitialState,
  action: WhatsappContactsAction,
): WhatsappContactsState {
  switch (action.type) {
    case WhatsappContactsActionTypes.LoadedContacts: {
      return {
        ...state,
        contactList: action.payload,
        isLoaded: true,
      };
    }
    case WhatsappContactsActionTypes.LoadedContactsError: {
      return {
        ...state,
        isLoaded: false,
        error: true,
      };
    }
    case WhatsappContactsActionTypes.LoadedInstance: {
      return {
        ...state,
        unreadChatCount: action.payload.instance.unreadChatCount,
        whatsappState: action.payload.instance.state,
        qrLink: action.payload.qrLink,
      };
    }
    case WhatsappContactsActionTypes.LoadedInstanceError: {
      return {
        ...state,
        isLoaded: false,
        error: true,
      };
    }
    case WhatsappContactsActionTypes.ClearContactUnreadMessagesByContactId: {
      const newContactList = state.contactList.slice();
      for (const [i, value] of newContactList.entries()) {
        if (value.lastMessage?.chatId === action.payload) {
          newContactList[i].contact.unreadMessageCount = 0;
        }
      }
      return {
        ...state,
        contactList: newContactList,
      };
    }
    case WhatsappContactsActionTypes.CountChatsWithUnreadMessages: {
      let unreadChats = 0;
      for (const item of state.contactList) {
        if (item.contact.unreadMessageCount > 0) {
          unreadChats += 1;
        }
      }
      return {
        ...state,
        unreadChatCount: unreadChats,
      };
    }
    case WhatsappContactsActionTypes.UpdateUnreadChatsCount: {
      const { unreadChatCount } = action.payload;
      return {
        ...state,
        unreadChatCount,
      };
    }
    case WhatsappContactsActionTypes.SetSelectedChat: {
      return {
        ...state,
        selectedChat: action.payload,
      };
    }
    case WhatsappContactsActionTypes.NewMessages: {
      return state;
      // вроде не используется, меняется схема контакта, пока аккуратно убираю спорные вещи
      // Если вы это видите после 01.02.2025 - удаляйте смело!
      // const newContactList = state.contactList.slice();
      // for (const item of action.payload) {
      //   for (const [i, value] of newContactList.entries()) {
      //     if (value.contact.id === item.contact.id && value.lastMessage !== item.message) {
      //       newContactList[i].lastMessage = item.message;
      //       newContactList[i].contact.lastMessageAt = value.lastMessage.receivedAt;
      //       if (value.contact.phone !== state.selectedChat.phone) {
      //         newContactList[i].contact.unreadMessageCount += 1;
      //       }
      //     }
      //   }
      // }
      // return {
      //   ...state,
      //   contactList: newContactList,
      // };
    }

    case WhatsappContactsActionTypes.NewContacts: {
      let newContactList = state.contactList.slice();
      newContactList = newContactList.concat(action.payload);
      return {
        ...state,
        contactList: newContactList,
      };
    }

    case WhatsappContactsActionTypes.LoadChat: {
      const newChats = state.chats.slice();
      for (const chat of newChats) {
        if (chat.contactId === action.payload.contactId) {
          chat.messages = action.payload.messages;
          return {
            ...state,
            chats: newChats,
          };
        }
      }
      newChats.push(action.payload);
      return {
        ...state,
        chats: newChats,
      };
    }

    case WhatsappContactsActionTypes.LoadDealViewToChat: {
      const newChats = state.chats.slice();
      for (const chat of newChats) {
        if (chat.crmCardId === action.payload.card.id) {
          chat.crmCardViewItem = action.payload;
        }
      }
      return {
        ...state,
        chats: newChats,
      };
    }
    case WhatsappContactsActionTypes.UpdateMessagesInChat: {
      const newChats = state.chats.slice();
      for (const chat of newChats) {
        if (chat.contactId === action.payload[0].contact.id) {
          let newMessages = chat.messages.slice();
          for (const item of action.payload) {
            if (newMessages[newMessages.length - 1].id !== item.message.id) {
              newMessages = newMessages.concat(item.message);
            }
          }
          chat.messages = newMessages.slice();
        }
      }
      return {
        ...state,
        chats: newChats,
      };
    }

    default:
      return state;
  }
}
