import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  inject,
  OnInit,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialogRef } from '@angular/material/dialog';
import { WhatsappMessageTemplate } from '@api-clients/crm-api-client';
import { TranslateModule } from '@ngx-translate/core';
import { CloseModalButtonComponent } from '../../../../ui-components/close-modal-button/close-modal-button.component';
import { WhatsappTemplateFormComponent } from './components/whatsapp-template-form/whatsapp-template-form.component';
import { WhatsappTemplateManageItemComponent } from './components/whatsapp-template-manage-item/whatsapp-template-manage-item.component';
import { MessageTemplatesApiService } from './services/message-templates-api.service';
import { MessageTemplatesService } from './services/message-templates.service';

@Component({
  selector: 'app-whatsapp-templates-settings',
  templateUrl: './whatsapp-templates-settings.component.html',
  styleUrls: ['./whatsapp-templates-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    TranslateModule,
    WhatsappTemplateManageItemComponent,
    WhatsappTemplateFormComponent,
    CloseModalButtonComponent,
  ],
})
export class WhatsappTemplatesSettingsComponent implements OnInit {
  private readonly messageTemplatesService = inject(MessageTemplatesService);
  private readonly messageTemplatesApiService = inject(MessageTemplatesApiService);
  private readonly cdRef = inject(ChangeDetectorRef);
  private readonly destroyRef = inject(DestroyRef);
  public dialogRef = inject(MatDialogRef<WhatsappTemplatesSettingsComponent>);

  public templates: WhatsappMessageTemplate[] = [];
  public isLoading = false;
  public currentTemplate: WhatsappMessageTemplate;

  ngOnInit(): void {
    this.refreshTemplateList();
  }

  refreshTemplateList() {
    this.messageTemplatesService.triggerLoadNewTemplates();
    this.isLoading = true;
    this.messageTemplatesApiService
      .getTemplates()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: templates => {
          if (templates) {
            this.templates = templates;
            this.isLoading = false;
            this.cdRef.markForCheck();
          }
        },
        error: error => {
          console.error('Failed to load templates', error);
          this.isLoading = false;
          this.cdRef.markForCheck();
        },
      });
  }

  editTemplate(event: WhatsappMessageTemplate) {
    this.currentTemplate = event;
    this.cdRef.markForCheck();
  }
}
