import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  DefaultResponse,
  DeleteWhatsappMessageTemplateRequest,
  UpdateWhatsappMessageTemplateRequest,
  WhatsappMessageTemplate,
} from '@api-clients/crm-api-client';
import { CreateWhatsappMessageTemplateRequest } from '@api-clients/crm-api-client/models';
import { Observable } from 'rxjs';
import { ApiResponse } from '../../../../../../../../backend/src/models/models';
import { AppConfig } from '../../../../../../environments/environment';
import { apiResponsePipeTyped } from '../../../../../api-response-typed.pipe';
import { apiResponsePipe } from '../../../../../api-response.pipe';

@Injectable({
  providedIn: 'root',
})
export class MessageTemplatesApiService {
  constructor(private http: HttpClient) {}

  getTemplates(): Observable<WhatsappMessageTemplate[]> {
    return this.http
      .get<ApiResponse<WhatsappMessageTemplate[]>>(`${AppConfig.apiUrl}/whatsapp-message-template/list`)
      .pipe(apiResponsePipeTyped<WhatsappMessageTemplate[]>());
  }

  create(body: CreateWhatsappMessageTemplateRequest) {
    return this.http
      .post<ApiResponse<DefaultResponse>>(`${AppConfig.apiUrl}/whatsapp-message-template/create`, body)
      .pipe(apiResponsePipe);
  }

  update(body: UpdateWhatsappMessageTemplateRequest) {
    return this.http
      .post<ApiResponse<DefaultResponse>>(`${AppConfig.apiUrl}/whatsapp-message-template/update`, body)
      .pipe(apiResponsePipe);
  }

  delete(body: DeleteWhatsappMessageTemplateRequest) {
    return this.http
      .post<ApiResponse<DefaultResponse>>(`${AppConfig.apiUrl}/whatsapp-message-template/delete`, body)
      .pipe(apiResponsePipe);
  }
}
