<nav class="aside-nav">
  @if (isChatsRoute()) {
    <div class="section-switcher">
      <div
        class="section-label"
        [class.clickable]="!currentStageChatListFilterIsAll()"
        [class.active]="currentStageChatListFilterIsAll()"
        (click)="resetStageFilter()"
      >
        @if (unreadChatsCount()) {
          <span class="marker-unread in-section"></span>
        }
        <img width="24" height="24" src="assets/icons/aside-menu/chats-icon.svg" alt="" srcset="" />
        <span class="section-caption">{{ 'LAYOUT.ASIDE.CHATS' | translate }} </span>
      </div>
    </div>
    <div class="aside-chats-filters">
      <div class="aside-chats-filters__item">
        <div class="deal-top-stages-container">
          @if (getStageCounterBy$(chatListStageFilterKeys.MISSED_AND_URGENT) | async; as counter) {
            <a
              (click)="setStageFilter(chatListStageFilterKeys.MISSED_AND_URGENT)"
              class="chat-filter missed-and-urgent"
              [class.active]="currentStageChatListFilter() === chatListStageFilterKeys.MISSED_AND_URGENT"
              [class.muted]="counter.total === 0"
              matTooltip="{{
                'LAYOUT.ASIDE.CHATS_FILTERS.' + chatListStageFilterKeys.MISSED_AND_URGENT | translate
              }}"
              matTooltipPosition="right"
            >
              @if (counter.total > 0) {
                <span class="stage-counter">
                  {{ counter.total }}
                </span>
                @if (counter.unread) {
                  <span class="marker-unread"></span>
                }
              }
            </a>
          }
          @if (getStageCounterBy$(chatListStageFilterKeys.NEW) | async; as counter) {
            <a
              (click)="setStageFilter(chatListStageFilterKeys.NEW)"
              class="chat-filter new"
              [class.active]="currentStageChatListFilter() === chatListStageFilterKeys.NEW"
              [class.muted]="counter.total === 0"
              matTooltip="{{ 'LAYOUT.ASIDE.CHATS_FILTERS.' + chatListStageFilterKeys.NEW | translate }}"
              matTooltipPosition="right"
            >
              @if (counter.total > 0) {
                <span class="stage-counter">
                  {{ counter.total }}
                </span>
                @if (counter.unread || counter.hasUnreadDealChanges) {
                  <span class="marker-unread"></span>
                }
              }
            </a>
          }
        </div>
        <div class="stage-with-subfilters" [class.opened]="isDaysFiltersOpened()">
          <a
            (click)="setStageFilter(chatListStageFilterKeys.IN_PROGRESS)"
            class="chat-filter work-in-progress"
            [class.active]="currentStageChatListFilter() === chatListStageFilterKeys.IN_PROGRESS"
            matTooltip="{{ 'LAYOUT.ASIDE.CHATS_FILTERS.' + chatListStageFilterKeys.IN_PROGRESS | translate }}"
            matTooltipPosition="right"
          >
            @if (getStageCounterBy$(chatListStageFilterKeys.IN_PROGRESS) | async; as counter) {
              @if (counter.total > 0) {
                <span class="stage-counter">
                  {{ counter.total }}
                </span>
                @if (counter.unread) {
                  <span class="marker-unread"></span>
                }
              }
            }
          </a>
          @if (isDaysFiltersOpened()) {
            <div class="days-filters">
              <a
                (click)="setStageFilter(chatListStageFilterKeys.FIRST_DAY)"
                class="days-stage"
                [class.active]="currentStageChatListFilter() === chatListStageFilterKeys.FIRST_DAY"
                matTooltip="{{
                  'LAYOUT.ASIDE.CHATS_FILTERS.' + chatListStageFilterKeys.FIRST_DAY | translate
                }}"
                matTooltipPosition="right"
              >
                <span class="days-stage-label">1 д</span>
                @if (getStageCounterBy$(chatListStageFilterKeys.FIRST_DAY) | async; as counter) {
                  @if (counter.total > 0) {
                    <span class="stage-counter">
                      {{ counter.total }}
                    </span>
                    @if (counter.unread) {
                      <span class="marker-unread in-submenu"></span>
                    }
                  }
                }
              </a>
              <a
                (click)="setStageFilter(chatListStageFilterKeys.SECOND_DAY)"
                class="days-stage"
                [class.active]="currentStageChatListFilter() === chatListStageFilterKeys.SECOND_DAY"
                matTooltip="{{
                  'LAYOUT.ASIDE.CHATS_FILTERS.' + chatListStageFilterKeys.SECOND_DAY | translate
                }}"
                matTooltipPosition="right"
              >
                <span class="days-stage-label">2 д</span>
                @if (getStageCounterBy$(chatListStageFilterKeys.SECOND_DAY) | async; as counter) {
                  @if (counter.total > 0) {
                    <span class="stage-counter">
                      {{ counter.total }}
                    </span>
                    @if (counter.unread) {
                      <span class="marker-unread in-submenu"></span>
                    }
                  }
                }
              </a>
              <a
                (click)="setStageFilter(chatListStageFilterKeys.THIRD_DAY)"
                class="days-stage"
                [class.active]="currentStageChatListFilter() === chatListStageFilterKeys.THIRD_DAY"
                matTooltip="{{
                  'LAYOUT.ASIDE.CHATS_FILTERS.' + chatListStageFilterKeys.THIRD_DAY | translate
                }}"
                matTooltipPosition="right"
              >
                <span class="days-stage-label">3 д</span>
                @if (getStageCounterBy$(chatListStageFilterKeys.THIRD_DAY) | async; as counter) {
                  @if (counter.total > 0) {
                    <span class="stage-counter">
                      {{ counter.total }}
                    </span>
                    @if (counter.unread) {
                      <span class="marker-unread in-submenu"></span>
                    }
                  }
                }
              </a>
              <a
                (click)="setStageFilter(chatListStageFilterKeys.FOURTH_TO_SEVENTH_DAYS)"
                class="days-stage"
                [class.active]="
                  currentStageChatListFilter() === chatListStageFilterKeys.FOURTH_TO_SEVENTH_DAYS
                "
                matTooltip="{{
                  'LAYOUT.ASIDE.CHATS_FILTERS.' + chatListStageFilterKeys.FOURTH_TO_SEVENTH_DAYS | translate
                }}"
                matTooltipPosition="right"
              >
                <span class="days-stage-label">4-7 д</span>
                @if (getStageCounterBy$(chatListStageFilterKeys.FOURTH_TO_SEVENTH_DAYS) | async; as counter) {
                  @if (counter.total > 0) {
                    <span class="stage-counter">
                      {{ counter.total }}
                    </span>
                    @if (counter.unread) {
                      <span class="marker-unread in-submenu"></span>
                    }
                  }
                }
              </a>
              <a
                (click)="setStageFilter(chatListStageFilterKeys.MORE_THAN_SEVEN_DAYS)"
                class="days-stage"
                [class.active]="currentStageChatListFilter() === chatListStageFilterKeys.MORE_THAN_SEVEN_DAYS"
                matTooltip="{{
                  'LAYOUT.ASIDE.CHATS_FILTERS.' + chatListStageFilterKeys.MORE_THAN_SEVEN_DAYS | translate
                }}"
                matTooltipPosition="right"
              >
                <span class="days-stage-label">> 7 д</span>
                @if (getStageCounterBy$(chatListStageFilterKeys.MORE_THAN_SEVEN_DAYS) | async; as counter) {
                  @if (counter.total > 0) {
                    <span class="stage-counter">
                      {{ counter.total }}
                    </span>
                    @if (counter.unread) {
                      <span class="marker-unread in-submenu"></span>
                    }
                  }
                }
              </a>
            </div>
          }
        </div>
      </div>
      <div class="aside-chats-filters__item aside-chats-filters__item--bottom">
        <div class="aside-chats-filters__item">
          <a
            (click)="setStageFilter(chatListStageFilterKeys.MEETING)"
            class="chat-filter meeting"
            [class.active]="currentStageChatListFilter() === chatListStageFilterKeys.MEETING"
            matTooltip="{{ 'LAYOUT.ASIDE.CHATS_FILTERS.' + chatListStageFilterKeys.MEETING | translate }}"
            matTooltipPosition="right"
          >
            @if (getStageCounterBy$(chatListStageFilterKeys.MEETING) | async; as counter) {
              @if (counter.total > 0) {
                <span class="stage-counter">
                  {{ counter.total }}
                </span>
                @if (counter.unread) {
                  <span class="marker-unread"></span>
                }
              }
            }
          </a>
          <a
            (click)="setStageFilter(chatListStageFilterKeys.READY_FOR_BOOKING)"
            class="chat-filter ready-for-booking"
            [class.active]="currentStageChatListFilter() === chatListStageFilterKeys.READY_FOR_BOOKING"
            matTooltip="{{
              'LAYOUT.ASIDE.CHATS_FILTERS.' + chatListStageFilterKeys.READY_FOR_BOOKING | translate
            }}"
            matTooltipPosition="right"
          >
            @if (getStageCounterBy$(chatListStageFilterKeys.READY_FOR_BOOKING) | async; as counter) {
              @if (counter.total > 0) {
                <span class="stage-counter">
                  {{ counter.total }}
                </span>
                @if (counter.unread) {
                  <span class="marker-unread"></span>
                }
              }
            }
          </a>
          <a
            (click)="setStageFilter(chatListStageFilterKeys.POSTPONED)"
            class="chat-filter postponed"
            [class.active]="currentStageChatListFilter() === chatListStageFilterKeys.POSTPONED"
            matTooltip="{{ 'LAYOUT.ASIDE.CHATS_FILTERS.' + chatListStageFilterKeys.POSTPONED | translate }}"
            matTooltipPosition="right"
          >
            @if (getStageCounterBy$(chatListStageFilterKeys.POSTPONED) | async; as counter) {
              @if (counter.total > 0) {
                <span class="stage-counter">
                  {{ counter.total }}
                </span>
                @if (counter.unread) {
                  <span class="marker-unread"></span>
                }
              }
            }
          </a>
        </div>
        <div class="aside-chats-filters__item">
          <a
            (click)="setStageFilter(chatListStageFilterKeys.COLD_TOUCH)"
            class="chat-filter cold-touch"
            [class.active]="currentStageChatListFilter() === chatListStageFilterKeys.COLD_TOUCH"
            matTooltip="{{ 'LAYOUT.ASIDE.CHATS_FILTERS.' + chatListStageFilterKeys.COLD_TOUCH | translate }}"
            matTooltipPosition="right"
          >
            @if (getStageCounterBy$(chatListStageFilterKeys.COLD_TOUCH) | async; as counter) {
              @if (counter.total > 0) {
                <span class="stage-counter">
                  {{ counter.total }}
                </span>
                @if (counter.unread) {
                  <span class="marker-unread"></span>
                }
              }
            }
          </a>
          <a
            (click)="setStageFilter(chatListStageFilterKeys.POST_SALE)"
            class="chat-filter post-sale"
            [class.active]="currentStageChatListFilter() === chatListStageFilterKeys.POST_SALE"
            matTooltip="{{ 'LAYOUT.ASIDE.CHATS_FILTERS.' + chatListStageFilterKeys.POST_SALE | translate }}"
            matTooltipPosition="right"
          >
            @if (getStageCounterBy$(chatListStageFilterKeys.POST_SALE) | async; as counter) {
              @if (counter.total > 0) {
                <span class="stage-counter">
                  {{ counter.total }}
                </span>
                @if (counter.unread) {
                  <span class="marker-unread"></span>
                }
              }
            }
          </a>
          <!-- Footer -->
          <div class="add-deal-container">
            <button type="button" class="add-deal-button" (click)="showAddDealPopup()">
              <img
                src="assets/icons/plus-bold-blue.svg"
                width="16"
                height="16"
                alt=""
                class="add-deal-icon"
              />
            </button>
          </div>
          <!-- Footer End -->
        </div>
      </div>
    </div>
  } @else {
    <a [routerLink]="['/deals']" routerLinkActive="active" class="aside-nav-clients">
      {{ 'LAYOUT.ASIDE.DEALS' | translate }}
      @if (dealsTotalCount()) {}
      <span class="counter">{{ dealsTotalCount() }}</span>
    </a>
    <!-- TODO: Delete if no need in future-->
    @if ((isOnlyZenmode$ | async) === false) {
      <app-aside-chats />
    }
  }
</nav>
