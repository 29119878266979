import { select, Store } from '@ngrx/store';
import { CrmCardViewItem, WhatsappContactListItem, WhatsappNewMessage } from '@api-clients/crm-api-client';
import { Injectable } from '@angular/core';
import * as WhatsappContactsSelectors from './whatsapp-contacts.selectors';
import { WhatsappContactsState } from './whatsapp-contacts.state';
import {
  ClearContactUnreadMessagesByContactId,
  CountChatsWithUnreadMessages,
  LoadChat,
  LoadContacts,
  LoadDealViewToChat,
  LoadInstance,
  NewContacts,
  NewMessages,
  SetSelectedChat,
  UpdateMessagesInChat,
} from './whatsapp-contacts.actions';
import { ChatPayload, SelectedChatPayload } from './whatsapp-contacts.interfaces';

@Injectable()
export class WhatsappContactsFacade {
  whatsappContacts$ = this.store.pipe(select(WhatsappContactsSelectors.getWhatsappContacts));
  unreadChats$ = this.store.pipe(select(WhatsappContactsSelectors.getWhatsappUnreadChats));
  whatsappState$ = this.store.pipe(select(WhatsappContactsSelectors.getWhatsappState));
  qrLink$ = this.store.pipe(select(WhatsappContactsSelectors.getQrLink));
  selectedChat$ = this.store.pipe(select(WhatsappContactsSelectors.getSelectedChat));
  loadedChats$ = this.store.pipe(select(WhatsappContactsSelectors.getChats));

  constructor(private store: Store<WhatsappContactsState>) {}

  loadContacts() {
    this.store.dispatch(new LoadContacts());
  }

  loadInstance() {
    this.store.dispatch(new LoadInstance());
  }

  loadChat(payload: ChatPayload) {
    this.store.dispatch(new LoadChat(payload));
  }

  clearContactUnreadMessagesByContactId(chatId) {
    this.store.dispatch(new ClearContactUnreadMessagesByContactId(chatId));
  }
  countChatsWithUnreadMessages() {
    this.store.dispatch(new CountChatsWithUnreadMessages());
  }

  setSelectedChat(payload: SelectedChatPayload) {
    this.store.dispatch(new SetSelectedChat(payload));
  }

  loadDealViewToChat(payload: CrmCardViewItem) {
    this.store.dispatch(new LoadDealViewToChat(payload));
  }
  updateMessagesInChat(payload: WhatsappNewMessage[]) {
    this.store.dispatch(new UpdateMessagesInChat(payload));
  }

  refreshContactsByNewMessages(payload: WhatsappNewMessage[]) {
    this.store.dispatch(new NewMessages(payload));
  }
  refreshContactsByNewContacts(payload: WhatsappContactListItem[]) {
    this.store.dispatch(new NewContacts(payload));
  }
}
