import { NgClass } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy, inject, signal } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { CloseModalButtonComponent } from '../../ui-components/close-modal-button/close-modal-button.component';
import { Notify, NotifyService } from './services/notify.service';

const HIDE_DELAY = 3000;

@Component({
  selector: 'app-notify',
  templateUrl: './notify.component.html',
  styleUrls: ['./notify.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ opacity: 0, marginLeft: '100px' }),
        animate('0.2s ease-out', style({ opacity: 1, marginLeft: '0' })),
      ]),
      transition(':leave', [
        style({ opacity: 1, marginLeft: '0' }),
        animate('0.2s ease-in', style({ opacity: 0, marginLeft: '100px' })),
      ]),
    ]),
  ],
  imports: [NgClass, CloseModalButtonComponent],
})
export class NotifyComponent implements OnInit {
  private readonly notifyService = inject(NotifyService);

  public notifyList = signal<Notify[]>([]);

  ngOnInit(): void {
    this.notifyService.createEvent.subscribe((notify: Notify) => {
      if (!notify.title && notify.type === 'error') {
        notify.title = 'Ошибка';
      }
      this.notifyList.set([...this.notifyList(), notify]);
      setTimeout(() => {
        this.close(notify);
      }, HIDE_DELAY);
    });
  }

  close(notify: Notify): void {
    this.notifyList.set(this.notifyList().filter(item => item !== notify));
  }
}
