import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  effect,
  OnInit,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CrmCardViewItem } from '@api-clients/crm-api-client';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NgxPermissionsService } from 'ngx-permissions';
import { DealsFacade } from '../../../../+state/deals/deals.facade';
import { SettingsFacade } from '../../../../+state/settings/settings.facade';
import { WorkerStateService } from '../../../../core/services';
import { AmplitudeTrackService } from '../../../../core/services/amplitude/amplitude-track.service';
import { ASIDE_CHATS_SCROLL_ENABLED_SET } from '../../../../core/services/amplitude/amplitudeEvents';
import { ElectronService } from '../../../../core/services/electron.service';
import { WebsocketHelperService } from '../../../../core/services/websocket/helper/websocket-helper.service';
import { Worker } from '../../../../models';
import { CallNotification, CurrentNotification } from '../../../../models/ipcEvent';
import { CloseModalButtonComponent } from '../../../../ui-components/close-modal-button/close-modal-button.component';
import { SlideToggleComponent } from '../../../../ui-components/slide-toggle/components/slide-toggle.component';
import { DealTasksService } from '../../../deals/services/deal-tasks.service';
import { ZenmodeToggleComponent } from '../../../zenmode/modules/zenmode-welcome-popup-container/components/zenmode-toggle/zenmode-toggle.component';
import { MessageTemplatesModalService } from '../whatsapp-templates-settings/services/message-templates-modal.service';
import { ZoomLevelToPercentPipe } from './pipes/zoom-level-to-percent.pipe';
import { SettingsHelperService } from './services/settings-helper.service';
import { ZoiperCallService } from './services/zoiper-call.service';
import { WhatsappNotificationsService } from '../../../../shared/services/whatsapp-notifications-enabled.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,

  imports: [
    TranslateModule,
    SlideToggleComponent,
    AsyncPipe,
    ZoomLevelToPercentPipe,
    ZenmodeToggleComponent,
    CloseModalButtonComponent,
  ],
})
export class SettingsComponent implements OnInit {
  public worker: Worker;
  public permissions: string[];
  public isWhatsappNotificationsEnabled: boolean;
  public isWhatsappChatsNotificationsEnabled: boolean;
  public isWhatsappGroupsNotificationsEnabled: boolean;

  public isShowDevToolsColumn = false;
  public isZoiperCall$ = this.zoiperCallService.isZoiperCall$;
  public isAsideChatsScrollEnabled$ = this.settingsFacade.asideChatsScrollBarEnabled$;
  public isZenModeOn$ = this.dealsFacade.zenmodeOn$;
  public labels = {
    WHATSAPP_NOTIFICATIONS_ENABLED: '',
    WHATSAPP_NOTIFICATIONS_DISABLED: '',
    WHATSAPP_GROUPS_NOTIFICATIONS_ENABLED: '',
    WHATSAPP_GROUPS_NOTIFICATIONS_DISABLED: '',
    ZOIPER_CALL_ENABLED: '',
    ZOIPER_CALL_DISABLED: '',
    ASIDE_CHATS_SCROLL_ENABLED: '',
    ASIDE_CHATS_SCROLL_DISABLED: '',
    SALES_NOTIFY_ENABLED: '',
    SALES_NOTIFY_DISABLED: '',
  };
  zoomLevel = signal<number>(1);
  public salesNotifyDisabled = false;

  constructor(
    private electronService: ElectronService,
    private workerStateService: WorkerStateService,
    private permissionsService: NgxPermissionsService,
    private settingsFacade: SettingsFacade,
    private translateService: TranslateService,
    private cdRef: ChangeDetectorRef,
    private router: Router,
    private websocketHelperService: WebsocketHelperService,
    private zoiperCallService: ZoiperCallService,
    private settingsHelperService: SettingsHelperService,
    private dealsFacade: DealsFacade,
    private amplitudeTrackService: AmplitudeTrackService,
    private whatsappNotificationsService: WhatsappNotificationsService,
    private messageTemplatesModalService: MessageTemplatesModalService,
    private dealTasksService: DealTasksService,
    public dialogRef: MatDialogRef<SettingsComponent>,
    private destroyRef: DestroyRef,
  ) {
    effect(() => {
      const zoomLevel = this.zoomLevel();
      localStorage.setItem('userZoomConfig', zoomLevel.toString());
    });
  }

  ngOnInit(): void {
    this.initializeZoomLevel();
    this.initializeSalesNotifyDisabled();
    this.initializeWhatsappNotificationsEnabled();
    this.electronService.ipcRenderer.on('notifications-window-info', (event, info) => {
      console.log('notifications-window-info', event, info);
    });

    this.electronService.ipcRenderer.on('zoom-level', (_, newZoom: number) => {
      this.zoomLevel.set(newZoom);
    });

    this.worker = this.workerStateService.currentWorkerValue;
    this.permissionsService.permissions$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(x => {
      const permissions: string[] = [];
      for (const key in x) {
        if (x.hasOwnProperty(key)) {
          permissions.push(key);
        }
      }
      this.permissions = permissions;
    });
    this.settingsFacade.whatsappNotificationsEnabled$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(value => {
        this.isWhatsappNotificationsEnabled = value;
        this.cdRef.detectChanges();
      });

    const languageKeys = {
      'PAGES.SETTINGS.WHATSAPP.WHATSAPP_NOTIFICATIONS_ENABLED': 'WHATSAPP_NOTIFICATIONS_ENABLED',
      'PAGES.SETTINGS.WHATSAPP.WHATSAPP_NOTIFICATIONS_DISABLED': 'WHATSAPP_NOTIFICATIONS_DISABLED',
      'PAGES.SETTINGS.ZOIPER_CALL.ZOIPER_CALL_ENABLED': 'ZOIPER_CALL_ENABLED',
      'PAGES.SETTINGS.ZOIPER_CALL.ZOIPER_CALL_DISABLED': 'ZOIPER_CALL_DISABLED',
      'PAGES.SETTINGS.ASIDE_CHATS_SCROLL.ASIDE_CHATS_SCROLL_ENABLED': 'ASIDE_CHATS_SCROLL_ENABLED',
      'PAGES.SETTINGS.ASIDE_CHATS_SCROLL.ASIDE_CHATS_SCROLL_DISABLED': 'ASIDE_CHATS_SCROLL_DISABLED',
      'PAGES.SETTINGS.SALES_NOTIFY.SALES_NOTIFY_ENABLED': 'SALES_NOTIFY_ENABLED',
      'PAGES.SETTINGS.SALES_NOTIFY.SALES_NOTIFY_DISABLED': 'SALES_NOTIFY_DISABLED',
    };

    for (const langKey in languageKeys) {
      if (languageKeys.hasOwnProperty(langKey)) {
        const label = languageKeys[langKey];
        this.translateService
          .get(langKey)
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe(value => {
            this.labels[label] = value;
          });
      }
    }
  }

  openMessageTemplatesModal(): void {
    this.messageTemplatesModalService.showModal();
  }

  initializeZoomLevel() {
    const userZoomConfig = parseFloat(localStorage.getItem('userZoomConfig')) || 1;
    this.zoomLevel.set(userZoomConfig);
  }

  initializeSalesNotifyDisabled() {
    const storedValue = localStorage.getItem('salesNotifyDisabled');
    this.salesNotifyDisabled = storedValue === 'true';
  }

  initializeWhatsappNotificationsEnabled() {
    this.isWhatsappChatsNotificationsEnabled =
      this.whatsappNotificationsService.getWhatsappChatsNotificationsEnabled();
    this.isWhatsappGroupsNotificationsEnabled =
      this.whatsappNotificationsService.getWhatsappGroupsNotificationsEnabled();
  }

  saveWhatsappChatsNotificationsEnabled(value: boolean): void {
    this.whatsappNotificationsService.updateWhatsappChatsNotificationsEnabled(value);
  }

  saveWhatsappGroupsNotificationsEnabled(value: boolean): void {
    this.whatsappNotificationsService.updateWhatsappGroupsNotificationsEnabled(value);
  }

  zoomIn() {
    this.electronService.ipcRenderer.send('zoom-in');
  }

  zoomOut() {
    this.electronService.ipcRenderer.send('zoom-out');
  }

  openDevTools() {
    this.electronService.ipcRenderer.send('openMainDevTools');
  }

  openNotificationsDevTools() {
    this.electronService.ipcRenderer.send('openNotificationDevTools');
  }

  openUpdateScreen() {
    this.router.navigate(['update']).then();
  }

  onWhatsappNotificationsSettingChange($event: boolean) {
    this.settingsFacade.saveSettings({ isWhatsappNotificationsEnabled: $event });
  }

  sendFakeNotifyAboutTourPackageDocs() {
    const fakeNotify: CurrentNotification =
      this.settingsHelperService.getFakeTourPackageDocsReadyNotification();

    this.websocketHelperService.showFakeNotify(fakeNotify);
  }

  sendFakeNotifyAboutTask() {
    this.dealTasksService.sendTasksToNotifications([this.settingsHelperService.getFakeTaskNotification()]);
  }

  sendFakeNotifyAboutCall() {
    const fakeNotify: CallNotification = this.settingsHelperService.getFakeCallNotification(true, false);

    this.websocketHelperService.showNewInboxCallNotify(fakeNotify);
  }

  sendFakeNotifyAboutCallFromApp() {
    const fakeNotify: CallNotification = this.settingsHelperService.getFakeCallNotification(true, true);

    this.websocketHelperService.showNewInboxCallNotify(fakeNotify);
  }

  sendFakeNotifyAboutCallWithoutDealView() {
    const fakeNotify: CallNotification = this.settingsHelperService.getFakeCallNotification(false);

    this.websocketHelperService.showNewInboxCallNotify(fakeNotify);
  }

  sendFakeOnStartConversation() {
    const fakeCrmCardViewItem: CrmCardViewItem = this.settingsHelperService.getFakeCrmCardViewItem();
    this.websocketHelperService.onStartConversation(fakeCrmCardViewItem);
    // setTimeout(() => {
    //   this.websocketHelperService.onEndCall({
    //     from: 'test',
    //     dealView: fakeCrmCardViewItem,
    //   });
    // }, 15_000);
  }

  showNotificationsWindow() {
    this.electronService.ipcRenderer.send('showNotificationsWindow');
  }

  closeNotificationsWindow() {
    this.electronService.ipcRenderer.send('closeNotificationsWindow');
  }

  showInConsoleNotificationsWindowInfo() {
    this.electronService.ipcRenderer.send('show-in-console-notifications-window-info');
  }

  changeIsZoiperCall(event: boolean) {
    this.zoiperCallService.changeIsZoiperCall(event);
  }

  changeIsAsideChatScrollEnabled($event: boolean) {
    this.settingsFacade.saveSettings({ isAsideChatsScrollBarEnabled: $event });
    this.amplitudeTrackService.trackEvent(ASIDE_CHATS_SCROLL_ENABLED_SET, { value: $event.toString() });
  }

  changeIsSalesNotifyDisabled($event: boolean) {
    localStorage.setItem('salesNotifyDisabled', $event.toString());
  }

  openZenmodeList() {
    this.router.navigate(['zenmode-list/']).then();
  }

  openTestUrl() {
    this.settingsHelperService.openUrlWithDefaultBrowser('https://www.google.com');
  }

  openTestUrlElectron() {
    this.electronService.shell.openExternal('https://www.google.com').then();
  }

  changeToDevVersion() {
    (window as any).location = 'https://stage-gera.ht.kz';
  }
}
