import { HttpErrorResponse } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  inject,
  resource,
  ResourceStatus,
  signal,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { EmployeeDreamUpdateRequest } from '@api-clients/api-client/models/employee-dream-update-request';
import { AlertLabelComponent, AlertLabelType } from '../../ui-components/alert-label/alert-label.component';
import { BrxBadgeComponent } from '../../ui-components/brx/badge/brx-badge.component';
import { BrxButtonComponent } from '../../ui-components/brx/button/brx-button.component';
import { BrxErrorComponent } from '../../ui-components/brx/error/brx-error.component';
import { BrxIconComponent } from '../../ui-components/brx/icon/brx-icon.component';
import { BrxModalHeaderComponent } from '../../ui-components/brx/modal-header/brx-modal-header.component';
import { BrxLoaderFullscreenComponent } from '../../ui-components/brx/loader-fullscreen/brx-loader-fullscreen.component';
import { DreamHowToComponent } from './components/dream-how-to/dream-how-to.component';
import { DreamFormComponent } from './dream-form/dream-form.component';
import { EditableEmployeeDream } from './interfaces/dream.interface';
import { DreamsApiService } from './services/dreams-api.service';

@Component({
  selector: 'app-frontend-dreams',
  standalone: true,
  imports: [
    BrxModalHeaderComponent,
    BrxLoaderFullscreenComponent,
    ReactiveFormsModule,
    FormsModule,
    BrxIconComponent,
    BrxButtonComponent,
    AlertLabelComponent,
    DreamFormComponent,
    BrxBadgeComponent,
    BrxErrorComponent,
  ],
  templateUrl: './dreams.component.html',
  styleUrl: './dreams.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DreamsComponent {
  public readonly dialogRef = inject(MatDialogRef<DreamsComponent>);
  private readonly dreamsApiService = inject(DreamsApiService);
  private readonly matDialog = inject(MatDialog);

  public dreamsResource = resource({
    loader: () => this.dreamsApiService.list(),
  });

  public dreams = computed<EditableEmployeeDream[]>(() => {
    if (!this.dreamsResource.value() || !this.dreamsResource.value().length) {
      return [];
    }
    return this.dreamsResource.value().map(dream => {
      const [deadlineYear, deadlineMonth] = dream.deadlineDate ? dream.deadlineDate.split('-') : [null, null];
      return {
        ...dream,
        deadlineMonth: deadlineMonth ? +deadlineMonth : null,
        deadlineYear: deadlineYear ? +deadlineYear : null,
      } as EditableEmployeeDream;
    });
  });

  public doneDreamIds = new Set<number>();
  public notDoneDreamsCount = signal(0);
  public doneDreamsCount = signal(0);
  public isDoneDreamsVisible = signal(false);

  public isLoading = this.dreamsResource.isLoading;
  public error = this.dreamsResource.error;
  public isSaving = signal(false);
  public isSuccessSaved = signal(false);
  public saveError = signal<HttpErrorResponse>(null);

  protected readonly successLabelType = AlertLabelType.success;

  constructor() {
    let countersInitialized = false;
    effect(() => {
      if (countersInitialized) {
        return;
      }
      if (this.dreamsResource.status() === ResourceStatus.Resolved && this.dreamsResource.value().length) {
        this.calcCounters();
        countersInitialized = true;
      }
    });
  }

  addDream(clearSaveState = false): void {
    if (clearSaveState) {
      this.isSuccessSaved.set(false);
      this.saveError.set(null);
    }
    this.notDoneDreamsCount.set(this.notDoneDreamsCount() + 1);
    this.dreamsResource.set([
      ...this.dreams(),
      { deadlineMonth: null, deadlineYear: null } as EditableEmployeeDream,
    ]);
  }

  removeDream(dream: EditableEmployeeDream): void {
    const isDeleteAllDoneDreams = dream.isDone && this.doneDreamsCount() === 1;
    const isDeleteAllNotDoneDreams = !dream.isDone && this.notDoneDreamsCount() === 1;
    if (isDeleteAllDoneDreams && !confirm('Вы уверены, что хотите удалить все неисполненные мечты?')) {
      return;
    }
    if (isDeleteAllNotDoneDreams && !confirm('Вы уверены, что хотите удалить все исполненные мечты?')) {
      return;
    }
    this.dreamsResource.set(this.dreams().filter(d => d !== dream));
    if (dream.isDone) {
      this.doneDreamsCount.set(this.doneDreamsCount() - 1);
    } else {
      this.notDoneDreamsCount.set(this.notDoneDreamsCount() - 1);
    }
    if (isDeleteAllDoneDreams || isDeleteAllNotDoneDreams) {
      this.saveDreams();
    }
  }

  saveDreams(): void {
    const dreams = this.dreams().map(dream => {
      const { deadlineMonth, deadlineYear, ...rest } = dream;
      let deadlineMonthWithZero = `${deadlineMonth}`;
      if (deadlineMonth && deadlineMonth < 10) {
        deadlineMonthWithZero = `0${deadlineMonth}`;
      }
      return {
        ...rest,
        deadlineDate: deadlineMonth && deadlineYear ? `${deadlineYear}-${deadlineMonthWithZero}-01` : null,
      } as EmployeeDreamUpdateRequest;
    });

    this.saveError.set(null);
    this.isSaving.set(true);
    this.isSuccessSaved.set(false);
    this.calcCounters();
    void this.dreamsApiService
      .batchUpdate({
        dreams,
      })
      .then(savedDreams => {
        this.isSaving.set(false);
        this.isSuccessSaved.set(true);
        this.dreamsResource.set(savedDreams);
        this.calcCounters();
      })
      .catch((error: HttpErrorResponse) => {
        this.saveError.set(error);
        this.isSaving.set(false);
      });
  }

  showHowToModal(): void {
    this.matDialog.open(DreamHowToComponent, {
      panelClass: 'modal-panel-rounded',
      width: '820px',
      minHeight: '300px',
      maxHeight: '95vh',
    });
  }

  isDreamDone(dream: EditableEmployeeDream): boolean {
    if (!dream.id) {
      return false;
    }
    return this.doneDreamIds.has(dream.id);
  }

  private calcCounters(): void {
    this.doneDreamIds = new Set(
      this.dreams()
        .filter(dream => dream.isDone)
        .map(dream => dream.id),
    );
    this.doneDreamsCount.set(this.doneDreamIds.size);
    this.notDoneDreamsCount.set(this.dreams().filter(dream => !dream.isDone).length);
  }
}
