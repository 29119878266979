import { select, Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import * as WhatsappContactsSelectors from './whatsapp-contacts.selectors';
import { WhatsappContactsState } from './whatsapp-contacts.state';
import { LoadInstance } from './whatsapp-contacts.actions';

@Injectable()
export class WhatsappContactsFacade {
  unreadChats$ = this.store.pipe(select(WhatsappContactsSelectors.getWhatsappUnreadChats));
  whatsappState$ = this.store.pipe(select(WhatsappContactsSelectors.getWhatsappState));
  qrLink$ = this.store.pipe(select(WhatsappContactsSelectors.getQrLink));

  constructor(private store: Store<WhatsappContactsState>) {}

  loadInstance() {
    this.store.dispatch(new LoadInstance());
  }
}
