import { DatePipe, DecimalPipe } from '@angular/common';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  provideHttpClient,
  withFetch,
  withInterceptorsFromDi,
} from '@angular/common/http';
import {
  APP_INITIALIZER,
  enableProdMode,
  ErrorHandler,
  importProvidersFrom,
  ImportProvidersSource,
  LOCALE_ID,
  provideExperimentalZonelessChangeDetection,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltip, MatTooltipDefaultOptions } from '@angular/material/tooltip';
import { bootstrapApplication, BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { EffectsModule } from '@ngrx/effects';
import { routerReducer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import * as Sentry from '@sentry/angular';
import { MetrikaModule } from 'ng-yandex-metrika';
import { CounterConfig as YaCounterConfig } from 'ng-yandex-metrika/lib/ng-yandex-metrika.config';
import { ClipboardModule } from 'ngx-clipboard';
import { NgxMaskModule } from 'ngx-mask';
import { NgxPermissionsModule } from 'ngx-permissions';
import { CallsFacade } from './app/+state/calls/calls.facade';
import { callsReducer } from './app/+state/calls/calls.reducers';
import { callInitialState } from './app/+state/calls/calls.state';
import { ChatMessagesEffects } from './app/+state/chat-messages/chat-messages.effects';
import { ChatMessagesFacade } from './app/+state/chat-messages/chat-messages.facade';
import { chatMessagesReducer } from './app/+state/chat-messages/chat-messages.reducers';
import { chatMessagesInitialState } from './app/+state/chat-messages/chat-messages.state';
import { ChatTimelineEffects } from './app/+state/chat-timeline/chat-timeline.effects';
import * as fromChatTimeline from './app/+state/chat-timeline/chat-timeline.reducer';
import { ChatEffects } from './app/+state/chats/chats.effects';
import { chatReducer, FEATURE_KEY } from './app/+state/chats/chats.reducer';
import { CurrentOutcomeCallFacade } from './app/+state/current-outcome-call/current-outcome-call.facade';
import { currentOutcomeCallReducer } from './app/+state/current-outcome-call/current-outcome-call.reducers';
import { currentOutcomeCallInitialState } from './app/+state/current-outcome-call/current-outcome-call.state';
import { DealsListFacade } from './app/+state/deals-list/deals-list.facade';
import { dealsListInitialState, dealsListReducer } from './app/+state/deals-list/deals-list.reducers';
import { DealsEffects } from './app/+state/deals/deals.effects';
import { DealsFacade } from './app/+state/deals/deals.facade';
import { dealsInitialState, dealsReducer } from './app/+state/deals/deals.reducers';
import { ManagerStateFacade } from './app/+state/manager-state/manager-state.facade';
import { managerStateReducer } from './app/+state/manager-state/manager-state.reducers';
import { managerSipInitialState } from './app/+state/manager-state/manager-state.state';
import { PopupFacade } from './app/+state/popup/popup.facade';
import { popupReducer } from './app/+state/popup/popup.reducers';
import { popupInitialState } from './app/+state/popup/popup.state';
import { RecommendationsEffects } from './app/+state/recommendations/recommendations.effects';
import { RecommendationsFacade } from './app/+state/recommendations/recommendations.facade';
import {
  recommendationsInitialState,
  recommendationsReducer,
} from './app/+state/recommendations/recommendations.redusers';
import { SettingsEffects } from './app/+state/settings/settings.effects';
import { SettingsFacade } from './app/+state/settings/settings.facade';
import { settingsReducer } from './app/+state/settings/settings.reducers';
import { settingsInitialState } from './app/+state/settings/settings.state';
import { WhatsappContactsEffects } from './app/+state/whatsapp-contacts/whatsapp-contacts.effects';
import { WhatsappContactsFacade } from './app/+state/whatsapp-contacts/whatsapp-contacts.facade';
import { whatsappContactsReducer } from './app/+state/whatsapp-contacts/whatsapp-contacts.reducers';
import { whatsappContactsInitialState } from './app/+state/whatsapp-contacts/whatsapp-contacts.state';
import { AppRoutingModule } from './app/app-routing.module';
import { AppComponent } from './app/app.component';
import {
  configServiceFactory,
  errorHandlerFactory,
  HttpLoaderFactory,
  maskConfigFunction,
  metaReducers,
} from './app/app.module';
import { CoreModule } from './app/core/core.module';
import { AppInitService } from './app/core/services';
import { ErrorInterceptor } from './app/interceptors/error.interceptor';
import { JwtInterceptor } from './app/interceptors/jwt.interceptor';
import { ChatsContainerTabService } from './app/modules/chats/container/components/chats-container-tab/chats-container-tabs-service';
import { SearchResultFiltersService } from './app/modules/deals/modules/deal-view/modules/search-tours/components/search-result/services/search-result-filters.service';
import { SearchToursProgressService } from './app/modules/deals/modules/deal-view/modules/search-tours/services/search-tours-progress.service';
import { CrmCardUpdateService } from './app/modules/deals/modules/deal-view/services/crm-card-update.service';
import { GoogleMapsLoaderService } from './app/modules/search/services/google-maps-loader.service';
import { SearchResultService } from './app/modules/search/services/search-result.service';
import { DbTourPackageService } from './app/shared/services/db-tour-package.service';
import { AppConfig } from './environments/environment';

const matTooltipDefaultOptions: MatTooltipDefaultOptions = {
  hideDelay: 100,
  touchendHideDelay: 1500,
  showDelay: 500,
};

const yaCounterConfig: YaCounterConfig = {
  id: 99123713,
};

if (AppConfig.environment === 'STAGE' || AppConfig.environment === 'PROD') {
  Sentry.init({
    dsn: 'https://51ad15dd7cf6e6f9e667a5505357a4ab@o4508012789694464.ingest.us.sentry.io/4508012792315904',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
      Sentry.captureConsoleIntegration({ levels: ['error', 'assert'] }),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: [/^https:\/\/gera-bff\.ht\.kz\/api/],
    replaysSessionSampleRate: 0.0,
    replaysOnErrorSampleRate: 1.0,
    release: '20250127-100835' || 'unknown',
    environment: AppConfig.environment,
  });

  enableProdMode();
}

const providersSources: ImportProvidersSource[] = [
  BrowserModule,
  BrowserAnimationsModule,
  FormsModule,
  ServiceWorkerModule.register('ngsw-worker.js', {
    enabled: AppConfig.environment === 'STAGE' || AppConfig.environment === 'PROD',
    registrationStrategy: 'registerWhenStable:30000',
  }),
  CoreModule,
  AppRoutingModule,
  NgxPermissionsModule.forRoot(),
  NgxMaskModule.forRoot(maskConfigFunction),
  EffectsModule.forRoot([]),
  StoreModule.forRoot({ router: routerReducer }, { metaReducers }),
  StoreRouterConnectingModule.forRoot(),
  StoreDevtoolsModule.instrument({ connectInZone: true }),
  StoreModule.forFeature('settings', settingsReducer, {
    initialState: settingsInitialState,
  }),
  StoreModule.forFeature('deals', dealsReducer, {
    initialState: dealsInitialState,
  }),
  StoreModule.forFeature('recommendations', recommendationsReducer, {
    initialState: recommendationsInitialState,
  }),
  StoreModule.forFeature('popup', popupReducer, {
    initialState: popupInitialState,
  }),
  StoreModule.forFeature('dealsList', dealsListReducer, {
    initialState: dealsListInitialState,
  }),
  StoreModule.forFeature('calls', callsReducer, {
    initialState: callInitialState,
  }),
  StoreModule.forFeature('managerSipState', managerStateReducer, {
    initialState: managerSipInitialState,
  }),
  EffectsModule.forFeature([DealsEffects]),
  EffectsModule.forFeature([RecommendationsEffects]),
  StoreModule.forFeature('current-outcome-call', currentOutcomeCallReducer, {
    initialState: currentOutcomeCallInitialState,
  }),
  EffectsModule.forFeature([SettingsEffects]),
  StoreModule.forFeature('chat-messages', chatMessagesReducer, {
    initialState: chatMessagesInitialState,
  }),
  StoreModule.forFeature('whatsapp-contacts', whatsappContactsReducer, {
    initialState: whatsappContactsInitialState,
  }),
  EffectsModule.forFeature([WhatsappContactsEffects]),
  StoreModule.forFeature(FEATURE_KEY, chatReducer),
  EffectsModule.forFeature([ChatEffects]),
  StoreModule.forFeature(fromChatTimeline.chatTimelineFeatureKey, fromChatTimeline.reducer),
  EffectsModule.forFeature([ChatTimelineEffects]),
  EffectsModule.forFeature([ChatMessagesEffects]),
  TranslateModule.forRoot({
    loader: {
      provide: TranslateLoader,
      useFactory: HttpLoaderFactory,
      deps: [HttpClient],
    },
  }),
  ClipboardModule,
  MatTooltip,
];

if (AppConfig.environment === 'PROD') {
  providersSources.push(MetrikaModule.forRoot(yaCounterConfig));
}

const providers = [
  importProvidersFrom(...providersSources),
  [DatePipe],
  { provide: LOCALE_ID, useValue: 'ru-RU' },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: JwtInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ErrorInterceptor,
    multi: true,
  },
  {
    provide: APP_INITIALIZER,
    useFactory: configServiceFactory,
    deps: [AppInitService],
    multi: true,
  },
  {
    provide: Sentry.TraceService,
    deps: [Router],
  },
  {
    provide: APP_INITIALIZER,
    useFactory: () => () => {},
    deps: [Sentry.TraceService],
    multi: true,
  },
  {
    provide: ErrorHandler,
    useFactory: errorHandlerFactory,
  },
  { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: matTooltipDefaultOptions },
  // Глобальные сервисы приложения
  RecommendationsFacade,
  CallsFacade,
  PopupFacade,
  ManagerStateFacade,
  SettingsFacade,
  SearchResultService,
  GoogleMapsLoaderService,
  SearchResultFiltersService,
  DbTourPackageService,
  ChatsContainerTabService,
  SearchToursProgressService,
  ChatMessagesFacade,
  WhatsappContactsFacade,
  DealsFacade,
  DealsListFacade,
  CrmCardUpdateService,
  ChatMessagesFacade,
  CurrentOutcomeCallFacade,
  DecimalPipe,
  provideHttpClient(withFetch(), withInterceptorsFromDi()),
  provideExperimentalZonelessChangeDetection(),
];

bootstrapApplication(AppComponent, { providers }).catch(err => console.error(err));
