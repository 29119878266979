import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { AppConfig } from '../../../../environments/environment';
import {
  GetEmployeeDreamMotivationResponse,
  DefaultResponse,
  EmployeeDreamMotivation,
} from '@api-clients/api-client/dist';

@Injectable({
  providedIn: 'root',
})
export class DreamMotivationsApiService {
  private readonly http = inject(HttpClient);
  private readonly baseUrl = AppConfig.commonApiUrl;

  list(dreamId: number): Promise<EmployeeDreamMotivation[]> {
    return firstValueFrom(
      this.http.get<EmployeeDreamMotivation[]>(
        `${this.baseUrl}/employee-dream-motivation/list?dreamId=${dreamId}`,
      ),
    );
  }

  getMotivation(): Promise<GetEmployeeDreamMotivationResponse> {
    return firstValueFrom(
      this.http.get<GetEmployeeDreamMotivationResponse>(
        `${this.baseUrl}/employee-dream-motivation/get-motivation`,
      ),
    );
  }

  markAsShown(motivationId: number): Promise<DefaultResponse> {
    return firstValueFrom(
      this.http.post<DefaultResponse>(`${this.baseUrl}/employee-dream-motivation/mark-as-shown`, {
        id: motivationId,
      }),
    );
  }
}
