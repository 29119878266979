import { inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HotelTagWithPriority, PostFilterPrices } from '../../../search-tours.model';
import { SearchHotelTagsService } from '../../../services/search-hotel-tags.service';

@Injectable()
export class SearchResultFiltersService {
  private readonly searchHotelTagsService = inject(SearchHotelTagsService);

  private hasFiltersSub = new BehaviorSubject<boolean>(false);
  private defaultPricesValue = { from: undefined, to: undefined };
  private filterHotelStarsListSub = new BehaviorSubject<string[]>([]);
  private filterHotelRegionsListSub = new BehaviorSubject<string[]>([]);
  private filterHotelRatingListSub = new BehaviorSubject<number[]>([]);
  private filterPricesSub = new BehaviorSubject<PostFilterPrices>(this.defaultPricesValue);
  private filterTagsSub = new BehaviorSubject<Map<number, HotelTagWithPriority[]>>(
    new Map<number, HotelTagWithPriority[]>(),
  );
  private filterTagsIdsSub = new BehaviorSubject<number[]>([]);

  filterHotelStarsList$ = this.filterHotelStarsListSub.asObservable();
  filterHotelRegionsList$ = this.filterHotelRegionsListSub.asObservable();
  filterHotelRatingList$ = this.filterHotelRatingListSub.asObservable();
  filterPrices$ = this.filterPricesSub.asObservable();
  filterTags$ = this.filterTagsSub.asObservable();
  filterTagsIds$ = this.filterTagsIdsSub.asObservable();
  hasFilters$ = this.hasFiltersSub.asObservable();

  flushFilters(): void {
    this.filterHotelStarsListSub.next([]);
    this.filterHotelRatingListSub.next([]);
    this.filterHotelRegionsListSub.next([]);
    this.filterTagsSub.next(new Map<number, HotelTagWithPriority[]>());
    this.filterTagsIdsSub.next([]);
    this.filterPricesSub.next(this.defaultPricesValue);
    this.hasFiltersSub.next(false);
    this.searchHotelTagsService.flushTagsByHotels();
  }

  applyHotelRegionsFilter(value: string[]): void {
    this.filterHotelRegionsListSub.next(value);
    this.calcHasFilters();
  }

  applyHotelStarsFilter(value: string[]): void {
    this.filterHotelStarsListSub.next(value);
    this.calcHasFilters();
  }

  applyHotelRatingFilter(value: number[]): void {
    this.filterHotelRatingListSub.next(value);
    this.calcHasFilters();
  }

  applyPricesFilter(value: PostFilterPrices): void {
    this.filterPricesSub.next(value);
    this.calcHasFilters();
  }

  applyTags(ids: number[], tags: Map<number, HotelTagWithPriority[]>) {
    this.filterTagsSub.next(tags);
    this.filterTagsIdsSub.next(ids);
    this.calcHasFilters();
  }

  private calcHasFilters(): void {
    const hasFilters =
      !!this.filterTagsSub.value.size ||
      !!this.filterHotelRatingListSub.value.length ||
      !!this.filterHotelStarsListSub.value.length ||
      !!this.filterHotelRegionsListSub.value.length ||
      !!this.filterPricesSub.value.to ||
      !!this.filterPricesSub.value.from;

    this.hasFiltersSub.next(hasFilters);
  }
}
