import { NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  inject,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SipStateListEvent } from '@api-clients/crm-api-client';
import { TranslateModule } from '@ngx-translate/core';
import { BehaviorSubject, startWith, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { WorkerStateService } from '../../../../core/services';
import { AmplitudeTrackService } from '../../../../core/services/amplitude/amplitude-track.service';
import { ScreenTypes } from '../../../../core/services/amplitude/amplitudeEventData';
import { DEAL_VIEW_OPENED } from '../../../../core/services/amplitude/amplitudeEvents';
import { Worker } from '../../../../models';
import { HighlightPipe } from '../../../../shared/pipes/highlight.pipe';
import { FeatureId } from '../../../../shared/services/feature-id.enum';
import { IntroManagementService } from '../../../../shared/services/intro-management.service';
import { CloseModalButtonComponent } from '../../../../ui-components/close-modal-button/close-modal-button.component';
import { BrxInputSearchComponent } from '../../../../ui-components/brx/input-search/brx-input-search.component';
import { BrxLoaderFullscreenComponent } from '../../../../ui-components/brx/loader-fullscreen/brx-loader-fullscreen.component';
import { WorkerNameComponent } from './components/worker-name/worker-name.component';
import { WorkersListService } from './services/workers-list.service';

const CALL_CENTER = 'call_operator';

interface GroupedWorkerList {
  offices: { [key: string]: Worker[] };
  callCenter: Worker[];
}

@Component({
  selector: 'app-workers-list',
  templateUrl: './workers-list.component.html',
  styleUrls: ['./workers-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  imports: [
    TranslateModule,
    WorkerNameComponent,
    BrxLoaderFullscreenComponent,
    HighlightPipe,
    NgIf,
    BrxInputSearchComponent,
    CloseModalButtonComponent,
  ],
})
export class WorkersListComponent implements OnInit {
  public groupedByOffice: GroupedWorkerList;
  public officeList: string[] = [];
  public workersList$: Subscription;
  public screenType = ScreenTypes.WORKERS_LIST;

  private originalGroupedByOffice: GroupedWorkerList;
  private searchQuery$ = new BehaviorSubject<string>('');

  currentQuery = toSignal(this.searchQuery$);

  private destroyRef = inject(DestroyRef);

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<WorkersListComponent>,
    public workersListService: WorkersListService,
    private cdRef: ChangeDetectorRef,
    private workerStateService: WorkerStateService,
    private amplitudeTrackService: AmplitudeTrackService,
    private introManagementService: IntroManagementService,
  ) {}

  ngOnInit(): void {
    const currentWorker = this.workerStateService.currentWorkerValue;

    this.workersListService
      .fetchWorkersList(currentWorker.cityId)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(workersList => {
        this.workersListService.sipState$
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe((sipStates: SipStateListEvent) => {
            if (sipStates) {
              void this.updateSipStates(sipStates, workersList).then(() => {});
            } else {
              // Если вы не подключены к prod сокету, это норма!
              console.warn('sipStates is null. check ws calls.getCurrentSipState()');
            }
          });

        this.groupedByOffice = this.groupWorkersByOffice(workersList);
        this.originalGroupedByOffice = JSON.parse(JSON.stringify(this.groupedByOffice));

        this.updateOfficeList();

        this.cdRef.detectChanges();

        this.introManagementService.queueIntro(FeatureId.WORKER_LIST_CARD, false, [], null);
      });

    this.searchQuery$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        startWith(''),
        debounceTime(300),
        distinctUntilChanged(),
        map(query => query.toLowerCase()),
      )
      .subscribe(query => this.filterWorkers(query));
  }

  public onSearchQueryChange(query: string): void {
    this.searchQuery$.next(query);
  }

  updateSipStates(sipStates: SipStateListEvent, workersList: Worker[]): Promise<any> {
    return new Promise<void>(resolve => {
      workersList.forEach((worker: Worker) => {
        sipStates.list.forEach(workerState => {
          if (workerState.sip === worker.sip.innerPhone) {
            worker.state = workerState.state;
          }
        });
      });

      this.cdRef.detectChanges();
      resolve();
    });
  }

  private groupWorkersByOffice(workersList: Worker[]): GroupedWorkerList {
    return workersList.reduce(
      (result, currentValue) => {
        if (currentValue.role === CALL_CENTER) {
          result.callCenter.push(currentValue);
        } else {
          (result.offices[currentValue.office.name] = result.offices[currentValue.office.name] || []).push(
            currentValue,
          );
        }
        return result;
      },
      { offices: {}, callCenter: [] } as GroupedWorkerList,
    );
  }

  private updateOfficeList(): void {
    this.officeList = Object.keys(this.groupedByOffice.offices).filter(office => {
      return this.groupedByOffice.offices[office].length > 0;
    });
  }

  private filterWorkers(query: string): void {
    if (!this.originalGroupedByOffice) {
      return;
    }

    if (query.trim() === '') {
      this.groupedByOffice = JSON.parse(JSON.stringify(this.originalGroupedByOffice));
      this.updateOfficeList();
      this.cdRef.detectChanges();
      return;
    }

    const filteredList: GroupedWorkerList = { offices: {}, callCenter: [] };
    query = query.toLowerCase();
    const containsQuery = (str: string) => str.toLowerCase().includes(query);

    // Фильтрация по офисам
    Object.keys(this.originalGroupedByOffice.offices).forEach(office => {
      const workers = this.originalGroupedByOffice.offices[office];
      const filteredWorkers = workers.filter(
        worker => containsQuery(worker.name) || containsQuery(worker.sip.innerPhone.toString()),
      );
      if (filteredWorkers.length > 0) {
        filteredList.offices[office] = filteredWorkers;
      }
    });

    // Фильтрация для callCenter
    filteredList.callCenter = this.originalGroupedByOffice.callCenter.filter(
      worker => containsQuery(worker.name) || containsQuery(worker.sip.innerPhone.toString()),
    );

    this.groupedByOffice = filteredList;
    this.updateOfficeList();
    this.cdRef.detectChanges();
  }

  public closeModal(): void {
    this.dialogRef.close();
  }

  public cardOpened(): void {
    this.amplitudeTrackService.trackEvent(DEAL_VIEW_OPENED, {
      isManagerCard: true,
      screenType: this.screenType,
    });
    this.closeModal();
  }

  public getFormattedManagerSip(sip: number): string {
    return `(${sip})`;
  }
}
