<div class="section-switcher">
  <div
    class="section-label"
    [class.clickable]="!currentStageChatListFilterIsAll()"
    [class.active]="currentStageChatListFilterIsAll()"
    (click)="resetStageFilter()"
  >
    @if (hasUnread()) {
      <span class="marker-unread in-section"></span>
    }
    <img width="24" height="24" src="assets/icons/aside-menu/chats-icon.svg" alt="" srcset="" />
    <span class="section-caption">{{ 'LAYOUT.ASIDE.CHATS' | translate }} </span>
  </div>
</div>

<ng-template #itemLink let-stageKey="stageKey">
  @let counter = getConditionalCounter(stageKey);
  <a
    (click)="setStageFilter(stageKey)"
    class="chat-filter"
    [class.active]="isActiveStage(stageKey)"
    [class.muted]="counter.total === 0"
    [attr.data-stage]="stageKey"
    matTooltip="{{ 'LAYOUT.ASIDE.CHATS_FILTERS.' + stageKey | translate }}"
    matTooltipPosition="right"
  >
    @if (counter && counter.total > 0) {
      <span class="stage-counter">
        {{ counter.total }}
      </span>
      @if (hasUnreadMarker(counter)) {
        <span class="marker-unread"></span>
      }
    }
  </a>
</ng-template>
<ng-template #itemSubLink let-stageKey="stageKey" let-stageLabel="stageLabel">
  @let counter = getConditionalCounter(stageKey);
  <a
    (click)="setStageFilter(stageKey)"
    class="days-stage"
    [class.active]="isActiveStage(stageKey)"
    [attr.data-stage]="stageKey"
    matTooltip="{{ 'LAYOUT.ASIDE.CHATS_FILTERS.' + stageKey | translate }}"
    matTooltipPosition="right"
  >
    <span class="days-stage-label">{{ stageLabel }}</span>
    @if (counter && counter.total > 0) {
      <span class="stage-counter">
        {{ counter.total }}
      </span>
      @if (hasUnreadMarker(counter)) {
        <span class="marker-unread in-submenu"></span>
      }
    }
  </a>
</ng-template>

<div class="aside-chats-filters">
  <div class="aside-chats-filters__item">
    <div class="deal-top-stages-container">
      <ng-container
        [ngTemplateOutlet]="itemLink"
        [ngTemplateOutletContext]="{ stageKey: chatListStageFilterKeys.MISSED_AND_URGENT }"
      />
      <ng-container
        [ngTemplateOutlet]="itemLink"
        [ngTemplateOutletContext]="{ stageKey: chatListStageFilterKeys.NEW }"
      />
    </div>
    <div class="stage-with-subfilters" [class.opened]="isDaysFiltersOpened()">
      <ng-container
        [ngTemplateOutlet]="itemLink"
        [ngTemplateOutletContext]="{ stageKey: chatListStageFilterKeys.IN_PROGRESS }"
      />
      @if (isDaysFiltersOpened()) {
        <div class="days-filters">
          <ng-container
            [ngTemplateOutlet]="itemSubLink"
            [ngTemplateOutletContext]="{ stageKey: chatListStageFilterKeys.FIRST_DAY, stageLabel: '1 д' }"
          />
          <ng-container
            [ngTemplateOutlet]="itemSubLink"
            [ngTemplateOutletContext]="{ stageKey: chatListStageFilterKeys.SECOND_DAY, stageLabel: '2 д' }"
          />
          <ng-container
            [ngTemplateOutlet]="itemSubLink"
            [ngTemplateOutletContext]="{ stageKey: chatListStageFilterKeys.THIRD_DAY, stageLabel: '3 д' }"
          />
          <ng-container
            [ngTemplateOutlet]="itemSubLink"
            [ngTemplateOutletContext]="{
              stageKey: chatListStageFilterKeys.FOURTH_TO_SEVENTH_DAYS,
              stageLabel: '4-7 д',
            }"
          />
          <ng-container
            [ngTemplateOutlet]="itemSubLink"
            [ngTemplateOutletContext]="{
              stageKey: chatListStageFilterKeys.MORE_THAN_SEVEN_DAYS,
              stageLabel: '> 7 д',
            }"
          />
        </div>
      }
    </div>
  </div>
  <div class="aside-chats-filters__item aside-chats-filters__item--bottom">
    <div class="aside-chats-filters__item">
      <ng-container
        [ngTemplateOutlet]="itemLink"
        [ngTemplateOutletContext]="{ stageKey: chatListStageFilterKeys.MEETING }"
      />
      <ng-container
        [ngTemplateOutlet]="itemLink"
        [ngTemplateOutletContext]="{ stageKey: chatListStageFilterKeys.READY_FOR_BOOKING }"
      />
      <ng-container
        [ngTemplateOutlet]="itemLink"
        [ngTemplateOutletContext]="{ stageKey: chatListStageFilterKeys.POSTPONED }"
      />
    </div>
    <div class="aside-chats-filters__item">
      <ng-container
        [ngTemplateOutlet]="itemLink"
        [ngTemplateOutletContext]="{ stageKey: chatListStageFilterKeys.COLD_TOUCH }"
      />
      <ng-container
        [ngTemplateOutlet]="itemLink"
        [ngTemplateOutletContext]="{ stageKey: chatListStageFilterKeys.POST_SALE }"
      />
      <!-- Footer -->
      <div class="add-deal-container">
        <button type="button" class="add-deal-button" (click)="showAddDealPopup()">
          <img src="assets/icons/plus-bold-blue.svg" width="16" height="16" alt="" class="add-deal-icon" />
        </button>
      </div>
      <!-- Footer End -->
    </div>
  </div>
</div>
<!--<div class="aside-chats-filters">-->
<!--  <div class="aside-chats-filters__item">-->
<!--    <div class="deal-top-stages-container">-->
<!--      <ng-container [ngTemplateOutlet]="itemLink"-->
<!--                    [ngTemplateOutletContext]="{stageKey: chatListStageFilterKeys.MISSED_AND_URGENT}"/>-->
<!--      @if (counterUrgent()) {-->
<!--        <a-->
<!--          (click)="setStageFilter(chatListStageFilterKeys.MISSED_AND_URGENT)"-->
<!--          class="chat-filter missed-and-urgent"-->
<!--          [class.active]="isActiveStage(chatListStageFilterKeys.MISSED_AND_URGENT)"-->
<!--          [class.muted]="counterUrgent().total === 0"-->
<!--          matTooltip="{{-->
<!--            'LAYOUT.ASIDE.CHATS_FILTERS.' + chatListStageFilterKeys.MISSED_AND_URGENT | translate-->
<!--          }}"-->
<!--          matTooltipPosition="right"-->
<!--        >-->
<!--          @if (counter.total > 0) {-->
<!--            <span class="stage-counter">-->
<!--              {{ counter.total }}-->
<!--            </span>-->
<!--            @if (counter.unread) {-->
<!--              <span class="marker-unread"></span>-->
<!--            }-->
<!--          }-->
<!--        </a>-->
<!--      }-->
<!--      @if (counterNew()) {-->
<!--        <a-->
<!--          (click)="setStageFilter(chatListStageFilterKeys.NEW)"-->
<!--          class="chat-filter new"-->
<!--          [class.active]="isActiveStage(chatListStageFilterKeys.NEW)"-->
<!--          [class.muted]="counterNew().total === 0"-->
<!--          matTooltip="{{ 'LAYOUT.ASIDE.CHATS_FILTERS.' + chatListStageFilterKeys.NEW | translate }}"-->
<!--          matTooltipPosition="right"-->
<!--        >-->
<!--          @if (counter.total > 0) {-->
<!--            <span class="stage-counter">-->
<!--              {{ counter.total }}-->
<!--            </span>-->
<!--            @if (counter.unread || counter.hasUnreadDealChanges) {-->
<!--              <span class="marker-unread"></span>-->
<!--            }-->
<!--          }-->

<!--          <ng-container *ngTemplateOutlet="itemCounter; context: { $implicit: counterNew() }" />-->
<!--        </a>-->
<!--      }-->
<!--    </div>-->
<!--    <div class="stage-with-subfilters" [class.opened]="isDaysFiltersOpened()">-->
<!--      <a-->
<!--        (click)="setStageFilter(chatListStageFilterKeys.IN_PROGRESS)"-->
<!--        class="chat-filter work-in-progress"-->
<!--        [class.active]="isActiveStage(chatListStageFilterKeys.IN_PROGRESS)"-->
<!--        matTooltip="{{ 'LAYOUT.ASIDE.CHATS_FILTERS.' + chatListStageFilterKeys.IN_PROGRESS | translate }}"-->
<!--        matTooltipPosition="right"-->
<!--      >-->
<!--        @if (getStageCounterBy$(chatListStageFilterKeys.IN_PROGRESS) | async; as counter) {-->
<!--          @if (counter.total > 0) {-->
<!--            <span class="stage-counter">-->
<!--              {{ counter.total }}-->
<!--            </span>-->
<!--            @if (counter.unread) {-->
<!--              <span class="marker-unread"></span>-->
<!--            }-->
<!--          }-->
<!--        }-->
<!--      </a>-->
<!--      @if (isDaysFiltersOpened()) {-->
<!--        <div class="days-filters">-->
<!--          <a-->
<!--            (click)="setStageFilter(chatListStageFilterKeys.FIRST_DAY)"-->
<!--            class="days-stage"-->
<!--            [class.active]="isActiveStage(chatListStageFilterKeys.FIRST_DAY)"-->
<!--            matTooltip="{{ 'LAYOUT.ASIDE.CHATS_FILTERS.' + chatListStageFilterKeys.FIRST_DAY | translate }}"-->
<!--            matTooltipPosition="right"-->
<!--          >-->
<!--            <span class="days-stage-label">1 д</span>-->
<!--            @if (getStageCounterBy$(chatListStageFilterKeys.FIRST_DAY) | async; as counter) {-->
<!--              @if (counter.total > 0) {-->
<!--                <span class="stage-counter">-->
<!--                  {{ counter.total }}-->
<!--                </span>-->
<!--                @if (counter.unread) {-->
<!--                  <span class="marker-unread in-submenu"></span>-->
<!--                }-->
<!--              }-->
<!--            }-->
<!--          </a>-->
<!--          <a-->
<!--            (click)="setStageFilter(chatListStageFilterKeys.SECOND_DAY)"-->
<!--            class="days-stage"-->
<!--            [class.active]="isActiveStage(chatListStageFilterKeys.SECOND_DAY)"-->
<!--            matTooltip="{{ 'LAYOUT.ASIDE.CHATS_FILTERS.' + chatListStageFilterKeys.SECOND_DAY | translate }}"-->
<!--            matTooltipPosition="right"-->
<!--          >-->
<!--            <span class="days-stage-label">2 д</span>-->
<!--            @if (getStageCounterBy$(chatListStageFilterKeys.SECOND_DAY) | async; as counter) {-->
<!--              @if (counter.total > 0) {-->
<!--                <span class="stage-counter">-->
<!--                  {{ counter.total }}-->
<!--                </span>-->
<!--                @if (counter.unread) {-->
<!--                  <span class="marker-unread in-submenu"></span>-->
<!--                }-->
<!--              }-->
<!--            }-->
<!--          </a>-->
<!--          <a-->
<!--            (click)="setStageFilter(chatListStageFilterKeys.THIRD_DAY)"-->
<!--            class="days-stage"-->
<!--            [class.active]="isActiveStage(chatListStageFilterKeys.THIRD_DAY)"-->
<!--            matTooltip="{{ 'LAYOUT.ASIDE.CHATS_FILTERS.' + chatListStageFilterKeys.THIRD_DAY | translate }}"-->
<!--            matTooltipPosition="right"-->
<!--          >-->
<!--            <span class="days-stage-label">3 д</span>-->
<!--            @if (getStageCounterBy$(chatListStageFilterKeys.THIRD_DAY) | async; as counter) {-->
<!--              @if (counter.total > 0) {-->
<!--                <span class="stage-counter">-->
<!--                  {{ counter.total }}-->
<!--                </span>-->
<!--                @if (counter.unread) {-->
<!--                  <span class="marker-unread in-submenu"></span>-->
<!--                }-->
<!--              }-->
<!--            }-->
<!--          </a>-->
<!--          <a-->
<!--            (click)="setStageFilter(chatListStageFilterKeys.FOURTH_TO_SEVENTH_DAYS)"-->
<!--            class="days-stage"-->
<!--            [class.active]="isActiveStage(chatListStageFilterKeys.FOURTH_TO_SEVENTH_DAYS)"-->
<!--            matTooltip="{{-->
<!--              'LAYOUT.ASIDE.CHATS_FILTERS.' + chatListStageFilterKeys.FOURTH_TO_SEVENTH_DAYS | translate-->
<!--            }}"-->
<!--            matTooltipPosition="right"-->
<!--          >-->
<!--            <span class="days-stage-label">4-7 д</span>-->
<!--            @if (getStageCounterBy$(chatListStageFilterKeys.FOURTH_TO_SEVENTH_DAYS) | async; as counter) {-->
<!--              @if (counter.total > 0) {-->
<!--                <span class="stage-counter">-->
<!--                  {{ counter.total }}-->
<!--                </span>-->
<!--                @if (counter.unread) {-->
<!--                  <span class="marker-unread in-submenu"></span>-->
<!--                }-->
<!--              }-->
<!--            }-->
<!--          </a>-->
<!--          <a-->
<!--            (click)="setStageFilter(chatListStageFilterKeys.MORE_THAN_SEVEN_DAYS)"-->
<!--            class="days-stage"-->
<!--            [class.active]="isActiveStage(chatListStageFilterKeys.FOURTH_TO_SEVENTH_DAYS)"-->
<!--            matTooltip="{{-->
<!--              'LAYOUT.ASIDE.CHATS_FILTERS.' + chatListStageFilterKeys.MORE_THAN_SEVEN_DAYS | translate-->
<!--            }}"-->
<!--            matTooltipPosition="right"-->
<!--          >-->
<!--            <span class="days-stage-label">> 7 д</span>-->
<!--            @if (getStageCounterBy$(chatListStageFilterKeys.MORE_THAN_SEVEN_DAYS) | async; as counter) {-->
<!--              @if (counter.total > 0) {-->
<!--                <span class="stage-counter">-->
<!--                  {{ counter.total }}-->
<!--                </span>-->
<!--                @if (counter.unread) {-->
<!--                  <span class="marker-unread in-submenu"></span>-->
<!--                }-->
<!--              }-->
<!--            }-->
<!--          </a>-->
<!--        </div>-->
<!--      }-->
<!--    </div>-->
<!--  </div>-->
<!--  <div class="aside-chats-filters__item aside-chats-filters__item&#45;&#45;bottom">-->
<!--    <div class="aside-chats-filters__item">-->
<!--      <a-->
<!--        (click)="setStageFilter(chatListStageFilterKeys.MEETING)"-->
<!--        class="chat-filter meeting"-->
<!--        [class.active]="isActiveStage(chatListStageFilterKeys.MEETING)"-->
<!--        matTooltip="{{ 'LAYOUT.ASIDE.CHATS_FILTERS.' + chatListStageFilterKeys.MEETING | translate }}"-->
<!--        matTooltipPosition="right"-->
<!--      >-->
<!--        @if (getStageCounterBy$(chatListStageFilterKeys.MEETING) | async; as counter) {-->
<!--          @if (counter.total > 0) {-->
<!--            <span class="stage-counter">-->
<!--              {{ counter.total }}-->
<!--            </span>-->
<!--            @if (counter.unread) {-->
<!--              <span class="marker-unread"></span>-->
<!--            }-->
<!--          }-->
<!--        }-->
<!--      </a>-->
<!--      <a-->
<!--        (click)="setStageFilter(chatListStageFilterKeys.READY_FOR_BOOKING)"-->
<!--        class="chat-filter ready-for-booking"-->
<!--        [class.active]="isActiveStage(chatListStageFilterKeys.READY_FOR_BOOKING)"-->
<!--        matTooltip="{{-->
<!--          'LAYOUT.ASIDE.CHATS_FILTERS.' + chatListStageFilterKeys.READY_FOR_BOOKING | translate-->
<!--        }}"-->
<!--        matTooltipPosition="right"-->
<!--      >-->
<!--        @if (getStageCounterBy$(chatListStageFilterKeys.READY_FOR_BOOKING) | async; as counter) {-->
<!--          @if (counter.total > 0) {-->
<!--            <span class="stage-counter">-->
<!--              {{ counter.total }}-->
<!--            </span>-->
<!--            @if (counter.unread) {-->
<!--              <span class="marker-unread"></span>-->
<!--            }-->
<!--          }-->
<!--        }-->
<!--      </a>-->
<!--      <a-->
<!--        (click)="setStageFilter(chatListStageFilterKeys.POSTPONED)"-->
<!--        class="chat-filter postponed"-->
<!--        [class.active]="currentStageChatListFilter() === chatListStageFilterKeys.POSTPONED"-->
<!--        matTooltip="{{ 'LAYOUT.ASIDE.CHATS_FILTERS.' + chatListStageFilterKeys.POSTPONED | translate }}"-->
<!--        matTooltipPosition="right"-->
<!--      >-->
<!--        @if (getStageCounterBy$(chatListStageFilterKeys.POSTPONED) | async; as counter) {-->
<!--          @if (counter.total > 0) {-->
<!--            <span class="stage-counter">-->
<!--              {{ counter.total }}-->
<!--            </span>-->
<!--            @if (counter.unread) {-->
<!--              <span class="marker-unread"></span>-->
<!--            }-->
<!--          }-->
<!--        }-->
<!--      </a>-->
<!--    </div>-->
<!--    <div class="aside-chats-filters__item">-->
<!--      <a-->
<!--        (click)="setStageFilter(chatListStageFilterKeys.COLD_TOUCH)"-->
<!--        class="chat-filter cold-touch"-->
<!--        [class.active]="currentStageChatListFilter() === chatListStageFilterKeys.COLD_TOUCH"-->
<!--        matTooltip="{{ 'LAYOUT.ASIDE.CHATS_FILTERS.' + chatListStageFilterKeys.COLD_TOUCH | translate }}"-->
<!--        matTooltipPosition="right"-->
<!--      >-->
<!--        @if (getStageCounterBy$(chatListStageFilterKeys.COLD_TOUCH) | async; as counter) {-->
<!--          @if (counter.total > 0) {-->
<!--            <span class="stage-counter">-->
<!--              {{ counter.total }}-->
<!--            </span>-->
<!--            @if (counter.unread) {-->
<!--              <span class="marker-unread"></span>-->
<!--            }-->
<!--          }-->
<!--        }-->
<!--      </a>-->
<!--      <a-->
<!--        (click)="setStageFilter(chatListStageFilterKeys.POST_SALE)"-->
<!--        class="chat-filter post-sale"-->
<!--        [class.active]="currentStageChatListFilter() === chatListStageFilterKeys.POST_SALE"-->
<!--        matTooltip="{{ 'LAYOUT.ASIDE.CHATS_FILTERS.' + chatListStageFilterKeys.POST_SALE | translate }}"-->
<!--        matTooltipPosition="right"-->
<!--      >-->
<!--        @if (getStageCounterBy$(chatListStageFilterKeys.POST_SALE) | async; as counter) {-->
<!--          @if (counter.total > 0) {-->
<!--            <span class="stage-counter">-->
<!--              {{ counter.total }}-->
<!--            </span>-->
<!--            @if (counter.unread) {-->
<!--              <span class="marker-unread"></span>-->
<!--            }-->
<!--          }-->
<!--        }-->
<!--      </a>-->
<!--      &lt;!&ndash; Footer &ndash;&gt;-->
<!--      <div class="add-deal-container">-->
<!--        <button type="button" class="add-deal-button" (click)="showAddDealPopup()">-->
<!--          <img src="assets/icons/plus-bold-blue.svg" width="16" height="16" alt="" class="add-deal-icon" />-->
<!--        </button>-->
<!--      </div>-->
<!--      &lt;!&ndash; Footer End &ndash;&gt;-->
<!--    </div>-->
<!--  </div>-->
<!--</div-->
