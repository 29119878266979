import { AsyncPipe, NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, inject, OnInit, signal } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { MatTooltip } from '@angular/material/tooltip';
import { Router, RouterLink } from '@angular/router';
import { CrmCardViewItem } from '@api-clients/crm-api-client/dist/models';
import { TranslateModule } from '@ngx-translate/core';
import { ConfigsService } from '../../../../core/services';
import { AmplitudeTrackService } from '../../../../core/services/amplitude/amplitude-track.service';
import { ScreenTypes } from '../../../../core/services/amplitude/amplitudeEventData';
import {
  MANAGERS_LIST_OPEN,
  NOTIFICATIONS_CENTER_OPEN,
  TOUR_DB_OPENED,
} from '../../../../core/services/amplitude/amplitudeEvents';
import { WebsocketHelperService } from '../../../../core/services/websocket/helper/websocket-helper.service';
import { ExternalLinkDirective } from '../../../../shared/directives/external-link.directive';
import { FeatureToggleService } from '../../../deals/modules/deals-list/modules/deals-list-content/helpers/feature-toggle.service';
import { NotificationsCenterPopupService } from '../../../notifications-center/services/notifications-center-popup.service';
import { CommissionComponent } from '../commission/commission.component';
import { NotificationsCenterCountComponent } from '../notifications-center-count/notifications-center-count.component';
import { RightSideMenuPopupService } from '../right-side-menu/services/right-side-menu-popup.service';
import { SearchFormComponent } from '../search-form/search-form.component';
import { WorkerStatusComponent } from '../worker-status/worker-status.component';
import { WorkersListComponent } from '../workers-list/workers-list.component';
import { CallingIslandComponent } from './calling/calling-island.component';
import { NetworkStatusComponent } from './network-status/network-status.component';

@Component({
  selector: 'app-top-menu',
  templateUrl: './top-menu.component.html',
  styleUrls: ['./top-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AsyncPipe,
    TranslateModule,
    NetworkStatusComponent,
    SearchFormComponent,
    CommissionComponent,
    WorkerStatusComponent,
    NotificationsCenterCountComponent,
    NgClass,
    ExternalLinkDirective,
    RouterLink,
    MatTooltip,
    CallingIslandComponent,
  ],
})
export class TopMenuComponent implements OnInit {
  private readonly websocketHelper = inject(WebsocketHelperService);

  public adminUrl: string;
  public readonly screenType: ScreenTypes = ScreenTypes.TOP_MENU;
  public popupOpen$ = this.rightSideMenuPopupService.popupOpen$;

  public isLogoLoaded = signal(false);

  public isChatsRoute = signal(false);
  public isDealsRoute = signal(false);

  isDevVersion = false;

  isChatsEnabled = toSignal(this.featureToggleService.shouldDisplayChats$);
  showCallIsland = signal<boolean>(false);
  currentCallCrmCardItem = signal<CrmCardViewItem>(undefined);

  constructor(
    public matDialog: MatDialog,
    private configsService: ConfigsService,
    private amplitudeTrackService: AmplitudeTrackService,
    private notificationsCenterPopupService: NotificationsCenterPopupService,
    private rightSideMenuPopupService: RightSideMenuPopupService,
    private featureToggleService: FeatureToggleService,
    private router: Router,
    private destroyRef: DestroyRef,
  ) {
    this.adminUrl = this.configsService.adminUrl;
  }

  ngOnInit(): void {
    this.router.events.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      const url = this.router.routerState.snapshot.url;
      this.isDealsRoute.set(url.startsWith('/deals'));
      this.isChatsRoute.set(url.startsWith('/chats'));
    });

    this.isDevVersion = window.location.toString().includes('stage-gera');

    this.websocketHelper.callsOnStartConversation$.subscribe(crmCardViewItem => {
      if (crmCardViewItem?.card) {
        this.currentCallCrmCardItem.set(crmCardViewItem);
      }
      this.showCallIsland.set(true);
    });
    this.websocketHelper.callsOnEndConversation$.subscribe(() => {
      this.showCallIsland.set(false);
      this.currentCallCrmCardItem.set(undefined);
    });
    this.websocketHelper.callOnEndCall$.subscribe(() => {
      this.showCallIsland.set(false);
      this.currentCallCrmCardItem.set(undefined);
    });
    this.websocketHelper.callOnUpdateData$.subscribe(crmCardViewItem => {
      this.currentCallCrmCardItem.set(crmCardViewItem);
    });
  }

  closeCallIsland(): void {
    this.showCallIsland.set(false);
    this.currentCallCrmCardItem.set(undefined);
  }

  showManagersList(): void {
    this.amplitudeTrackService.trackEvent(MANAGERS_LIST_OPEN);
    this.matDialog.open(WorkersListComponent, {
      id: 'workers-list-component',
      disableClose: false,
      hasBackdrop: true,
      width: '100%',
      maxWidth: 'calc(100vw-90px)',
      height: 'calc(100vh - 100px)',
      maxHeight: '100%',
      position: { top: '80px', left: '105px' },
    });
  }

  showNotificationsPopup() {
    this.notificationsCenterPopupService.showPopup();
    this.amplitudeTrackService.trackEvent(NOTIFICATIONS_CENTER_OPEN, {
      screenType: this.screenType,
    });
  }

  toggleMenu() {
    this.rightSideMenuPopupService.toggleMenu();
  }

  tourDBOpened() {
    this.amplitudeTrackService.trackEvent(TOUR_DB_OPENED, {
      screenType: this.screenType,
    });
  }

  changeVersionToProduction() {
    (window as any).location = 'https://gera.ht.kz';
  }
}
