import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppConfig } from '../../environments/environment';
import { AuthService } from '../core/services';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((err: HttpErrorResponse) => {
        if (
          req.url.startsWith(AppConfig.andromedaApiUrl) ||
          req.url.startsWith(AppConfig.crmApiUrl) ||
          req.url.startsWith(AppConfig.commonApiUrl)
        ) {
          return throwError(() => err);
        }

        if (err.status === 401 || err.status === 403) {
          this.authenticationService.logout();
          location.reload();
        }

        const error = err?.error || err.error.message || err.statusText;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return throwError(() => error);
      }),
    );
  }
}
