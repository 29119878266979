import { DatePipe, NgStyle } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  computed,
  DestroyRef,
  effect,
  EventEmitter,
  inject,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
  signal,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { HotelReview } from '@api-clients/api-client';
import { TourContent } from '@api-clients/api-client/models/tour-content';
import { Observable, of } from 'rxjs';
import { PluralizePipe } from '../../../../../helpers/pipes/plural/pluralize.pipe';
import { ResizeObserverDirective } from '../../../../../shared/directives/resize-observer.directive';
import { TextSelectionDirective } from '../../../../../shared/directives/text-selection.directive';
import { SafeHtmlPipe } from '../../../../../shared/pipes/safe-html.pipe';
import { HtmlSanitizerService } from '../../../../../shared/services/html-sanitizer.service';
import {
  AlertLabelComponent,
  AlertLabelType,
} from '../../../../../ui-components/alert-label/alert-label.component';

import { SpeechBubbleComponent } from '../../../../../ui-components/speech-bubble/speech-bubble.component';
import {
  ContentCreatorItemComponent,
  ContentCreatorItemMessage,
} from '../../../interfaces/content-creator.interface';
import { ContentCreatorSizesService } from '../../../services/content-creator-sizes.service';
import { ReviewsApiService } from '../../../services/reviews-api.service';
import { FavoriteHotelsMapFilterTagsComponentCopy } from './components/favorite-hotels-map-filter-tags-copy.component';
import { BrxLoaderFullscreenComponent } from '../../../../../ui-components/brx/loader-fullscreen/brx-loader-fullscreen.component';

@Component({
  selector: 'app-tour-content-reviews',

  imports: [
    PluralizePipe,
    FormsModule,
    TextSelectionDirective,
    SpeechBubbleComponent,
    ResizeObserverDirective,
    FavoriteHotelsMapFilterTagsComponentCopy,
    BrxLoaderFullscreenComponent,
    SafeHtmlPipe,
    AlertLabelComponent,
    NgStyle,
    DatePipe,
  ],
  templateUrl: './reviews.component.html',
  styleUrls: ['./reviews.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReviewsComponent implements ContentCreatorItemComponent, OnInit, OnDestroy {
  @Input() tourContent: TourContent;
  @Input() tourId: string;
  @Input() appTextSelectionEnabled = true;
  @Input() initTagIds = [];
  @Output() insertSingleMessageText = new EventEmitter<string>();

  public isLoading = signal(false);
  public lastSelectedText = signal<string>('');

  public isTagsFilterOpened = signal(false);
  public selectedTagIds = signal<number[]>([]);

  public sorting = 'newFirst';
  public allReviews: HotelReview[];
  public reviews = signal<HotelReview[]>([]);

  @ViewChild('tagsFilter', { read: ViewContainerRef })
  public tagsFilter: ViewContainerRef;

  // Значения для расчета высоты блока с отзывами
  private contentCreatorBaseReservedHeight = toSignal(this.contentCreatorSizesService.reservedHeight$);
  public filtersHeight = signal<number>(0);
  public contentCreatorReservedHeight = computed(
    () => this.contentCreatorBaseReservedHeight() + this.filtersHeight(),
  );

  public closeTagsFilter: (event?: MouseEvent) => void;

  public errorMessage = signal<string>('');
  protected readonly dangerAlertLabelType = AlertLabelType.danger;
  private destroyRef = inject(DestroyRef);

  constructor(
    private readonly cdRef: ChangeDetectorRef,
    private contentCreatorSizesService: ContentCreatorSizesService,
    private reviewsApiService: ReviewsApiService,
    private sanitizerService: HtmlSanitizerService,
    private injector: Injector,
  ) {}

  ngOnInit(): void {
    const hotelId = this.tourContent?.tourMessageData?.hotel.id as number;
    this.allReviews = this.tourContent?.hotel?.reviews.map(review => {
      // Надо сделать заэнкоженный текст, т.к. в шаблоне потом используется safeHtml
      review.likeComment = this.sanitizerService.encodeHtmlEntities(review.likeComment);
      review.notLikeComment = this.sanitizerService.encodeHtmlEntities(review.notLikeComment);
      return review;
    });
    if (!this.allReviews || !this.allReviews.length) {
      this.errorMessage.set('Отзывы не найдены');
    }

    this.closeTagsFilter = (event: MouseEvent) => {
      // Если кликнули вне фильтра, закрываем его и удаляем обработчик
      if (!event || !this.tagsFilter.element.nativeElement.contains(event.target as Node)) {
        this.isTagsFilterOpened.set(false);
        document.removeEventListener('click', this.closeTagsFilter);
      }
    };

    if (this.initTagIds.length) {
      this.selectTags(this.initTagIds);
    }

    effect(
      () => {
        if (this.selectedTagIds().length === 0) {
          this.reviews.set(this.allReviews);
        } else {
          this.isLoading.set(true);
          this.reviewsApiService
            .getTextReviewsWithTags(hotelId, this.selectedTagIds())
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(reviews => {
              this.reviews.set(reviews);
              this.isLoading.set(false);

              // пока не удалять, нужно из-за карт вне зоны ангуляра
              this.cdRef.detectChanges();
            });
        }
      },
      { injector: this.injector },
    );
  }

  toggleTagsFilter(event: MouseEvent) {
    event.stopPropagation();
    this.isTagsFilterOpened.set(!this.isTagsFilterOpened());
    // Создаем обработчик клика за пределами фильтра
    if (this.isTagsFilterOpened()) {
      document.addEventListener('click', this.closeTagsFilter);
    }
  }

  getSelectedTagIds(): number[] {
    return [...this.selectedTagIds()];
  }

  changeSorting(sorting: string) {
    this.reviews.set(
      this.reviews().sort((a, b) => {
        if (sorting === 'newFirst') {
          return b.createdAt.localeCompare(a.createdAt);
        } else {
          return a.createdAt.localeCompare(b.createdAt);
        }
      }),
    );
  }

  selectTags(tagIds: number[] | null) {
    this.closeTagsFilter();
    if (tagIds === null) {
      this.selectedTagIds.set([]);
      return;
    }
    this.selectedTagIds.set(tagIds);
  }

  addTextToMessage(text: string) {
    this.insertSingleMessageText.emit(`\n- ${this.sanitizerService.extractTextFromHtml(text).trim()}`);
  }

  ngOnDestroy(): void {
    document.removeEventListener('click', this.closeTagsFilter);
  }

  getMessagesForSend(): Observable<ContentCreatorItemMessage[]> {
    return of([] as ContentCreatorItemMessage[]);
  }
}
