import { inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EmployeeDreamMotivation, GetEmployeeDreamMotivationResponse } from '@api-clients/api-client';
import { EmployeeDream } from '@api-clients/api-client/dist/models/employee-dream';
import { AppConfig } from '../../../../environments/environment';
import { DreamMotivationComponent } from '../components/dream-motivation/dream-motivation.component';
import { DreamMotivationsApiService } from './dream-motivations-api.service';

@Injectable({
  providedIn: 'root',
})
export class DreamMotivationsModalService {
  private readonly dreamMotivationsApiService = inject(DreamMotivationsApiService);
  private readonly matDialog = inject(MatDialog);

  getAndShowMotivation(): void {
    if (AppConfig.environment === 'DEV') {
      // Локально не будем показывать мотивации
      return;
    }
    void this.dreamMotivationsApiService
      .getMotivation()
      .then((response: GetEmployeeDreamMotivationResponse) => {
        if (response.dream && response.motivation) {
          this.showMotivation(response.dream, response.motivation);
          void this.dreamMotivationsApiService.markAsShown(response.motivation.id);
        }
      });
  }

  showMotivation(dream: EmployeeDream, motivation: EmployeeDreamMotivation): void {
    this.matDialog.open(DreamMotivationComponent, {
      panelClass: 'modal-panel-transparent-bg',
      width: '686px',
      minHeight: '350px',
      maxHeight: '95vh',
      data: {
        dream,
        motivation,
      },
    });
  }
}
