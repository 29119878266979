import { JsonPipe, NgClass } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { first } from 'rxjs/operators';
import { WorkerLoginRequest } from '@api-clients/api-client/dist/models';
import { AppInitService, AuthService } from '../../../../core/services';
import { FormErrorComponent } from '../../../../ui-components/form-error/form-error.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ReactiveFormsModule, TranslateModule, NgClass, FormErrorComponent, JsonPipe],
})
export class LoginComponent implements OnInit {
  public loginForm: UntypedFormGroup;
  public loading = false;
  public submitted = false;
  public returnUrl: string;
  public error: '';
  hidePassword = true;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private authenticationService: AuthService,
    private appInitService: AppInitService,
    private cdRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });

    this.returnUrl = '/';
  }

  get formControls() {
    return this.loginForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    if (this.loginForm.invalid) {
      this.submitted = false;
      return;
    }

    this.loading = true;
    const workerLoginRequest: WorkerLoginRequest = {
      username: this.formControls.username.value,
      password: this.formControls.password.value,
    };

    this.authenticationService
      .login(workerLoginRequest)
      .pipe(first())
      .subscribe(
        () => {
          this.appInitService.init().then(() => {
            this.router.navigate([this.returnUrl]).then(r => r);
          });
        },
        error => {
          this.error = error;
          this.loading = false;
          this.cdRef.detectChanges();
        },
      );
  }

  togglePasswordVisibility() {
    this.hidePassword = !this.hidePassword;
  }
}
