import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AiGptCompleteRequest, AiGptCompleteResponse } from '@api-clients/api-client';
import { Observable } from 'rxjs';
import { AppConfig } from '../../../../environments/environment';
import { AmplitudeTrackService } from '../../../core/services/amplitude/amplitude-track.service';
import { GPT_COMPLETE_REQUEST } from '../../../core/services/amplitude/amplitudeEvents';

@Injectable({
  providedIn: 'root',
})
export class AiGptApiService {
  private readonly http = inject(HttpClient);
  private readonly amplitudeTrackService = inject(AmplitudeTrackService);

  complete(request: AiGptCompleteRequest, prompt: string): Observable<AiGptCompleteResponse> {
    this.amplitudeTrackService.trackEvent(GPT_COMPLETE_REQUEST, {
      Prompt: prompt,
      Place: request.place,
    });
    return this.http.post<AiGptCompleteResponse>(`${AppConfig.commonApiUrl}/ai-gpt/complete`, request);
  }
}
