import {
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  inject,
  OnInit,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { filter } from 'rxjs';
import { AsideMenuInChatsComponent } from '../aside-menu-in-chats/aside-menu-in-chats.component';
import { AsideMenuInDealsComponent } from '../aside-menu-in-deals/aside-menu-in-deals.component';

@Component({
  selector: 'app-aside-menu',
  templateUrl: './aside-menu.component.html',
  styleUrls: ['./aside-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TranslateModule, AsideMenuInChatsComponent, AsideMenuInDealsComponent],
})
export class AsideMenuComponent implements OnInit {
  public currentUrl = signal(this.router.url);
  public isChatsRoute = computed(() => {
    return this.currentUrl().startsWith('/chats');
  });

  private destroyRef = inject(DestroyRef);

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.router.events
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter(event => event instanceof NavigationEnd),
      )
      .subscribe(() => {
        this.currentUrl.set(this.router.url);
      });
  }
}
