@if (initialMessage) {
  Исходный текст:
  <textarea
    rows="1"
    spellcheck="true"
    appResizableTextarea
    [maxHeightInPx]="260"
    matInput
    [(ngModel)]="initialText"
  ></textarea>
}

Что нужно сделать?
<div>
  <textarea
    [class.is-without-initial-message]="!initialMessage"
    #promptTextarea
    rows="1"
    spellcheck="true"
    appResizableTextarea
    [maxHeightInPx]="260"
    [triggerResize]="triggerTextareaResize"
    appSpeechRecognition
    [screenType]="screenType"
    [recorderButtonMarginBottom]="2.5"
    matInput
    [(ngModel)]="prompt"
    placeholder="Например: придумай продающий текст..."
  ></textarea>
</div>

<div class="prompt-variants">
  @for (promptVariant of promptVariants; track promptVariant) {
    <div class="prompt-variant" (click)="setPrompt(promptVariant)">
      {{ promptVariant.label }}
    </div>
  }
</div>

<div class="right">
  <button
    class="generate"
    brx-button
    iconName="@brx.ai"
    color="blue"
    [isLoading]="isLoading()"
    (click)="generateCompletion()"
  >
    Сгенерировать
  </button>
</div>

@if (completionText) {
  Итоговый текст для отправки:
  <textarea
    rows="1"
    spellcheck="true"
    appResizableTextarea
    [maxHeightInPx]="400"
    matInput
    [(ngModel)]="completionText"
  ></textarea>
  <div class="right">
    <button brx-button color="blue" iconName="@brx.send-message" (click)="sendContent()">Отправить</button>
  </div>
}
