<brx-modal-header text="Рекомендации по мечте" [dialogRef]="dialogRef" />
<brx-loader-fullscreen [isLoading]="isLoading()" [error]="error()" />

@if (motivations()?.length) {
  <div class="motivation-list">
    @for (motivation of motivations(); track motivation) {
      <div class="motivation" (click)="showMotivation(motivation)">
        <img [src]="motivation.previewImageUrl" class="motivation-image" />
        <div class="motivation-text">
          <strong>Совет:</strong><br />{{ motivation.text }}<br /><br />
          <strong>Как достичь уже сейчас:</strong><br />{{ motivation.feelingsAdvice }}
        </div>
        <div class="motivation-date">{{ motivation.shownAt | date: 'dd.MM.yyyy' }}</div>
      </div>
    }
  </div>
}
