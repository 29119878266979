<div class="modal-managers-list">
  <div style="display: flex">
    <h2 style="margin-right: 1rem" class="modal-managers-list-title">
      {{ 'MODALS.MANAGERS_LIST.TITLE' | translate }}
    </h2>
    <brx-input-search placeholder="Поиск" (inputEvent)="onSearchQueryChange($event)" [focusOnInit]="true" />
  </div>
  <app-close-modal-button [dialogRef]="dialogRef" />
  @if (officeList?.length || currentQuery()) {
    <div class="managers-list-wrapper">
      @for (office of officeList; track office; let isFirstOffice = $first) {
        <div class="office-item">
          <h5 class="office-item-title">{{ office }}</h5>
          <ul>
            @for (
              manager of groupedByOffice['offices'][office];
              track manager.id;
              let isFirstManager = $first
            ) {
              <li
                class="manager"
                [attr.manager-id]="manager?.id"
                [attr.manager-username]="manager?.username"
                [attr.id]="isFirstOffice && isFirstManager ? 'highlightNewFeature' : null"
              >
                <span class="manager-status manager-status--{{ manager?.state }}"></span>
                <app-worker-name
                  [name]="manager?.name | highlight: currentQuery()"
                  [crmCardId]="manager?.crmCardId"
                  (nameClicked)="cardOpened()"
                >
                </app-worker-name>
                @if (manager?.sip?.innerPhone) {
                  <span
                    class="manager-inner-phone"
                    [innerHTML]="getFormattedManagerSip(manager.sip.innerPhone) | highlight: currentQuery()"
                  ></span>
                }
              </li>
            }
          </ul>
        </div>
      }
      <ng-container
        *ngIf="
          groupedByOffice && groupedByOffice.callCenter.length && groupedByOffice.callCenter as callCenter
        "
      >
        <div class="office-item">
          <h5 class="office-item-title">{{ 'MODALS.MANAGERS_LIST.CALL_CENTER' | translate }}</h5>
          <ul>
            @for (manager of callCenter; track manager.id) {
              <li class="manager">
                <span class="manager-status manager-status--{{ manager?.state }}"></span>
                <span class="manager-name" [innerHTML]="manager?.name | highlight: currentQuery()"></span>
                @if (manager?.sip?.innerPhone) {
                  <span
                    class="manager-inner-phone"
                    [innerHTML]="getFormattedManagerSip(manager.sip.innerPhone) | highlight: currentQuery()"
                  ></span>
                }
              </li>
            }
          </ul>
        </div>
      </ng-container>
    </div>
    <div class="managers-status-legend">
      <h4 class="managers-status-legend-title">
        {{ 'MODALS.MANAGERS_LIST.MANAGER_STATUS.TITLE' | translate }}
      </h4>
      <ul class="status-legend-items">
        <li class="status-legend-item">
          <span class="managers-status managers-status--available"></span>
          <span class="status-legend-title">{{
            'MODALS.MANAGERS_LIST.MANAGER_STATUS.AVAILABLE' | translate
          }}</span>
        </li>
        <li class="status-legend-item">
          <span class="managers-status managers-status--busy"></span>
          <span class="status-legend-title">{{
            'MODALS.MANAGERS_LIST.MANAGER_STATUS.BUSY' | translate
          }}</span>
        </li>
        <li class="status-legend-item">
          <span class="managers-status managers-status--offline"></span>
          <span class="status-legend-title">{{
            'MODALS.MANAGERS_LIST.MANAGER_STATUS.OFFLINE' | translate
          }}</span>
        </li>
      </ul>
    </div>
  } @else {
    <brx-loader-fullscreen />
  }
</div>
