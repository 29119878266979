<app-close-modal-button [dialogRef]="dialogRef" [isWhite]="true" />

<div class="motivation-panel">
  <img [src]="motivation().previewImageUrl" class="motivation-image" (click)="showImage()" />
  <div class="title" [matTooltip]="dream().dream" matTooltipClass="text-pre">{{ dream().dream }}</div>
  <div class="motivation-text">
    {{ motivation().text }}
  </div>

  <div class="why-important">
    <div class="small-title">Как достичь ощущения, как когда мечта осуществится, но уже сейчас</div>
    <div>{{ motivation().feelingsAdvice }}</div>
  </div>
  @if (dream().deadlineDate) {
    <div class="separator-wrapper">
      <div class="separator"></div>
    </div>
    <div class="deadline-date">
      <div class="small-title">Дата исполнения</div>
      <div class="date">
        <div class="date-part">{{ dreamMonth() }}</div>
        <div class="date-part">{{ dreamYear() }}</div>
      </div>
    </div>
  }
</div>
