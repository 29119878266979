import { Injectable } from '@angular/core';
import { BehaviorSubject, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NavigationStateService {
  private currentUrlSub = new BehaviorSubject('');

  currentUrl$ = this.currentUrlSub.asObservable();

  onUrlChange(url: string) {
    this.currentUrlSub.next(url);
  }

  isChatsRoute(url: string) {
    // в app не срабатывает
    return url.startsWith('/chats');
  }

  isDealsRoute(url: string) {
    // в app не срабатывает
    return url.startsWith('/chats');
  }

  get isChatsRoute$() {
    return this.currentUrl$.pipe(map(url => this.isChatsRoute(url)));
  }

  get isDealsRoute$() {
    return this.currentUrl$.pipe(map(url => this.isDealsRoute(url)));
  }
}
