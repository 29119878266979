import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AsideChatsComponent } from '../../../aside-chats/components/aside-chats/aside-chats.component';
import { DealItemsCounterService } from '../../../deals/services/deal-items-counter.service';
import { ZenmodeAbTestService } from '../../services/zenmode-ab-test.service';

@Component({
  selector: 'app-aside-menu-in-deals',
  templateUrl: './aside-menu-in-deals.component.html',
  styleUrls: ['./aside-menu-in-deals.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TranslateModule, AsyncPipe, RouterLink, AsideChatsComponent, RouterLinkActive],
})
export class AsideMenuInDealsComponent {
  public dealsTotalCount = toSignal(this.dealItemsCounterService.dealsNumber$);
  public isOnlyZenMode$ = this.zenmodeAbTestService.isOnlyZenmodeForWorker$;

  constructor(
    private zenmodeAbTestService: ZenmodeAbTestService,
    private dealItemsCounterService: DealItemsCounterService,
    private router: Router,
  ) {}
}
