import { NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, input, output } from '@angular/core';
import { CrmCardViewItem } from '@api-clients/crm-api-client';
import { CloseModalButtonComponent } from '../../../../../ui-components/close-modal-button/close-modal-button.component';
import { ChatsNavigationService } from 'app/modules/chats/services/chats-nav.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-calling-island',
  templateUrl: './calling-island.component.html',
  styleUrl: './calling-island.component.scss',
  imports: [NgOptimizedImage, CloseModalButtonComponent],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CallingIslandComponent {
  private readonly navService = inject(ChatsNavigationService);
  crmCardViewItem = input<CrmCardViewItem>();

  constructor(private router: Router) {}

  close = output<void>();

  openChat(): void {
    const item = this.crmCardViewItem(); // Explicit cast
    if (!item) {
      return;
    }

    this.navService.navigateAndOpenChatFromCrmCardViewItem(item, this.router);
  }
}
