import { Directive, ElementRef, inject } from '@angular/core';

/**
 * Директива для управления текстовым полем ввода
 */
@Directive({
  selector: '[appTextInputControl]',
  exportAs: 'appTextInputControl',
})
export class TextInputControlDirective {
  private readonly el = inject(ElementRef);

  /**
   * Вставляет текст в позицию каретки
   */
  insertText(text: string, needTrim = false): string {
    const textarea: HTMLTextAreaElement = this.el.nativeElement;
    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;

    // Вставляем текст на позицию каретки
    let updatedText = textarea.value.substring(0, start) + text + textarea.value.substring(end);
    if (needTrim) {
      updatedText = updatedText.trim();
    }
    textarea.value = updatedText;

    // Устанавливаем каретку после вставленного текста
    textarea.selectionStart = textarea.selectionEnd = start + text.length;
    textarea.focus();
    // Триггер события изменения текста
    textarea.dispatchEvent(new Event('input'));

    return updatedText;
  }
}
